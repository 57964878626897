.tooltipDefault {
    width:100%;
    line-height:27px;
    display: inherit;

    .inputWrapper {
        user-select: none;
        box-sizing: border-box;
        background: #fff;
        border:1px solid #cccccc;
        position: relative;
        height:27px;
        cursor: pointer;
        outline: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding:0 24px 0 7px;
    
        &:focus{
            border: 1px solid #1c90fb ;
        }
    
        &.disabled {
            cursor: not-allowed;
            color: #a6a6a6;
            background-color: #f2f2f2;
        }
    
        &.readonly {
            color: #a6a6a6;
            background-color: #f2f2f2;
        }
    
        &.frozen {
            cursor: default;
        }
    
        &.require {
           background: rgb(254, 244, 244)
        }
    
        .useImage{
            height:15px;
            margin-right: 7px;
            flex : 0 1 15px;
            & > img {
                width:15px;
            }
        }
    
        .inputText {
            flex: 1 1 auto;
            font-size: 12px;
            box-sizing: border-box;
            width:calc(100% - 20px);
            overflow:hidden;
            white-space: nowrap;
            word-break: break-all;
            text-overflow: ellipsis;
        }
    
        .arrowBtn{
            position:absolute;
            right:6px;
            display: flex;
            align-items: center;
            justify-content: center;
            & > img {
                width:12px;
                transition: transform 0.2s ease;
                transform-origin: center;
            }
            .arrowDown {
                transform: rotate(0.5turn);
            }
        }
    }
}

.dropDownWrapper{
    box-sizing: border-box;
    position: relative;
    transition: height .2s ease-out;
    height:0;
    box-shadow: rgba(0, 0,0 ,0.12) 0px 1px 6px, rgba(0, 0,0 ,0.12) 0px 1px 4px;
    border:1px solid #a3a3a3;
    max-height: 189px;
    overflow:hidden;

    &.dropDownWrapperAnimate{
        height:189px;
    }
    .dropDownList{
        max-height:189px;
        overflow-y: auto;
        box-sizing: border-box;
        position:absolute;
        left:0;
        right:0;
        bottom:0;

        .dropDownItem{
            background: #fff;
            height:27px;
            line-height:27px;
            font-size:12px;
            position:relative;
            cursor: pointer;
            overflow: hidden;
            white-space: nowrap;
            word-break: break-all;
            text-overflow: ellipsis;

            .useImage {
                display: inline-block;
                position:absolute;
                top:6px;
                left:7px;
                width:15px;
                height:15px;

                & > img{
                    width:15px;
                }
            }

            &.focusedItem {
                background: #e9f5ff;
                & > span {
                    color: #1c90fb;
        
                }
                &:hover {
                    & > span {
                        color: #4a4a4a;
                    }
                }
            }

            &:hover{
                background: #e9f5ff;
            }
        }
    }
}

.itemOverFlowTooltip{
    display: inherit;
}