.breadcrumb {
    display: flex;
    align-items: center;
    height: 50px;
    background-color : rgb(248, 249, 250);
    padding:0 10px;
    font-size: 1.25em;
}

.node-item{  
  display:flex;
  position: relative;
  &--end{
    display: flex;
    position: relative;
    cursor: pointer;
  }

  &__prev{
    cursor: pointer;
    text-decoration: underline;
  }

  align-items: center;
}

.dropdown{
  white-space: nowrap;
  // height:90px;
  background-color: white;
  position:absolute;
  border: 1px solid;
  z-index: 10;
  top:20px;
  font-size:11px;
  & a{
    text-decoration: none;
  }
  padding: 10px;
}

  
  