@charset "UTF-8";
/**************************************************
   reset CSS
**************************************************/
html, body {
  width: 100%;
  height: 100%;
  font-size: 12px;
  -webkit-text-size-adjust: none;
  overflow: hidden;
}

body, p, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, dt, dd, table, th, td, form, fieldset, legend, input, textarea, img, button, select {
  margin: 0;
  padding: 0;
}

body, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, button {
  font-family: "NSKR", "돋움", Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif;
}

img, fieldset {
  border: 0;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: #1a1a1a;
}

em, address {
  font-style: normal;
}

ul, ol, li {
  list-style: none;
}

label, button {
  cursor: pointer;
}

input::-ms-clear {
  display: none;
}

input:disabled {
  -webkit-text-fill-color: #8d8d8d;
  opacity: 1;
}

iframe {
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0;
}

* {
  -webkit-overflow-scrolling: touch;
}

/* 모바일에서 터치 부드러운 효과 적용 */
/* Noto Sans KR (korean) http://www.google.com/fonts/earlyaccess */
@font-face {
  font-family: "NSKT";
  font-style: normal;
  font-weight: 100;
  src: url("../font/NotoSansKr/NotoSansKR-Thin.woff2") format("woff2"), url("../font/NotoSansKr/NotoSansKR-Thin.woff") format("woff"), url("../font/NotoSansKr/NotoSansKR-Thin.otf") format("opentype");
}
@font-face {
  font-family: "NSKL";
  font-style: normal;
  font-weight: 300;
  src: url("../font/NotoSansKr/NotoSansKR-Light.woff2") format("woff2"), url("../font/NotoSansKr/NotoSansKR-Light.woff") format("woff"), url("../font/NotoSansKr/NotoSansKR-Light.otf") format("opentype");
}
@font-face {
  font-family: "NSKR";
  font-style: normal;
  font-weight: 400;
  src: url("../font/NotoSansKr/NotoSansKR-Regular.woff2") format("woff2"), url("../font/NotoSansKr/NotoSansKR-Regular.woff") format("woff"), url("../font/NotoSansKr/NotoSansKR-Regular.otf") format("opentype");
}
@font-face {
  font-family: "NSKM";
  font-style: normal;
  font-weight: 500;
  src: url("../font/NotoSansKr/NotoSansKR-Medium.woff2") format("woff2"), url("../font/NotoSansKr/NotoSansKR-Medium.woff") format("woff"), url("../font/NotoSansKr/NotoSansKR-Medium.otf") format("opentype");
}
@font-face {
  font-family: "NSKB";
  font-style: normal;
  font-weight: 700;
  src: url("../font/NotoSansKr/NotoSansKR-Bold.woff2") format("woff2"), url("../font/NotoSansKr/NotoSansKR-Bold.woff") format("woff"), url("../font/NotoSansKr/NotoSansKR-Bold.otf") format("opentype");
}
@font-face {
  font-family: "NSKBK";
  font-style: normal;
  font-weight: 900;
  src: url("../font/NotoSansKr/NotoSansKR-Black.woff2") format("woff2"), url("../font/NotoSansKr/NotoSansKR-Black.woff") format("woff"), url("../font/NotoSansKr/NotoSansKR-Black.otf") format("opentype");
}
/* 크롬 input 자동완성 배경 초기화 */
input:-webkit-autofill {
  box-shadow: 0 0 0 30px #fff inset;
  -webkit-text-fill-color: #000;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

/* PlaceHolder 예외 처리 */
::-webkit-input-placeholder {
  text-align: left !important;
  color: #999;
}

:-moz-placeholder {
  text-align: left !important;
  color: #999;
}

::-moz-placeholder {
  text-align: left !important;
  color: #999;
}

:-ms-input-placeholder {
  text-align: left !important;
  color: #999 !important;
}

::-ms-input-placeholder {
  text-align: left !important;
  color: #999;
}

::placeholder {
  text-align: left !important;
  color: #999;
}

input::-webkit-input-placeholder {
  text-align: left !important;
  color: #999;
}

input:-moz-placeholder {
  text-align: left !important;
  color: #999;
}

input::-moz-placeholder {
  text-align: left !important;
  color: #999;
}

input:-ms-input-placeholder {
  text-align: left !important;
  color: #999 !important;
}

input::-ms-input-placeholder {
  text-align: left !important;
  color: #999;
}

input::placeholder {
  text-align: left !important;
  color: #999;
}

/***************************** 
    컬러
******************************/
/**************************************************
    Common
**************************************************/
.accessibility {
  overflow: hidden;
  position: absolute;
  left: -1000px;
  top: 0;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  text-indent: -9999px;
}

.hidden {
  overflow: hidden;
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 1px;
  height: 1px;
}

.blind {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}

.skip {
  display: none;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
}

.no_ellipsis {
  white-space: normal !important;
}

.ovh {
  overflow: hidden !important;
}

.ovv {
  overflow: visible !important;
}

.eventLink {
  cursor: pointer;
}

.link {
  position: relative;
  cursor: pointer;
}
.link:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 1px;
  background: #000000;
  transition: all 0.5s;
}
.link:hover:after {
  width: 100%;
  transition: all 0.5s;
}

/* flex */
.h-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  min-width: 0;
}

.v-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
}

.flex-1 {
  -webkit-flex: 1;
  flex: 1;
  min-width: 0;
}

.flex-2 {
  -webkit-flex: 2;
  flex: 2;
  min-width: 0;
}

.flex-3 {
  -webkit-flex: 3;
  flex: 3;
  min-width: 0;
}

/*scroll*/
.scroll_on {
  overflow: auto !important;
}

.scroll_off {
  overflow: hidden !important;
}

.scroll_y_on {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.scroll_x_on {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}

.scroll_fix {
  overflow: scroll !important;
}

.scroll_y_fix {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.scroll_x_fix {
  overflow-y: hidden !important;
  overflow-x: scroll !important;
}

/*position*/
.posi_re {
  position: relative !important;
}

.posi_ab {
  position: absolute !important;
}

.posi_fix {
  position: fixed !important;
}

.posi_inh {
  position: inherit !important;
}

/* Floating */
.fl {
  float: left !important;
}

.fr {
  float: right !important;
}

.cl {
  clear: both !important;
}

/*각 자식요소들 floating 지정으로 높이 인식못하는거 해결*/
.clear:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

/* display */
.dp_ib {
  display: inline-block !important;
}

.dp_i {
  display: inline !important;
}

.dp_b {
  display: block !important;
}

.dp_n {
  display: none !important;
}

/* visibility */
.vv {
  visibility: visible;
}

.vh {
  visibility: hidden;
}

/* Align */
.al {
  text-align: left !important;
}

.ac {
  text-align: center !important;
}

.ar {
  text-align: right !important;
}

.vt {
  vertical-align: top !important;
}

.vm {
  vertical-align: middle !important;
}

.vb {
  vertical-align: bottom !important;
}

/* Font size */
.f11 {
  font-size: 11px !important;
}

.f12 {
  font-size: 12px !important;
}

.f13 {
  font-size: 13px !important;
}

.f14 {
  font-size: 14px !important;
}

.f15 {
  font-size: 15px !important;
}

.f16 {
  font-size: 16px !important;
}

.f17 {
  font-size: 17px !important;
}

.f18 {
  font-size: 18px !important;
}

.f19 {
  font-size: 19px !important;
}

.f20 {
  font-size: 20px !important;
}

/* Font weight */
.fwb {
  font-weight: bold !important;
}

.fwn {
  font-weight: normal !important;
}

/* Padding, Margin style */
/* 특별한 case외에 사용 자제 */
.p0 {
  padding: 0 !important;
}

.p10 {
  padding: 10px !important;
}

.p15 {
  padding: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pl0 {
  padding-left: 0px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.m0 {
  margin: 0px !important;
}

.m5 {
  margin: 5px !important;
}

.m10 {
  margin: 10px !important;
}

.m15 {
  margin: 15px !important;
}

.m20 {
  margin: 20px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mr50 {
  margin-right: 50px !important;
}

/***************************** 
    nav_in_wrap
******************************/
.nav_in_wrap {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: auto;
}

.nav.active .nav_in_wrap {
  display: block;
  width: 100%;
  height: 100%;
}

.nav_hold {
  padding: 100px 0 0 0;
}

.nav_hold ul {
  width: 1000px;
  margin: 0 auto;
}

.nav_hold ul:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.nav_hold ul li {
  position: relative;
  overflow: hidden;
  float: left;
  height: 30px;
  line-height: 30px;
  margin-left: 50px;
  padding: 5px 10px;
  border-radius: 24px;
  vertical-align: top;
}

.nav_hold ul li:first-child {
  margin: 0;
}

.nav_hold ul li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 40px;
  border-radius: 24px;
  background: #8BA9FF;
  -webkit-transform: translateX(-110%);
  transform: translateX(-110%);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0, 0.41, 0.4, 1), visibility 0s 0.2s;
  transition: transform 0.2s cubic-bezier(0, 0.41, 0.4, 1), visibility 0s 0.2s;
}

.nav_hold ul li:hover::after {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  opacity: 1;
  -webkit-animation-name: opc1;
  -webkit-animation-fill-mode: backwards;
  -webkit-animation-delay: 0.2s;
  -webkit-animation-duration: 0.1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-direction: normal;
  animation-name: opc1;
  animation-fill-mode: backwards;
  animation-delay: 0.2s;
  animation-duration: 0.1s;
  animation-iteration-count: 1;
  animation-direction: normal;
}

.nav_hold ul li span.menuLink {
  position: relative;
  display: block;
  font-size: 24px;
  color: #ffffff;
  z-index: 10;
  cursor: pointer;
}

.nav_scroll {
  position: absolute;
  top: 200px;
  left: 50%;
  width: 1000px;
  bottom: 0;
  margin: 0 0 0 -500px;
  padding: 0 0 50px 0;
  box-sizing: border-box;
}

.nav_scroll .box:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.nav_scroll .box .unitWrap {
  float: left;
  min-width: 180px;
  margin: 0 20px 0 0;
}

.nav_scroll .box .unitWrap ul {
  margin: 20px 0 0 0;
}

.nav_scroll .box .unitWrap ul:first-child {
  margin: 0;
}

.nav_scroll .box p {
  font-size: 24px;
  color: #ffffff;
  margin: 0 0 40px 10px;
}

.nav_scroll .box p span {
  font-size: 18px;
  margin: 0 0 0 10px;
}

.nav_scroll .box .unitWrap ul:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.nav_scroll .box .unitWrap ul li {
  position: relative;
  overflow: hidden;
  width: auto;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  border-radius: 24px;
}

.nav_scroll .box .unitWrap ul li:first-child:hover:after {
  display: none;
}

.nav_scroll .box .unitWrap ul li:hover:after {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  opacity: 1;
  -webkit-animation-name: opc1;
  -webkit-animation-fill-mode: backwards;
  -webkit-animation-delay: 0.2s;
  -webkit-animation-duration: 0.1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-direction: normal;
  animation-name: opc1;
  animation-fill-mode: backwards;
  animation-delay: 0.2s;
  animation-duration: 0.1s;
  animation-iteration-count: 1;
  animation-direction: normal;
}

.nav_scroll .box .unitWrap ul li b {
  color: #ffffff;
}

.nav_scroll .box .unitWrap ul li small {
  color: #ffffff;
}

.nav_scroll .box .unitWrap ul li span.menuLink {
  display: block;
  font-size: 12px;
  color: #8BA9FF;
  cursor: pointer;
}

.nav_scroll .box .unitWrap ul li:hover span.menuLink {
  color: #ffffff;
  transition: all 0.2s;
}

/***************************** 
    햄버거 전체메뉴
******************************/
.nav {
  position: fixed;
  z-index: 1000;
}

.nav .dim {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: rgba(0, 23, 54, 0.9);
  z-index: 999;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(0);
}

.nav.active {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.nav.active .dim {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(30);
}

.menu-trigger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
}

.menu-trigger {
  position: fixed;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 24px;
  z-index: 1000;
  cursor: pointer;
}

.home .menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #8BA9FF;
  border-radius: 24px;
}

.menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #8BA9FF;
  border-radius: 24px;
}

.nav.active .menu-trigger span {
  background-color: #ffffff;
}

.menu-trigger span:nth-of-type(1) {
  top: 0;
}

.menu-trigger span:nth-of-type(2) {
  top: 10px;
}

.menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

/* 햄버거메뉴 액션 */
.menu-trigger span:nth-of-type(1) {
  -webkit-animation: menu-bar01 0.5s forwards;
  animation: menu-bar01 0.5s forwards;
}

@-webkit-keyframes menu-bar01 {
  0% {
    -webkit-transform: translateY(10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(0);
  }
}
@keyframes menu-bar01 {
  0% {
    transform: translateY(10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
.menu-trigger span:nth-of-type(2) {
  -webkit-animation: menu-bar02 0.5s forwards;
  animation: menu-bar02 0.5s forwards;
}

@-webkit-keyframes menu-bar02 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes menu-bar02 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.menu-trigger span:nth-of-type(3) {
  -webkit-animation: menu-bar03 0.5s forwards;
  animation: menu-bar03 0.5s forwards;
}

@-webkit-keyframes menu-bar03 {
  0% {
    -webkit-transform: translateY(-10px) rotate(45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(0);
  }
}
@keyframes menu-bar03 {
  0% {
    transform: translateY(-10px) rotate(45deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
.menu-trigger:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  content: "";
  width: 30px;
  height: 30px;
  margin: -16px 0 0 -16px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 0.1s;
  opacity: 0;
}

.nav.active .menu-trigger:after {
  -webkit-animation: circle 0.5s;
  animation: circle 0.5s;
}

@-webkit-keyframes circle {
  0% {
    -webkit-transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(3.5);
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}
.nav.active .menu-trigger span:nth-of-type(1) {
  -webkit-animation: active-menu-bar01 0.5s 0.5s forwards;
  animation: active-menu-bar01 0.5s 0.5s forwards;
}

@-webkit-keyframes active-menu-bar01 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
  }
  100% {
    -webkit-transform: translateY(10px) rotate(-45deg);
  }
}
@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(10px) rotate(-45deg);
  }
}
.nav.active .menu-trigger span:nth-of-type(2) {
  -webkit-animation: active-menu-bar02 0.5s 0.5s forwards;
  animation: active-menu-bar02 0.5s 0.5s forwards;
}

@-webkit-keyframes active-menu-bar02 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes active-menu-bar02 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.nav.active .menu-trigger span:nth-of-type(3) {
  -webkit-animation: active-menu-bar03 0.5s 0.5s forwards;
  animation: active-menu-bar03 0.5s 0.5s forwards;
}

@-webkit-keyframes active-menu-bar03 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
  }
  100% {
    -webkit-transform: translateY(-10px) rotate(45deg);
  }
}
@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(-10px) rotate(45deg);
  }
}