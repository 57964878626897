.closeButton{
    top: -12px;
    left: calc((100% - 98px) /2);
    width: 98px;
    height: 12px;
    position: absolute;
    padding: 0;
    border: none;
    cursor: pointer;
    background:  url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGIAAAAMCAYAAACZW2gkAAADu0lEQVRYhe2WT0gjZxiHn8kk66jNP1HolEQ0YMCBXvQgEkGhhh5MLsXgGlSQRaSHXHTx0IOFHHtUqNsN9LBhPUgK1sQugh4C5pgcIuRUzMHgHDwkKm3GLbE9uEmNq2ZrN+mhPqf5vvnN+/2+eb/3nREWFhaowTzwopbokXuZB17eJ9DXCDAsiuJ38/Pzf7S3txs+nq//DxcXF29XVla+KRaLGWD/Lp3unhhdOp3uJ5/P1/KYhIfT1NT05NmzZ5/p9frXgO0u3V0V0SKK4i8ul6vJ6XTWqpoHo2kau7u7ZLNZJEmiv78fWZYJh8MsLS1VaROJBJlMBgBFUXC5XACoqkosFkNVVRwOB4qiIMsyoVCo8qzVaiUQCFTG5+fnGI3Gqvi3zX0s2traDD6fz7ixsREtlUou4PebmttesqDT6V47HI5PR0ZGPqmLs3fEYjEkSSIQCKCqKqqqUiwW0TStSldOwvT0NADhcBgAl8tFOBzG4/GgKAqqqpLP5ykWiwAsLy+/t+bp6Snr6+t4vV5stqsDmsvliEaj+P1+zGZzXfba09NjHRoa+m1/f//HUqn09Ob991qTIAjfmkymkfHxcWtdHL1D0zQymUzlZMuyTF9f363ask6SpErllKujubmZVCqFqqrIsoyiKPeuazab8Xq9RKNRcrlcJQkej6duSSgzPDxs6+7uHhAE4fnNezcT8ZVer38+MzNjMRjq+1k4Pj4GrtpGLVRVRZKkythisaCqKgBzc3MAhEIhVldXK/MAwWCQYDBIKpWqimez2SrJKCfBbrf/6z19CBMTE11mszkAfHl9/npr+lyn072anJxssVgsdTdUTkA+n6+ZDFmWKRQKlXGhUECWZQAkSWJqagq4anWRSISxsTHg9tZ0G4Ig/GP/D0UURWZnZ+1ra2svNU37AvgV/q6IDlEU37jdbn1XV1dDXFmtVhwOB4lEArg69eXrmyiKQjKZRNM0NE0jmUyiKAqaphGLxSrflA+pLqCqHV1vU43CaDQKfr+/3WAw/AyYAMTBwcEnoii+6e3t7XS73S0NcwM4nU4ODw/Z3Nzk6OiIzs5OrFYrqVSKeDxOPB7n/Pyc0dFRNE1jb2+PdDpd+WvS6/WcnJywvb3Nzs4OgiDg8XgwGAxVMdLpNAMDAwCcnZ0RiUTwer3Y7XZMJhOyLBONRnE6nVUtsJ6YTCZDa2vrn9lsdujy8nJdWFxc/KGjo+Pp3NycSRTFhpj4r2n07+t9bG1tHR0cHLwSCoXC98DXDXfwyHVe/AW3zl2UDQOT1AAAAABJRU5ErkJggg==) no-repeat 50%;
}

.popupPanel {
    padding: 0 25px;
    border: 1px solid #ccc;
    background: #fff;
    border-bottom: 0px;
    box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    visibility: visible !important;
    box-sizing: border-box;
}
