.obt_list_grid_root_class_name_hard .rg-dropdownlist {
    background: #fff;
    font-family: inherit;
    border: 1px solid rgb(50, 50, 50);
    box-shadow: rgba(0, 0, 0, 0.8) 1px 2px 5px;
    font-size: 12px;
    padding: 0px;
    margin: 0px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border: 1px solid rgb(163, 163, 163);
    overflow: auto !important;
}

.obt_list_grid_root_class_name_hard .rg-dropdown-item {
    height: 20px;
    line-height: 20px;
    padding: 0px 6px;
    margin: 0px;
    cursor: pointer !important;
}

.obt_list_grid_root_class_name_hard .rg-dropdown-select {
    height: 20px;
    line-height: 20px;
    padding: 0px 6px;
    margin: 0px;
    cursor: pointer !important;
    background: rgb(233, 244, 255);
    color: rgb(28, 144, 251);
}

.obt_list_grid_root_class_name_hard .rg-dropdown-select:hover {
    color: rgb(74, 74, 74);
}

.obt_list_grid_root_class_name_hard .rg-dropdown-item:hover {
    background: rgb(233, 244, 255);
    color: rgb(74, 74, 74);
}