.root {
  min-width: 900px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .titleRoot {
    padding:20px 10px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    position: relative;
    margin-bottom: 5px;

    & > * {
      flex-grow: 0;
      flex-shrink: 0;
    }

    .title {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 25px;
      font-weight: bolder;
      margin-right: 10px;
      cursor: pointer;
      transition:0.14s;

      &:hover {
        color: #8BA9FF;
      }
    }

    .description {
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 14px;
      color: rgba($color: #000000, $alpha: 0.7);
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .filterRoot {
    background: #f1f4f8;
    z-index: 1;
    padding:15px 10px 10px 10px;
    margin-bottom:20px;
    min-width:900px;
  
    .filterBoxWrap {
      min-width: 600px;
      padding:1% 25%;
    
      .searchTextBox {
        position: relative;
        width:100%;
        box-shadow: 0px 5px 7px rgba(59, 59, 59, 0.15);
        border-radius:5px;
        margin-bottom:25px;
    
        & > div {
          width:100%;
          border-radius: 5px;
    
          & > div > div {
            height:45px !important; 
            border:none !important;
            border-radius: 5px;
            outline: none;
            background-image: url('./img/search.png') !important;
            background-position:20px center !important;
            background-size: 15px 15px !important;
            background-repeat: no-repeat !important;
    
            & > input {
              padding:0 160px 0 50px  !important; 
              height:55px !important; 
              font-size: 1.1em !important;
            }
          }
        }
        .searchTextResetButton {
          font-family:Arial, Helvetica, sans-serif;
          border-radius: 30px;
          background: #f1f4f8;
          background-image: url('./img/xmark.png');
          background-repeat: no-repeat;
          background-size: 8px 8px;
          background-position: center center;
          width:15px;
          height:15px;
          border:none;
          outline: none;
          color: #738092;
          position:absolute;
          right:130px;
          top:50%;
          transform: translateY(-50%);
        }
    
        .searchBtn {
          font-family:Arial, Helvetica, sans-serif;
          position:absolute;
          right:5px;
          top:5px;
          bottom:5px;
          width:100px;
          background: #005ff8;
          border:none;
          outline: none;
          color:#fff;
          font-weight: bolder;
          font-size: 0.95em;
          border-radius: 5px;
        }
      }
    
      .filterBox {
        display: flex;
    
        .filter {
          width:200px;
          margin-right:25px;
    
          & > h4 {
            font-family:Arial, Helvetica, sans-serif;
            margin-bottom:15px;
          }
        }
    
        .filter1 {
          width:200px;
  
          & > div > div > div > span {
            height:25px !important;
            line-height:23px !important;
            border:none !important;
            border-radius: 5px;
            padding: 4px 24px 1px 12px !important;
          }
        }
  
        .filter2 {
          width:200px;
          margin-right:25px;
    
          .dateWrap {
            display: flex;
                  
            & > div > div > div {
                width: calc(200px) !important;
  
              &:not(.customInput){
                border:none !important;
                line-height:23px !important;
                border-radius: 5px;
                height:30px !important;
                width:100%;
                padding: 3.5px 15px !important;
    
                & > span {
                  margin: 0px 8px !important;
                }
    
                & > input {
                  padding: 4px 0 !important;
                }
              }
            }
          }
        }
        .clearFilter{
          width:150px;
          height:30px;
          margin-top:29px;
          margin-left:20px;
            
          .clearFilterBtn{
            border-radius: 3px;
            padding:8px 10px 8px 25px;
            line-height:normal;
            background: #e6e9ed;
            font-family:Arial, Helvetica, sans-serif;
            display: inline-block;
            color:#a7aeb5;
            cursor: pointer;
            background-image: url('./img/refresh1.png') ;
            background-position:8px center;
            background-size: 12px 12px ;
            background-repeat: no-repeat ;
            transition: 0.3s;
        
            &:hover{
              color:#005ff8;
              background-image: url('./img/refresh_hover.png') ;
              background-position:8px center;
              background-size: 12px 12px ;
              background-repeat: no-repeat ;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }  

  .loginButton {
      font-family:Arial, Helvetica, sans-serif;
      // position:absolute;
      right:5px;
      top:20px;
      width:100px;
      background: #fff;
      outline: none;
      color:#005ff8;
      border: 2px solid #005ff8;
      font-weight: bolder;
      font-size: 0.95em;
      border-radius: 5px;
      height: 30px;
      box-shadow: 0px 4px 4px rgba(59, 59, 59, 0.15);
  }

  .deleteEditButton {
    color:#d1d3d4;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        color:#005ff8;
        text-decoration: underline;
    }
  }

  .contentsRoot {  
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
    overflow: hidden;
  
    & > div > div {
      overflow: hidden !important;

      .updateListWrap{
        padding:0 28% 0 0; 
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        overflow: hidden;
        overflow-y: auto;
        transition: right 0.3s ease;
        scroll-behavior: smooth;
  
        .updateDate{
          font-family: 'NSKB';
          padding:20px 30px;
          font-weight: 900;
          font-size: 1.6em;
        }
        .itemWrap {
          padding:8px 0 8px 30px;
        
          .listItemWrap {
            width:100%;
            color: #6f6f6f;
            list-style-type: disc;
        
            & > span {
              margin-right:5px;
              font-size:1.1em;
        
              &:nth-child(2) {
                border-radius: 10px;
                font-size: 0.4em !important;
                padding:0px 3px;
                margin:0 5px;
              }
            }
        
            .componentName {
              font-weight: 900;
            }
        
            .badge {
              border:1.7px solid #4b96e6;
              color: #4b96e6;
            }
            .bug {
              border:1.7px solid #f10717;
              color: #f10717;
            }
            .doc {
              border:1.7px solid #959595;
              color: #959595;
            }
            .new {
              border:1.7px solid#1cb028;
              color: #1cb028;
            }
            .style {
              border:1.7px solid #f1ce33;
              color: #f1ce33;
            }
            .warningLevelWrap {
              margin:0 5px;
              display: inline-block;
        
              & > span {
                display: inline-block;
                width:10px;
                height:10px;
              }
              .important{
                background-image: url('./img/importantStar.png');
                background-size: cover;
                background-repeat: no-repeat;
              }
              .warning{
                background-image: url('./img/warningStar.png');
                background-size: cover;
                background-repeat: no-repeat;
              }
            }
            .normalWarningLevel {
              display: none;
            }
        
            .subjectWrap {
              margin-right:10px;
              padding: 10px 0 0 10px;
              // display: inline-block;
              margin-left:10px;
              .subject{
                word-break:keep-all;
                white-space: pre-wrap;
                font-size: 1.1em;
                margin-right:10px;
                line-height:2.0em;
              }
            }
        
            .link {
              color:#d1d3d4;
              cursor: pointer;
              padding-right:18px;
              margin-right:10px;
              background-image: url('./img/link.png');
              background-position:right 4px center;
              background-size: 11px 11px;
              background-repeat: no-repeat;
              transition: 0.3s;
        
              &:hover {
                  color:#8ba9ff;
                  background-image: url('./img/link_hover.png');
                  background-position:right 2px center;
                  background-size: 11px 11px;
                  background-repeat: no-repeat;
                  text-decoration: underline;
              }
            }
            
            .code {
              margin: 5px 15px 0 15px;
              border:3px solid #f2f4fa;
              border-radius: 5px;
            
              & > pre {
                margin:0 !important;
                border-radius: 3px;
              }
            }
        
            .noticeBoxWrap{
              box-sizing: border-box;
              // margin:20px;
              margin: 20px 20px 20px 0;
              position: relative;
              padding:15px;
              background: #f2f4fa;
              border-left:5px solid #005ff8;
              border-radius: 5px;
              .noticeBox{
                & > h5{
                  background-image: url('./img/info.png');
                  background-position:left;
                  background-size: 13px 13px;
                  background-repeat: no-repeat;
                  font-weight: bold;
                  color: #000;
                  padding:0 20px;
                  margin-bottom:5px;
                  font-size: 1.0em;
                }
                & > p {
                  white-space: pre-wrap;
                  font-family: auto;
                  line-height:170%;
                }
              }
            }
          }
        }
      }
    }

    .dateLocatorRoot{
      width:250px;
      border-left:1px solid #ededed;
      position:absolute;
      top:0;
      bottom:0;
      right:15px;
      box-sizing: border-box;
      background: #fff;
  
      .dateLocatorTitle{
        box-sizing: border-box;
        height:80px;
        // border-bottom:1px solid #ddd;
        padding:0px 45px;
        display: flex;
        align-items: center;
  
        & > h4 {
          font-size: 1.6em;
          padding-left: 15px;
          border-left:5px solid #005ff8;
          color:#005ff8;
          font-family:Arial, Helvetica, sans-serif;
        }
      }
  
      .anchorItem {
        height:87%;
        overflow: scroll;
        padding:0 0px 30px 50px;
        box-sizing: border-box;
  
        &  > div > div > div {
          line-height:0.9em;
          padding-left:10px;
          margin : 0 0 30px 5px;
          cursor: pointer;
          transition: 0.15s;
  
          &:hover {
            margin: 0 0 30px 2px;
            // border-left:3px solid #4b96e6;
          }
          
          &:first-child {
            margin-top:10px;
          }
  
          &:last-child {
            margin-bottom: 0;
          }
        }
        .selected {
          margin: 0 0 30px 0px !important;
          border-left:3px solid #005ff8;
          color:#005ff8;
          &:first-child{
            margin-top:10px !important;
          }
        }
      }
    }
  }

  .emptySetRoot{
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .imageWrapper {
      margin-bottom:25px;
    }
    .textWrapper {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;

      .mainText {
        font-size: 2.1em;
        margin-bottom: 15px;
      }
      .subText {
        font-size:1.1em;
        line-height:160%;
        color: #a1a7ac;
        margin-bottom: 40px;
      }
      & > button{
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bolder;
        font-size: 0.95em;
        width:100px;
        height:40px;
        background: #005ff8;
        color:#fff;
        outline: none;
        border: none;
        border-radius: 5px;
      }
    }
  }
}