@charset "UTF-8";
.dialogRoot {
  display: none;
  outline: none;
  position: absolute;
}

.dialogRootOpen {
  display: block;
}

.data {
  flex-grow: 1;
  border-top: 1px solid #d9d9d9;
  overflow: auto;
  position: relative;
}
.data > .dataWrapper {
  display: inline-block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 9px;
  bottom: 30px;
}

.bottomButton {
  margin: 0px -31px 0px;
  padding: 15px 0 15px 0;
  background-color: #f7f7f7;
  text-align: center;
}
.bottomButton.noneButton {
  padding: 0 0 60px 0;
}
.bottomButton > div:first-child {
  margin: 0;
}
.bottomButton > div {
  margin-left: 4px;
}

.useImageUrl {
  max-width: 18px;
  max-height: 18px;
  margin-top: 2px !important;
}

.buttonWrapper > div {
  display: inline-block !important;
}

._isDialog {
  display: var(--dialog-display);
  overflow: auto;
  position: fixed;
  inset: 0px;
  z-index: 1000;
}

.dimmedStyle {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  background: #000;
  opacity: 0.3;
}

._dimClicker {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.dialogBoxStyle {
  display: inline-block;
  position: absolute;
  top: var(--position-top);
  left: var(--position-left);
  padding: 0px 30px;
  background: #fff;
  border: 1px solid #e7e7e7;
  outline: none;
  z-index: 1200;
  min-height: 124px;
  min-width: 100px;
  box-sizing: border-box;
}

.dialog_data {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.dialog_title_area {
  height: 57px;
  padding: 22px 0px 8px;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  cursor: move;
}

.dialog_title {
  margin: 0px;
  font-size: 18px;
  line-height: 1.28;
  height: 27px;
  letter-spacing: -0.9px;
}

.dialog_subTitle {
  text-align: left;
  font-family: inherit;
  color: #a6a6a6;
  line-height: 24px;
  font-size: 13px;
  vertical-align: middle;
}

.dialog_title + .dialog_subTitle,
.dialog_title + .notHaveTitleCLoseButton {
  margin-left: 6px;
}

.notHaveTitleCLoseButton {
  color: black;
  font-size: 18px;
  font-family: inherit;
  line-height: 1.28;
  text-align: left;
  height: 27px;
  letter-spacing: -0.9px;
  font-weight: bold;
}

.dialog_button {
  cursor: initial;
  box-sizing: border-box;
  display: inline-block;
  outline: none;
  position: absolute;
  text-align: center;
  vertical-align: top;
  border-radius: 0px;
  border: 0px none;
  background: transparent;
  color: #4a4a4a;
  text-decoration: none;
  font-family: 돋움, Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif;
  font-size: 12px;
  height: 30px;
  letter-spacing: -0.5px;
  line-height: initial;
  padding: 0px;
  width: 30px;
  top: 17px;
  right: -8px;
}
.dialog_button > span {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  background: url("https://static.wehago.com/imgs/common/sp_lux.png") -140px -275px;
  line-height: 100em;
  vertical-align: middle;
  width: 13px;
  height: 13px;
  text-align: center;
}

.data {
  outline: none;
  flex-grow: 1;
  border-top: 1px solid #d9d9d9;
  overflow: auto;
  position: relative;
}

.dataWrapper {
  display: inline-block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 9px;
  bottom: 30px;
}