@charset "UTF-8";
.root {
  margin-bottom: 10px;
  /* 아코디언 */
  /* children 영역 */
}
.root .wrapper {
  margin: 0px;
  padding: 0px;
  position: initial;
}
.root .wrapper > div {
  position: relative;
}
.root .wrapper .accordion {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  outline: none;
  position: relative;
  text-align: left;
  vertical-align: top;
  border-radius: 0px;
  border-width: 2px 0px 1px;
  border-style: solid none;
  border-top-color: #666666;
  border-right-color: initial;
  border-bottom-color: #dcdbdc;
  border-left-color: initial;
  border-image: initial;
  background: transparent;
  width: 100%;
  font-family: inherit;
  margin-top: -1px;
  padding: 0px 0px 0px 10px;
  font-size: 13px;
  align-items: center;
  height: 32px;
  line-height: 12px;
  /* imageUrl */
  /* OBTButton 영역 */
  /* 위아래 아이콘 */
  /* 타이틀 영역 */
}
.root .wrapper .accordion.disabled {
  cursor: not-allowed;
}
.root .wrapper .accordion.large {
  height: 40px;
}
.root .wrapper .accordion.largeHavingImageUrl {
  height: 40px;
  padding-left: 38px;
}
.root .wrapper .accordion.defaultHavingImageUrl {
  padding-left: 34px;
}
.root .wrapper .accordion > .imageUrl {
  position: absolute;
  top: 50%;
  display: block;
  left: 10px;
  width: 18px;
  height: 18px;
  margin-top: -10px;
  text-align: center;
}
.root .wrapper .accordion > .imageUrl > img {
  width: 18px;
  height: 18px;
}
.root .wrapper .accordion > .imageUrl > img.large {
  width: 22px;
  height: 22px;
  margin-top: -3px;
}
.root .wrapper .accordion > .button {
  position: absolute;
  top: 50%;
  right: 30px;
  z-index: 10;
  margin-top: -10px;
}
.root .wrapper .accordion > .button.useSeparator {
  right: 24px;
}
.root .wrapper .accordion > .button > .separator {
  margin-left: 2px;
}
.root .wrapper .accordion > .button .stateLabel {
  margin-top: 1px;
  height: 18px;
  border-radius: 10px;
  padding: 0px 6px;
  font-size: 11px;
  line-height: 18px;
  color: white;
  text-align: center;
  min-width: 4em;
  pointer-events: none;
  margin-right: 10px;
}
.root .wrapper .accordion > .arrowIcon {
  background: url("./Images/ic_arrow_down_01_m_normal.png") no-repeat;
  display: inline-block;
  overflow: hidden;
  line-height: 100em;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  text-align: center;
  position: absolute;
  right: 6px;
  z-index: 30;
}
.root .wrapper .accordion > .arrowIcon.open {
  background: url("./Images/ic_arrow_up_01_m_normal.png") no-repeat;
}
.root .wrapper .accordion > .title {
  color: #4a4a4a;
  font-weight: lighter;
}
.root .wrapper .accordion > .title.open {
  color: #1c90fb;
  font-weight: bold;
}
.root .wrapper .accordion > .title.disabled {
  color: #a6a6a6;
}
.root .contents {
  display: block;
  border-top: 0px;
  border-bottom: 1px solid #dcdbdc;
  background: transparent;
  padding: 14px 10px;
  margin: 0px;
  position: relative;
  opacity: 1;
}
.root .contents.useSection {
  padding: 0px;
}
.root .contents.closed {
  opacity: 0;
  height: 0px;
  width: 0px;
  padding: 0px;
  border: none;
  overflow: hidden;
}