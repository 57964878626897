.rootContainer {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;

  .root {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background: white;

    .navRoot {
      border-right: 2px solid lightgray;
      box-sizing: border-box;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 10px;
      min-width: 280px;
      display: flex;
      flex-direction: column;
      padding: 10px 10px 20px 10px;

      .searchRoot {
        width: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        margin-bottom: 30px;
        position: relative;

        .searchIcon {
          position: absolute;
          background-image: url("./Images/example-search.png");
          width: 16px;
          height: 16px;
          top: calc(50% - 8px);
          right: 8px;
          pointer-events: none;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 0px;
          right: 0px;
          height: 1px;
          background-color: #8ba9ff;
        }
      }

      .listContainer {
        flex-grow: 1;
        flex-shrink: 1;
        position: relative;

        .list {
          position: absolute;
          left: 0px;
          top: 0px;
          right: 0px;
          bottom: 0px;

          .groupRoot {
            display: flex;
            flex-direction: column;

            .groupTitle {
              font-size: 16px;
              font-weight: bold;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
              padding-right: 10px;
              cursor: pointer;
              transition: color 200ms ease;

              &:hover {
                color: #8ba9ff;
              }

              .collapseButton {
                width: 16px;
                height: 16px;
                background-image: url("./Images/group-expand-more.png");
                background-position: center;
                background-repeat: no-repeat;

                &.collapsed {
                  background-image: url("./Images/group-expand-less.png");
                }
              }
            }

            .groupChildren {
              display: flex;
              flex-direction: column;
              transition: height 400ms ease-in-out;
              height: var(--height, max-content);
              overflow: hidden;

              &.collapsed {
                height: 0px;
              }
            }
          }

          .groupRoot:not(:last-child) {
            margin-bottom: 20px;
          }

          .example {
            margin-left: 1em;
            font-size: 14px;
            text-decoration: none;
            padding: 5px 0px;
            color: rgba(0, 0, 0, 0.5);
            transition: color 200ms ease;

            &:hover {
              color: #8ba9ff;
            }

            &.selected {
              color: #8ba9ff;
              font-weight: bold;
            }
          }
        }
      }
    }

    .contentsWrap {
      flex-grow: 1;
      position: relative;
      height: 100%;

      .contents {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        overflow: auto;
        padding: 10px 10px 0px 10px;
      }
    }
  }
}
