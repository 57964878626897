$month-height: 20px;
$day-height: 20px;
$title-height: $month-height + $day-height;
$item-height: 40px;
$panel-width: 300px;
$panel-padding: 10px;
$day-width: 20px;
$today-color: #bbdefb;

.root {
  display: flex;
  flex-direction: row-reverse;
  z-index: 0;

  & > .seriesPanel {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    overflow: hidden;
    position: sticky;
    z-index: 1;

    & > .title {
      height: $title-height;
      width: 100%;
      font-size: 14px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-self: flex-start;
      padding-left: $panel-padding;
      border-bottom: 1px solid #e6e6e6;
      box-sizing: border-box;
      position: sticky;
      top: 0px;
      min-height: $title-height;
      background: #ffffff;
      z-index: 1;
    }
    & > .panel {
      flex-grow: 1;
      flex-shrink: 1;
      width: 100%;

      & > .seriesItemRoot {
        width: 100%;
        height: $item-height;
        padding: 0px $panel-padding;
        box-sizing: border-box;
        border-bottom: 1px solid #e6e6e6;

        & > .indent {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          box-sizing: border-box;

          & > .serialItemCollapseButton {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            box-sizing: border-box;
            outline: none;
            border: none;
            background: url("../Images/icon-btn-arrow-down-normal.png") no-repeat center;

            &.collapsed {
              background: url("../Images/icon-btn-arrow-right-normal.png") no-repeat center;
            }
          }
          & .seriesItemText {
            font-size: 12px;
            line-height: $item-height;
            height: $item-height;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .tooltip {
            text-decoration: none;
            position: relative;
          }
          .tooltip > span:first-child {
            display: none;
            pointer-events: none;
          }
          .tooltip:hover > span:first-child {
            display: block;
            position: fixed;
            overflow: hidden;
            z-index: 100;
            line-height: initial;
          }
        }
      }
    }
  }

  & > .chartRoot {
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;

    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    border-left: none;
    margin-left: -1px;

    & > .scrollRoot {
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      & > .chartTitle {
        min-height: $title-height;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        z-index: 1;

        & > .chartMonthRoot {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          box-sizing: border-box;
          border-left: 1px solid #e6e6e6;
          border-bottom: 1px solid #e6e6e6;
          background: #ffffff;
          & > .chartMonth {
            font-size: 12px;
            flex-grow: 1;
            display: flex;
            align-items: center;
            padding: 0px 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            box-sizing: border-box;
          }

          & > .chartDays {
            margin-left: -1px;
            display: flex;
            flex-direction: row;
            align-items: center;
            box-sizing: border-box;

            & > .chartDay {
              width: $day-width;
              font-size: 10px;
              text-align: center;

              &.today {
                position: relative;
                z-index: 1;
                &::before {
                  content: "";
                  position: absolute;
                  left: 0px;
                  right: 0px;
                  top: 0px;
                  bottom: -1px;
                  border-top-left-radius: 3px;
                  border-top-right-radius: 3px;
                  background-color: $today-color;
                  z-index: -1;
                }
                &::after {
                  content: "";
                  position: absolute;
                  width: 0px;
                  height: 0px;
                  border: 10px solid transparent;
                  border-top-color: $today-color;
                  z-index: 0;
                  left: 0px;
                  top: 15px;
                }
              }
            }
          }
        }
      }
      & .chartContents {
        width: 100%;
        flex-grow: 1;
        background-image: linear-gradient(90deg, #e6e6e6 1px, transparent 1px);
        background-size: 20px 20px;
        background-position: 0px;
        position: relative;

        & > .chartContentsRoot {
          position: absolute;
          left: 0px;
          top: 0px;
          right: 0px;
          bottom: 0px;

          & > .holidays {
            & > * {
              position: absolute;
              top: 0px;
              height: 100%;
              width: calc(#{$day-width} - 1px);
            }

            & > .sunday {
              background-color: #dae2f3;
            }
            & > .saturday {
              background-color: #dae2f3;
            }
            & > .today {
              &::after {
                content: "";
                position: absolute;
                width: 4px;
                height: 100%;
                left: calc(50% - 3px);
                top: 0px;
                background-color: $today-color;
              }
            }
          }

          & > .contents {
            display: flex;
            flex-direction: column;

            & > .barRoot {
              position: relative;
              width: 100%;
              height: $item-height;

              & > .bar {
                position: absolute;
                top: 10px;
                bottom: 10px;

                & .barInner {
                  display: inline-block;
                }

                .tooltip {
                  text-decoration: none;
                  position: relative;
                }
                .tooltip > span:first-child {
                  display: none;
                  pointer-events: none;
                }
                .tooltip:hover > span:first-child {
                  display: block;
                  position: fixed;
                  overflow: hidden;
                  z-index: 100;
                }
              }
            }
          }
        }
      }
    }
  }

  & > * {
    box-sizing: border-box;
  }
}
