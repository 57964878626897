.wrapper {
    border: 1px solid rgb(219,219,219);
    box-shadow: 0px 1px 1px 0px rgba(6,6,6,0.1);
    outline: none;
    background-color: #ffffff;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
}

.dialogList {
    height: 22px;
    padding: 0px 5px;
    line-height: 22px;
    cursor: pointer;
}
