$contentsPrimaryColor: #116979;
$anchor-width: 150px;

@mixin textButton {
  align-self: center;
  padding: 5px 20px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-right: 10px;
  background-color: #ffffff;
  transition: border-color 0.5s ease, background-color 0.5s ease;

  &.on,
  &:hover {
    border-color: #ad8afd;
  }

  &.on {
    background-color: #ad8afd;
    color: #ffffff;
  }
}

@mixin iconButton {
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: relative;

  &:hover::before {
    content: "";
    background-color: #8ba9ff;
    opacity: 0.5;
    position: absolute;
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 50%;
    pointer-events: none;
  }
}

@mixin iconTextButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    color: #8ba9ff;
    font-weight: bold;
  }

  &::before {
    content: "";
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 3px;
  }
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .titleRoot {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    position: relative;
    margin-bottom: 30px;

    & > * {
      flex-grow: 0;
      flex-shrink: 0;
    }

    .componentName {
      font-size: 20px;
      font-weight: bold;
      margin-right: 10px;
    }

    .description {
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 14px;
      color: rgba($color: #000000, $alpha: 0.7);
      display: flex;
      flex-direction: row;
      align-items: center;

      .closeExtraButton {
        margin-left: 5px;
        width: 16px;
        height: 16px;
        background-image: url("./Images/attributes-close.png");
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    }

    .extraButton {
      @include textButton();
    }

    .attributeButton {
      @include textButton();
    }

    .anchorButton {
      @include textButton();
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -9px;
      left: 0px;
      right: 0px;
      height: 1px;
      background-color: #8ba9ff;
    }
  }

  .contentsRoot {
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
    overflow: hidden;

    .contentsWrap {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      overflow: hidden;
      overflow-y: auto;
      transition: right 0.3s ease;
      scroll-behavior: smooth;
      padding: 0px 2px;

      & > div > div {
        scroll-behavior: smooth;
      }

      &.anchorsShown {
        right: $anchor-width;
      }

      .contents {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-bottom: 10px;

        & > *:not(:last-child) {
          margin-bottom: 20px;
        }

        .exampleRoot {
          width: 100%;
          display: flex;
          flex-direction: column;
          border: 1px solid #dae1e7;
          border-radius: 3px;
          box-sizing: border-box;
          overflow: hidden;
          box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.12);

          &:first-child {
            border: 1px solid rgb(202, 219, 233);
            box-shadow: 0px 2px 1px -1px rgba(202, 219, 233, 0.2), 0px 1px 1px 0px rgba(202, 219, 233, 0.14),
              0px 1px 3px 0px rgba(202, 219, 233, 0.12);

            & > .title {
              color: #4ea1d3;
            }

            & > .extraDescription {
              background: rgba(202, 219, 233, 0.2);
            }

            & > .footer {
              background: rgba(202, 219, 233, 0.4);

              & > .viewCodeText {
                color: #9cc2d9;

                &:hover {
                  color: #84aec7;
                }
              }
            }
          }

          .title {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            padding: 10px 20px;
            padding-right: 50px;

            & > span {
              min-width: 10em;
              &:hover {
                cursor: pointer;
                color: #8ba9ff;
              }
            }

            & >.dataGridTitle {
                width: 300px;
                display: block;
            }

            .description {
              position: relative;
              font-size: 14px;
              color: rgba($color: #000000, $alpha: 0.7);
              margin-left: 10px;
            }

            .collapseButton {
              position: absolute;
              top: calc(50% - 8px);
              right: 20px;
              width: 16px;
              height: 16px;
              background-image: url("./Images/example-expand-more.png");
              background-position: center;
              background-repeat: no-repeat;
              cursor: pointer;

              &.collapsed {
                background-image: url("./Images/example-expand-less.png");
              }
            }
          }

          .extraDescription {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.7);
            background-color: #f4f4f4;
            margin: 0px 10px 10px 10px;
            padding: 10px;
            border-radius: 3px;

            &.isDataGridExample {
              width: 100%;
              margin: 0px 10px 0px 10px;
            }
          }

          .component {
            position: relative;
            padding: 10px;
          }

          .footer {
            margin-top: 10px;
            min-height: 20px;
            padding: 10px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #f4f4f4;

            .viewCodeButton {
              display: inline-block;
              @include iconButton();
              background-image: url("./Images/example-code.png");
            }

            .viewCodeText {
              margin-left: 10px;
              font-size: 12px;
              line-height: 16px;
              color: #cccccc;
              cursor: pointer;

              &:hover {
                color: #a1aca7;
                font-weight: bold;
              }
            }
          }

          .code {
            & > * {
              margin: 0px !important;
            }
          }

          &.collapsed > *:not(.title) {
            display: none;
          }

          &.collapsed > .title:hover {
            cursor: pointer;
            color: #8ba9ff;
          }
        }
      }
    }

    .anchors {
      position: absolute;
      right: calc(#{$anchor-width} * -1);
      top: 0px;
      bottom: 0px;
      width: $anchor-width;
      display: flex;
      flex-direction: column;
      transition: right 0.3s ease;
      overflow: hidden;

      &.on {
        right: 0px;
      }

      .title {
        font-size: 16px;
        font-weight: bold;
        text-align: right;
        width: 100%;
        padding-top: 10px;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        .toolbox {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-left: 20px;

          & > *:not(:last-child) {
            margin-right: 5px;
          }

          .close {
            @include iconButton();
            background-image: url("./Images/anchors-close.png");
          }
        }

        &::after {
          content: "";
          position: absolute;
          right: 0px;
          left: 10px;
          bottom: -10px;
          height: 1px;
          background-color: rgba($color: #000000, $alpha: 0.3);
        }
      }

      .anchor {
        font-size: 14px;
        color: rgba($color: #000000, $alpha: 0.7);
        overflow: hidden;
        cursor: pointer;
        width: 100%;
        text-align: right;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &:hover {
          font-weight: bold;
          color: #8ba9ff;
        }
      }

      .footer {
        margin-top: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 14px;
        color: rgba($color: #000000, $alpha: 0.7);

        & > *:not(:last-child) {
          margin-bottom: 10px;
        }

        .top {
          @include iconTextButton();
          &::before {
            background-image: url("./Images/anchors-scrollTop.png");
          }
        }

        .fold {
          @include iconTextButton();
          &::before {
            background-image: url("./Images/anchors-fold.png");
          }
        }

        .unfold {
          @include iconTextButton();
          &::before {
            background-image: url("./Images/anchors-unfold.png");
          }
        }

        &::before {
          content: "";
          position: absolute;
          right: 0px;
          left: 10px;
          top: -10px;
          height: 1px;
          background-color: rgba($color: #000000, $alpha: 0.3);
        }
      }
    }

    .attributeRoot {
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      height: 0px;
      overflow: hidden;
      max-height: 40%;
      background-color: #f7f7f7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      scroll-behavior: smooth;
      overflow-y: auto;
      transition: height 0.5s;
      z-index: 999;

      & > div > div {
        scroll-behavior: smooth;
      }

      &.show {
        height: var(--height, 0px);
        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.5);
      }

      &.show.fullscreen {
        max-height: none;
        top: 0px;
        height: auto;
        border-radius: 0px;
      }

      .attributes {
        .sticky {
          position: sticky;
          top: 0px;
          background: #f7f7f7;
          padding-bottom: 20px;
          z-index: 1;

          .title {
            font-size: 20px;
            padding: 10px 20px 0px 20px;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .toolbox {
              display: flex;
              flex-direction: row;
              align-items: center;

              .top {
                @include iconButton();
                background-image: url("./Images/attributes-scrollTop.png");
                margin-right: 10px;
              }

              .full {
                @include iconButton();
                background-image: url("./Images/attributes-fullscreen.png");
                background-size: cover;
                margin-right: 10px;

                &.on {
                  background-image: url("./Images/attributes-fullscreen-exit.png");
                  background-size: cover;
                }
              }

              .close {
                @include iconButton();
                background-image: url("./Images/attributes-close.png");
              }
            }
          }

          &::after {
            content: "";
            position: absolute;
            bottom: 10px;
            left: 10px;
            right: 10px;
            height: 1px;
            background-color: #8ba9ff;
          }
        }

        .attributeTitle {
          padding-left: 15px;
          padding-bottom: 5px;
          font-size: 15px;
          font-weight: bold;
        }

        .tableContents {
          margin: 0px 10px;
          width: calc(100% - 20px);
          margin-bottom: 10px;
          background-color: #ffffff;
          border: 1px solid #e6e6e6;
          word-break: break-all;
          table-layout: fixed;

          & pre {
            white-space: pre-wrap;
          }

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          thead {
            tr {
              th {
                font-size: 14px;
                padding: 5px 0px;
                background: #fafafa;
                color: black;
                position: relative;
                border: 1px solid #e6e6e6;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 13px;
                padding: 5px;
                border-bottom: 1px solid #e6e6e6;

                &:nth-child(1) {
                  color: #6382d6;
                  font-weight: bold;
                }

                &:nth-child(2) {
                  color: #fb709f;
                }

                &:not(:last-child) {
                  border-right: 1px solid #e6e6e6;
                }
              }

              &:last-child {
                border-bottom: none;
              }

              // &:nth-child(2n) {
              //   background-color: #f7f7f7;
              // }

              &:hover {
                background-color: #c0e0ff;
              }
            }
          }
        }
      }
    }

    .extraRoot {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      overflow: hidden;
      scroll-behavior: smooth;
      overflow-y: auto;
      transition: height 0.5s;
      z-index: 999;
      background-color: #ffffff;
    }
  }
}
