@charset "UTF-8";
.LS_datepicker.active .LS_calendar, .LS_datepicker:hover .LS_calendar {
  display: block;
}

.LS_datepicker.active .datebx .inpbx, .LS_datepicker:hover .datebx .inpbx {
  border-color: #1c90fb;
}

.LS_datepicker.yearpicker .LS_calendar {
  width: 192px;
}

.LS_datepicker.yearpicker .LS_calendar .date_tbl .date_day button {
  width: 43px;
  height: 36px;
}

.LS_calendar {
  width: 216px;
  border: 1px solid #a3a3a3;
  background: #fff;
  box-shadow: 1px 1px 1px #ececec;
  font-family: "Nanum Square", "돋움", Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif !important;
}

.LS_calendar .date_select {
  position: relative;
  height: 40px;
  background: #fafafa;
  line-height: 38px;
  text-align: center;
  vertical-align: top;
}

.LS_calendar .date_select .btn {
  position: absolute;
  top: 8px;
  width: 23px;
  height: 23px;
  border: 1px solid transparent;
  background: 0 0;
  line-height: 23px;
  text-align: center;
}

.LS_calendar .date_select .btn:hover {
  border-color: #ccc;
}

.LS_calendar .date_select .btn:hover .sp_selene {
  opacity: 1;
}

.LS_calendar .date_select .btn .sp_selene {
  width: 10px;
  height: 9px;
  margin-top: 6px;
  opacity: 0.5;
}

.LS_calendar .date_select .btn_prev_year {
  left: 15px;
}

.LS_calendar .date_select .btn_prev_year .sp_selene {
  background-position: -50px -100px;
}

.LS_calendar .date_select .btn_prev_mon {
  left: 40px;
}

.LS_calendar .date_select .btn_prev_mon .sp_selene {
  background-position: -50px -75px;
}

.LS_calendar .date_select .date_day_title {
  display: inline-block;
  vertical-align: top;
  font-family: tahoma, sans-serif;
  font-size: 14px;
  color: #000;
}

.LS_calendar .date_select .btn_next_mon {
  right: 40px;
}

.LS_calendar .date_select .btn_next_mon .sp_selene {
  background-position: -75px -75px;
}

.LS_calendar .date_select .btn_next_year {
  right: 15px;
}

.LS_calendar .date_select .btn_next_year .sp_selene {
  background-position: -75px -100px;
}

.LS_calendar .date_tbl {
  padding: 10px;
}

.LS_calendar .date_tbl table {
  width: 100%;
  border-collapse: collapse;
  border: 0;
  text-align: center;
  table-layout: fixed;
  border-spacing: 0;
}

.LS_calendar .date_tbl td, .LS_calendar .date_tbl th, .LS_calendar .date_tbl tr:first-child td {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border: 0 none !important;
  background: 0 0;
  border-spacing: 0;
}

.LS_calendar .date_tbl .date_week {
  height: 21px;
  padding: 0;
  border: 0 none;
  background: 0 0;
  font-size: 11px;
  font-family: "돋움", Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif !important;
  color: #8d8d8d;
  letter-spacing: -0.5px;
  text-align: center;
}

.LS_calendar .date_tbl .date_day button {
  display: block;
  width: 28px;
  height: 21px;
  border: 1px solid transparent;
  background: 0 0;
  color: #4a4a4a;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
  outline: 0;
  font-size: 11px;
}

.LS_calendar .date_tbl .date_day button span {
  font-size: 12px;
  font-family: "돋움", Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif !important;
}

.LS_calendar .date_tbl .date_day button:hover {
  border-color: #ccc;
}

.LS_calendar .date_tbl .date_day_sun button {
  color: red;
}

.LS_calendar .date_tbl .date_day_holiday button {
  color: red;
}

.LS_calendar .date_tbl .date_day_holiday button:hover {
  color: red;
}

.LS_calendar .date_tbl .date_day_prev_mon button {
  color: #c2c2c2;
}

.LS_calendar .date_day_next_mon button {
  color: #c2c2c2;
}

.LS_calendar .date_tbl .date_day_today button {
  background: #f0f0f0;
}

.LS_calendar .date_tbl .date_day_selected button {
  background: #1c90fb;
  color: #fff;
}

.LS_calendar .date_tbl .date_day_selected button:hover {
  border-color: #0072dc;
}

.LS_calendar .date_tbl .date_day_gap button {
  background: #e9f5ff;
}

.LS_calendar .select_area {
  clear: both;
  padding: 10px;
  border-top: 1px solid #e6e6e6;
  white-space: nowrap;
}

.LS_calendar .select_area .btn {
  display: inline-block;
  position: relative;
  line-height: 20px;
  height: 21px;
  padding: 1px 6px 0;
  border: 1px solid #e0e0e0;
  background: #fff;
  font-size: 11px;
  font-family: "돋움", Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif !important;
  font-weight: 400;
  color: #4a4a4a;
  letter-spacing: -0.5px;
  text-align: center;
  vertical-align: top;
  box-sizing: border-box;
  cursor: pointer;
  outline: 0;
}

.LS_calendar .select_area .btn_group {
  display: inline-block;
}

.LS_calendar .select_area .btn_group .btn {
  margin: 0 5px;
}

.LS_calendar .select_area .btn_group2 {
  float: right;
}

.LS_calendar .select_area .btn_group2 .btn {
  margin: -1px 0 0 -1px;
  font-weight: 400;
}

.LS_calendar .select_area .solar_area {
  display: inline-block;
  font-size: 12px;
  font-family: "돋움", Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif !important;
  color: #999;
  letter-spacing: -0.5px;
  vertical-align: top;
  padding-top: 5px;
}

.LS_calendar .select_area .solar_area span {
  margin: 0 3px 0 0;
}

.LS_datepicker.gap .LS_calendar {
  width: 429px;
}

.LS_smart_calendar .LS_calendar {
  font-family: "Nanum Square", "돋움", Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif !important;
  display: block;
  position: relative;
  width: 100%;
  border: 0;
  box-shadow: none;
}

.LS_smart_calendar .LS_calendar .date_tbl {
  padding-left: 8px;
}

.LS_smart_calendar .LS_calendar .date_tbl .date_day button {
  position: relative;
}

.LS_smart_calendar .LS_calendar .date_tbl colgroup col {
  width: 27px !important;
}

.LS_smart_calendar .LS_calendar .date_tbl .date_day_check button:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 0;
  border-color: transparent #00b4b7 transparent transparent;
}

.LS_smart_calendar .LS_calendar .date_select .btn_next_year, .LS_smart_calendar .LS_calendar .date_select .btn_prev_year {
  display: none;
}

.LS_mini_calendar .LS_calendar {
  display: block;
  position: relative;
  width: 100%;
  border: 0;
  box-shadow: none;
  font-family: "Nanum Square", "돋움", Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif !important;
}

.LS_mini_calendar .LS_calendar .date_tbl.check_all td {
  background: #f0f0f0;
}

.LS_mini_calendar .LS_calendar .date_tbl .check_week td, .LS_mini_calendar .LS_calendar .date_tbl .check_week th {
  background: #f0f0f0;
}

.LS_mini_calendar .LS_calendar .date_tbl .date_day button {
  position: relative;
  width: 25px;
}

.LS_mini_calendar .LS_calendar .date_tbl table {
  table-layout: auto;
}

.LS_mini_calendar .LS_calendar .date_tbl colgroup col {
  width: 27px !important;
}

.LS_mini_calendar .LS_calendar .date_tbl .today_check button {
  border-color: #ccc;
}

.LS_mini_calendar .LS_calendar .date_tbl .date_day_check button:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 0;
  border-color: transparent #00b4b7 transparent transparent;
}

.LS_mini_calendar .LS_calendar .date_tbl .date_day_selected button {
  border-color: transparent;
}

.LS_datepicker.gap.yearpicker .LS_calendar {
  width: 384px;
}

.LS_datepicker.gap.yearpicker .LS_calendar .date_tbl .date_day button {
  width: 43px;
  height: 36px;
}

.LS_datepicker.gap.yearpicker .LS_calendar .select_area .btn_group2 {
  float: none;
  text-align: right;
}