.referboxWrapper {
  position: relative;
    
  .referbox {
    height: 39px;
    display: flex;
    flex-direction: row;
    align-items: center;
    visibility: visible !important;
    border: 1px solid #d4dfe7;
    background-color: #f3f7fa;

    .title {
      flex-grow: 1;
      padding-left: 4px;
      font-size: 12px;
      letter-spacing: -0.65px;
      text-align: left;
      color: #000000;
    }

      & > button {
        top: 13px;
        right: 15px;
        width: 15px;
        height: 15px;
        border-radius: 0;
        background: url("../Images/icon-btn-arrow-top-normal.png") 50% no-repeat;
        position: absolute;
        padding: 0;
        border: none;
        outline: none;
      }
    }

    .noreferbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      visibility: visible !important;
      border: 1px solid #d4dfe7;
      background-color: #f3f7fa;
      border-radius: 3px;
      .notitle {
        padding: 12px 15px;

        & > ul > li,
        & > ol > li {
          color: #4a4a4a;
          font-size: 12px;
          line-height: 1.54;
          letter-spacing: -0.65px;
          text-align: left;
          list-style: none;
          background-color: transparent !important;
        
        }
      }
    }

    .nochildreferbox {
      height: 39px;
      display: flex;
      flex-direction: row;
      align-items: center;
      visibility: visible !important;
      border: 1px solid #d4dfe7;
      background-color: #f3f7fa;
      border-radius: 3px;
  
      .title {
        flex-grow: 1;
        padding-left: 4px;
        font-size: 12px;
        letter-spacing: -0.65px;
        text-align: left;
        color: #000000;
      }
  
        & > button {
          top: 13px;
          right: 15px;
          width: 15px;
          height: 15px;
          border-radius: 0;
          background: url("../Images/icon-btn-arrow-top-normal.png") 50% no-repeat;
          position: absolute;
          padding: 0;
          border: none;
          outline: none;
        }
      }

    .content {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 100%;
      border-radius: 0px 0px 3px 3px;
      border: solid 1px #d4dfe7;
      background-color: #f3f7fa;
      padding: 12px 15px;
      overflow: hidden;
      z-index: 100;

      & > ul > li,
      & > ol > li {
        color: #4a4a4a;
        font-size: 12px;
        line-height: 1.54;
        letter-spacing: -0.65px;
        text-align: left;
        list-style: none;
        background-color: transparent !important;
        
      }
    }
  }

.closed {
  .referbox {
    .content {
      height: 0px;
      border: none;
      border-radius: 0px 0px 3px 3px;
      padding: 0px 38px;

      & > ul > li,
      & > ol > li {
          color: #4a4a4a;
          font-size: 12px;
          line-height: 1.54;
          letter-spacing: -0.65px;
          text-align: left;
          list-style: none;
          background-color: transparent !important;
          
        }
    }
    & > button {
    background: url("../Images/icon-btn-arrow-down-normal.png") 50% no-repeat;
    }
  
  }
}

.icon {
  padding-left: 15px;
  & > img {
    display: block; 
    margin: 0px auto;
  }
}

