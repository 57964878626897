.root {
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  font-weight: normal;
  letter-spacing: normal;
  color: #000000;
  // outline: none;
  background-color: transparent;
  cursor: pointer;

  & > .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & > .labelText {
      position: relative;
      line-height: normal;
    }
    & > *:not(:last-child) {
      margin-right: 2px;
    }
  }

  &.typebig {
    height: 32px;
    padding: 0px 16px;
    & > .wrapper > .labelText {
      font-size: 15px;
    }
  }
  &.typedefault {
    height: 27px;
    padding: 0px 10px;
    & > .wrapper > .labelText {
      font-size: 12px;
    }
  }
  &.typesmall {
    height: 21px;
    padding: 0px 8px;
    & > .wrapper > .labelText {
      font-size: 11px;
    }
  }
  &.typeicon {
    width: 25px;
    height: 25px;
    position: relative;
    border-radius: 12.5px;
    border: none;
    padding: 0px;
    font-size: 12px;

    & > .wrapper {
      flex-direction: column;
      & > *:not(:last-child) {
        margin-right: none;
        margin-bottom: 2px;
      }
    }
  }

  &.typeicon {
    &.overBackground {
      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.disabled:not(.typeicon) {
    border-color: #cccccc;
    background-color: #f5f5f5;
    color: #a6a6a6;
    cursor: not-allowed;
  }

  &:not(.typeicon):not(.disabled) {
    &.themedefault {
      background-image: linear-gradient(to top, #f0f0f0, #ffffff);
      &:hover {
        border: solid 1px #9f9f9f;
      }
      &:active {
        background-image: linear-gradient(to bottom, #dddddd, #f5f5f5);
      }
    }
    &.themeblue {
      border-color: #1c90fb;
      color: #ffffff;
      background-image: linear-gradient(to top, #1c90fb, #5eb5fd);
      &:hover {
        border: solid 1px #136ad5;
        background-image: linear-gradient(to top, #3280e0, #3ba1ff);
        // & > .wrapper > .labelText::after {
        //   background-color: #ffffff;
        // }
      }
      &:active {
        background-image: linear-gradient(to bottom, #3280e0, #3ba1ff);
        // & > .wrapper > .labelText::after {
        //   background-color: #ffffff;
        // }
      }
    }
    &.themeskyBlue {
      border-color: #aedafe;
      background-image: linear-gradient(to top, #d0ebff, #edf6ff);
      &:hover {
        border-color: #5eb5fd;
        // background-image: linear-gradient(to top, #a0d6fe, #e6f5ff);
      }
      &:active {
        background-image: linear-gradient(to bottom, #a4d6fe, #e6f5ff);
      }
    }
  }

  &:not(.typeicon).themedrawer {
    border-color: #b5b5b5;
    background: #50596c;
    color: #ffffff;
    &:hover {
      border-color: #e6e6e6;
      // background-image: linear-gradient(to top, #a0d6fe, #e6f5ff);
    }
    &:active {
      border-color: #b5b5b5;
    }

    &:disabled {
      color: #b3b3b3;
      border-color: #999999;

      &:hover {
        border-color: #999999;
      }
      &:active {
        border-color: #999999;
      }
    }
  }

  &:not(.typeicon).themedrawerImportant {
    border-color: #1c90fb;
    background: #1c90fb;
    color: #ffffff;
    &:hover {
      border-color: #2ba7ff;
      background: #2ba7ff;
      // background-image: linear-gradient(to top, #a0d6fe, #e6f5ff);
    }
    &:active {
      border-color: #1c90fb;
      background: #1c90fb;
    }

    &:disabled {
      color: #737373;
      border-color: #b3b3b3;
      background: #b3b3b3;

      &:hover {
        border-color: #b3b3b3;
        background: #b3b3b3;
      }
      &:active {
        border-color: #b3b3b3;
        background: #b3b3b3;
      }
    }
  }
}
