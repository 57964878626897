.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 10px 0px 10px;
  border: 1px solid #dae1e7;
  border-radius: 3px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  & > * {
    margin-bottom: 10px;
  }

}
