.iconstyle {
    & > div > div > button::before {
        content: "";
        width: 25px;
        display: inline-block;
    }

    &.large > div > div > button::before {
        width: 29px;
    }
}

.default {
    margin-bottom: 10px;
}

.icon {
    width: 18px;
    height: 18px;

    &.large {
        width: 22px;
        height: 22px;
        margin-top: -3px;
    }
}

.stateLabel {
    margin-top: 1px;
    height: 18px;
    border-radius: 10px;
    padding: 0px 6px;
    font-size: 11px;
    line-height: 18px;
    color: white;
    text-align: center;
    min-width: 4em;
    pointer-events: none;
}
.buttonsWrap {
    display: flex;
    align-items: center;
        &::after {
        content: '';
        margin-left:10px;
        border-right:1px solid #e6e6e6;
        height:15px;
    }
}
