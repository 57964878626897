.wrapper {
    position: relative;
    margin: 0px;
    padding: 0px;
    cursor: auto;

    .wrapperSpan {
        padding: 3px 6px;
        display: flex;
        height: 19px;
        border: 1px solid rgb(219, 219, 219);
        background: rgb(255, 255, 255);
        line-height: 19px;
        margin: 0px;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        &:focus-within {
            border: 1px solid rgb(103, 179, 246) !important;
        }

        &.readOnly {
            border: 1px solid #cccccc !important;
            cursor: default;
        }

        &.disabled {
            cursor: not-allowed;
            user-select: none;
            color: #a6a6a6;
            background-color: #f2f2f2;
        }

        &.required {
            background: #fef4f4;
        }

        .dropDownButton {
            background: rgb(255, 255, 255);
            outline: none;
            border: none;
            display: inline-block;
            line-height: 17px;

            &.disabled {
                cursor: not-allowed !important;
                user-select: none !important;
            }

            & > .dropDownButtonImg {
                &.disabled {
                    background: #f2f2f2;
                }

                &.required {
                    background: #fef4f4;
                }
            }
        }
    }

    .wrapperInput {
        display: block;
        width: 100%;
        border: 0px;
        background: transparent;
        font-size: 12px;
        font-family: inherit;
        line-height: inherit;
        color: rgb(0, 0, 0);
        outline: none;
        margin: 0px;
        padding: 0px;
        cursor: auto;
        
        &.readOnly {
            border: 1px solid #cccccc !important;
            cursor: default;
        }

        &.disabled {
            cursor: not-allowed;
        }
    }
}

.dropDown {
    border: 1px solid rgb(219, 219, 219);
    // border-top: none;
    box-shadow: 0px 1px 1px 0px rgba(6,6,6,0.1);
    outline: none;
    background-color: #ffffff;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;

    &.dropDownWithAddPanel {
        border-top: none;
    }

    .dropDownList {
        display: block;
        height: 27px;
        padding: 0px 10px;
        line-height: 27px;
        cursor: pointer;
        border-bottom: 0.3px solid rgba(219, 219, 219, 0.3);
        box-sizing: border-box;

        &.dropDownWithDeleteButton {
            display: flex;
        }

        & > .deleteButton {
            width: 12px;
            height: 12px;
            margin-top: 7px;
        }

        .dropDownListText {
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            & > img {
                height: 20px;
                width: 20px;
                margin-top: 3px;
            }
        }
    }
}

.dataAddPanel {
    height: 35px;
    line-height: 35px;
    // padding: 0px 10px;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid rgb(219, 219, 219);
    border-top: none;
    background-color: white;
    cursor: pointer;
    color: rgb(72,143,243);
    font-size: 12px;
    width: inherit;

    & > .plusIcon {
        background: url("./Images/ic_add_02_l_over.png") no-repeat;
        display: inline-block;
        vertical-align: middle;
        line-height: 35px;
        width: 24px;
        height: 24px;
        margin-right: 3px;
        margin-bottom: 2px;
    }
}

.tooltip {
    display: initial;
}



