.hidden {
    & > div:first-child {
        overflow: hidden !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
    }
    & > div:first-child + div {
        height: 0px !important;
    }
    & > div:first-child + div + div {
        width: 0px !important;
    }
}
