@mixin image-button {
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    outline: none;
}

.sideBarWrapper {
    overflow-y: scroll;
}

.sideBarContent {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cccccc;
    background: #ffffff;
    box-sizing: border-box;
    font-size: 12px;
    outline: none;

    &.default {
        padding: 10px;
    }

    &.accordion {
        & > .sideBarTitle {
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 9px 0px 11px;

            & > .sideBarIcon {
                margin-right: 6px;
            }

            & > .sideBarImage {
                margin-right: 6px;
            }

            & > .sideBarTitleText {
                font-size: 12px;
                font-weight: bold;
                flex-grow: 1;
                cursor: pointer;

                &.focused {
                    color: #1c90fb;
                }
            }

            & > .sideBarTitleCollapse {
                @include image-button;
                width: 14px;
                height: 14px;
            }
        }

        & > .animationWrapper {
            overflow: hidden;
            height: var(--height, auto);
            position: relative;
            transition: height 300ms ease-in-out;

            & > .sideBarComponent {
                position: relative;
                bottom: 0px;
                left: 0px;
                right: 0px;
            }

            &.collapsed > .sideBarComponent {
                position: absolute;
            }
        }

        &:not(.collapsed) {
            & > .sideBarTitle {
                border-bottom: 1px solid rgb(190, 190, 190);

                & > .sideBarTitleCollapse {
                    background-image: url("./Images/ico_sb_arrow_up_normal.png");

                    &:hover {
                        background-image: url("./Images/ico_sb_arrow_up_over.png");
                    }
                }
            }
        }

        &.collapsed {
            & > .sideBarTitle {
                & > .sideBarTitleCollapse {
                    background-image: url("./Images/ico_sb_arrow_down_normal.png");

                    &:hover {
                        background-image: url("./Images/ico_sb_arrow_down_over.png");
                    }
                }
            }

            & > .animationWrapper {
                height: 0px;
            }
        }
    }
}

.log {
    & > .logMain {
        box-sizing: content-box;
        padding: 10px;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;

        & > .avartar {
            width: 42px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 10px;

            & > .avartarImage {
                background-image: url("./Images/avartar.gif");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 32px;
                height: 32px;
                border-radius: 16px;
            }

            & > .buttons {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                & > .email {
                    @include image-button;
                    background-image: url("./Images/email.png");
                    width: 18px;
                    height: 18px;
                    border: 1px solid rgb(211, 211, 211);
                }

                & > .talk {
                    @include image-button;
                    background-image: url("./Images/talk.png");
                    width: 18px;
                    height: 18px;
                    border: 1px solid rgb(211, 211, 211);
                }
            }
        }

        & > .logInfo {
            flex-grow: 1;
            font-size: 11px;

            & > .id {
                color: rgb(130, 130, 130);
            }
        }
    }

    & > .logDate {
        box-sizing: border-box;
        height: 34px;
        line-height: 14px;
        font-size: 11px;
        padding: 10px;
        border-top: 1px solid rgb(239, 239, 239);
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & > .logDateTitle {
            color: rgb(130, 130, 130);
        }
    }
}

// .memo {
//     padding: 10px;
//     padding-top: 5px;
//     display: flex;
//     flex-direction: column;

//     &>.title {
//         font-size: 11px;
//         color: rgb(141, 141, 141);
//         text-align: left;
//     }

//     &>.content {
//         border: 1px solid rgb(219, 219, 219);

//         &>.textField {
//             flex-grow: 1;

//             &>div>div>textarea {
//                 border: none !important;
//                 outline: none !important;
//                 padding: 5px !important;
//                 height: 110px;
//             }
//         }

//         &>.functions {
//             padding: 0px 5px 5px 5px;
//             display: flex;
//             flex-direction: row;
//             align-items: center;

//             &>.date {
//                 flex-grow: 1;
//                 font-size: 11px;
//                 color: rgb(141, 141, 141);
//             }

//             &>.add {
//                 @include image-button;
//                 width: 18px;
//                 height: 18px;
//                 background-image: url("./Images/ico_new_normal.png");
//                 margin-right: 5px;
//             }

//             &>.save {
//                 @include image-button;
//                 width: 18px;
//                 height: 18px;
//                 background-image: url("./Images/save.png");
//                 margin-right: 5px;
//             }

//             &>.delete {
//                 @include image-button;
//                 width: 18px;
//                 height: 18px;
//                 background-image: url("./Images/delete.png");
//             }
//         }
//     }

//     &>.attachfile {
//         margin-top: 10px;
//     }

//     &>.memoList {
//         margin-top: 10px;
//         border: 1px solid rgb(219, 219, 219);

//         &>.listWrapper {
//             border: 1px solid rgb(219, 219, 219);

//             &>.listHeader {
//                 background-color: #f5f5f5;
//                 height: 24px;
//                 color: #565656;
//                 width: 100%;
//             }

//             .columnWriter {
//                 float: left;
//                 width: 20%;
//                 text-align: center;
//             }

//             .columnContent {
//                 float: left;
//                 width: 50%;
//                 text-align: center;
//                 text-overflow: ellipsis;
//                 overflow: hidden;
//             }

//             .columnDate {
//                 float: left;
//                 width: 30%;
//                 text-align: center;

//             }
//         }
//     }
// }

.editTracker {
    & > .bullet {
        width: 3px;
        height: 3px;
        background-color: #585858;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }

    & > .subtitle {
        font-size: 12px;
        font-weight: bold;
        display: inline-block;
    }

    & > .mainInfo {
        margin-top: 10px;
        display: flex;

        & > .avartar {
            width: 42px;

            & > .avartarImage {
                background-image: url("./Images/avartar.gif");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 32px;
                height: 32px;
                border-radius: 16px;
            }
        }

        & > .userInfo {
            font-size: 11px;

            & > .infoItem {
                height: 16px;

                & > .label {
                    display: inline-block;
                    color: #8c8c8c;
                    width: 45px;
                    border-right: 1px solid #d9d9d9;
                    margin-right: 5px;
                }

                & > .data {
                    display: inline-block;
                }
            }
        }
    }

    & > .line {
        width: 180px;
        height: 1px;
        background-color: #e6e6e6;
        margin-top: 9px;
        margin-bottom: 9px;
    }

    & > .line2 {
        width: 180px;
        height: 1px;
        background-color: #e6e6e6;
        margin-top: 9px;
    }

    & > .dateInfo {
        & > .label {
            display: inline-block;
            margin-right: 38px;
            color: #8c8c8c;
            font-size: 11px;
        }

        & > .data {
            display: inline-block;
            font-size: 11px;
        }
    }
}

.obtbutton {
    padding: 0px;
}

.memo {
    // padding: 10px;
    // &>div>.memoLenth {
    //     float: left;
    //     margin-top: 7px;
    //     font-size: 11px;
    //     color: #8c8c8c;
    // }

    // &>div>.inputButtons {
    //     float: right;
    //     display: flex;

    //     &>.buttonItem {
    //         margin-left: 2px;
    //     }
    // }

    // &>.textFieldWrapper {
    //     margin-top: 2px;
    // }

    & .upload {
        background-color: red;

        & > div > :global(.UpDownLoader) > :global(.header) > :global(.title) {
            display: none;
        }

        & > div > :global(.UpDownLoader) > :global(.header) > :global(.btnBox) > :global(span) > :global(.btn_list) {
            display: none;
        }

        & > div > :global(.UpDownLoader) > :global(.header) > :global(.btnBox) > :global(span) > :global(.btn_detail) {
            width: 0px;
        }

        & > div > :global(.UpDownLoader) > :global(.header) > :global(.btnBox) > :global(span) > :global(.btn_thumb) {
            display: none;
        }

        & > div > :global(.UpDownLoader) > :global(.conBox) > :global(.noFile) > :global(.text) {
            font-size: 11px;
        }

        // &>div> :global(.UpDownLoader)> :global(.conBox)> :global(.flexBox .list)>div {
        //     max-width: 300px;
        // }

        // &>div> :global(.UpDownLoader)> :global(.conBox)> :global(.flexBox .list)>div>:global(.fb_div) {
        //     width: 300px;
        // }
    }

    & > .listWrapper {
        margin-top: 2px;

        & > .listButtons {
            display: flex;
            justify-content: flex-end;

            & > .deleteButton {
                margin-left: 2px;
                float: right;
            }
        }

        & > .list {
            border: solid 1px #cccccc;

            & > .listHeader {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f5f5f5;
                height: 24px;
                font-size: 11px;
                color: #565656;
                text-align: center;

                & > * {
                    flex-grow: 0;
                }

                & > .column1 {
                    width: 35%;
                    padding-left: 2px;
                    padding-right: 5px;
                }

                & > .column2 {
                    width: 42%;
                    flex-grow: 1;
                }

                & > .column3 {
                    padding-left: 5px;
                    padding-right: 2px;
                    width: 33%;
                }
            }

            & > .listItem {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 24px;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 11px;
                color: #565656;

                & > * {
                    flex-grow: 0;
                }

                & > .column1 {
                    width: 35%;
                    padding-left: 2px;
                    padding-right: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                & > .column2 {
                    width: 42%;
                    flex-grow: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                & > .column3 {
                    padding-left: 5px;
                    padding-right: 2px;
                    width: 33%;
                }
            }
        }
    }
}

.checkpen {
    & > .colorPicker {
        width: 100%;

        & > div > div {
            border: none !important;

            & > * {
                border: none !important;
            }

            & > *:nth-child(1),
            & > *:nth-child(4),
            & > *:nth-child(6) {
                margin-left: -6px;
                margin-right: -6px;
                font-size: 12px;
                color: #000000;
                background-color: #f5f5f5;
            }

            & > *:nth-child(8) {
                font-size: 12px;
            }
        }
    }

    & > .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 9px 0px;
        background-color: #f7f7f7;

        & > *:not(:last-child) {
            margin-right: 4px;
        }
    }
}

.schedule {
    & > .scheduleListHeader {
        display: flex;
        background-color: #f5f5f5;
        font-size: 12px;
        padding: 5px 10px;
        border-top: 1px solid #e6e6e6;
        display: flex;
        flex-direction: row;
        align-items: center;

        & > * {
            flex-grow: 0;
        }

        & > .selectedDate {
            font-size: 12px;
            flex-grow: 1;
        }

        & > .scheduleCount {
            color: #1c90fb;
            font-weight: bold;
        }
    }

    & > .scheduleList {
        margin-top: 13px;
        margin-bottom: 5px;

        & > .scheduleItem {
            padding: 0px 10px 8px 10px;

            & > .timeLine {
                color: #8c8c8c;
                text-align: left;
                font-size: 11px;
            }

            & > .itemLine {
                margin-top: 3px;
                display: flex;
                flex-direction: row;
                align-items: center;

                & > .gnbCode {
                    width: 27px;
                    height: 15px;
                    border-radius: 10px;
                    color: white;
                    text-align: center;
                    font-size: 9px;
                    font-weight: bold;
                    vertical-align: middle;
                    margin-right: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                & > .title {
                    width: 160px;
                    // max-height: 24px;
                    display: inline-block;
                    font-size: 11px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    // white-space: nowrap;
                }
            }

            & > .grayLine {
                border-top: 1px solid #e6e6e6;
                margin-top: 8px;
            }
        }
    }
}

.multiLineText {
    textarea {
        border: 0px !important;
    }
}
