.focusCheckBox {
    vertical-align: top;
    path {
        fill: #41a2fc;
    }
}

.LS_form {
    position: relative;
    display: inline-block;
}
.LS_form label .label_text {
    display: inline-block;
    padding-left: 18px;
    vertical-align: top;
}
.LS_form + .LS_form {
    margin-left: 20px;
}
.LS_form.vertical_align {
    display: block;
}
.LS_form.vertical_align + .LS_form {
    margin-left: 0;
}
