.obt_grid_root_class_name_hard .rg-dropdownlist {
    background: #fff;
    font-family: inherit;
    border: 1px solid rgb(50, 50, 50);
    box-shadow: rgba(0, 0, 0, 0.8) 1px 2px 5px;
    font-size: 12px;
    padding: 0px;
    margin: 0px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border: 1px solid rgb(163, 163, 163);
    overflow: auto !important;
}

.obt_grid_root_class_name_hard .rg-dropdown-item {
    height: 20px;
    line-height: 20px;
    padding: 0px 6px;
    margin: 0px;
    cursor: pointer !important;
}

.obt_grid_root_class_name_hard .rg-dropdown-select {
    height: 20px;
    line-height: 20px;
    padding: 0px 6px;
    margin: 0px;
    cursor: pointer !important;
    background: rgb(233, 244, 255);
    color: rgb(28, 144, 251);
}

.obt_grid_root_class_name_hard .rg-dropdown-select:hover {
    color: rgb(74, 74, 74);
}

.obt_grid_root_class_name_hard .rg-dropdown-item:hover {
    background: rgb(233, 244, 255);
    color: rgb(74, 74, 74);
}
/* 
.obt_grid_root_class_name_hard .rg-popup-menu {
    background: #ffeeb6;
    border: 1px solid rgb(200, 200, 200);
    box-shadow: rgba(0, 0, 0, 0.8) 1px 2px 5px;
    font-family: 나눔고딕코딩;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 10pt;
    padding: 4px;
    margin: 0px;
}

.obt_grid_root_class_name_hard .rg-popup-item {
    padding-top: 2px;
    padding-bottom: 2px;
    height: 20px;
    cursor: pointer;
}

.obt_grid_root_class_name_hard .rg-popup-item:hover {
    background-color: #ffd8d8;
}

.obt_grid_root_class_name_hard .rg-popup-separator-hr {
    height: 1px;
    border: 0px;
    margin: 2px;
    color: #777;
    background-color: #777;
}

.obt_grid_root_class_name_hard .rg-popup-expander {
    background-image: url("/resource/image/icon/menu_expander.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.obt_grid_root_class_name_hard .rg-popup-check {

} 
*/