.root {
    border: 1px solid #e6e6e6;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-top: none;
}

.headerCheckBox {
    & > div {
        & > label {
            & > span {
                & > svg {
                    background-color: white;
                }
            }
        }
    }
}

.checkstyle {
    display: flex;
    height: 100%;
    justify-content: center;
    padding-right: 10px;
    outline: none;
    & > div {
        width: 14px;
        & > label {
            & > span {
                & > svg {
                    background-color: white;
                }
            }
        }
    }
}

// .template {
//     li {
//     }
// }

.sortstyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fafafa;
    padding-left: 12px;
    padding-right: 3px;

    &.isOnlyHeaderComponent {
        padding: 8px 3px 5px 12px;
    }
}

.sortSpace {
    flex-grow: 1;
}

.head {
    display: flex;
    flex-direction: column;
    border-top: 2px solid #666;
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

.dropdownlist {
    display: flex;
    overflow: hidden;
    & > div {
        width: 170px !important;
        & > div > span:first-child {
            border: none !important;
            font-weight: bold;
            background: none !important;
            text-align: right;
        }
    }
}

.template1 {
    display: flex;
    flex-direction: row;
    justify-items: center;
    height: 100%;
    width: 100%;
}

.template2 {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.template3 {
    flex-grow: 1;
    font-size: 13px;
    color: #000000;
    font-weight: normal;
    letter-spacing: -0.33px;
    line-height: 1.2;
    text-align: left;
}

.template4 {
    font-size: 12px;
    color: #a6a6a6;
    line-height: 1.5;
    letter-spacing: 0px;
    text-align: left;
}

.template5 {
    font-size: 12px;
    color: #4a4a4a;
    display: flex;
    align-items: center;
}

.template6 {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & > *:not(:last-child) {
        margin-right: 4px;
    }
}

.template7 {
    height: 18px;
    border-radius: 10px;
    padding: 0px 6px;
    font-size: 11px;
    line-height: 18px;
    color: white;
    text-align: center;
    min-width: 5em;
}

.template8 {
    min-width: 5em;
}

.imageLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 12px;
}

.imageRight {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.paginationCheck {
    & > div {
        & > div:first-child {
            display: none;
        }
        & > div:last-child {
            left: 40px;
            position: absolute;
            top: 6px;
            font-size: 12px;
        }
    }

    &.noHeader > div {
        & > div:last-child {
            display: none;
        }
    }
}

.pagination {
    & > div {
        & > div:first-child {
            display: none;
        }
        & > div:last-child {
            left: 12px;
            position: absolute;
            top: 6px;
            font-size: 12px;
        }
    }

    &.noHeader > div {
        & > div:last-child {
            display: none;
        }
    }
}

.addRow {
    border-top: 1px solid #e6e6e6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & > img {
        user-select: none;
        width: 17px;
        height: 17px;
        padding-right: 5px;
    }
    & > span {
        user-select: none;
        font-size: 14px;
    }
}

.headLine {
    width: 100%;
    height: 10px;
    background-color: #fafafa;
    & > div {
        position: absolute;
        height: 1px;
        background-color: #e0e0e0;
    }
}

.virtualCardList {
    width: 100%;
    flex-grow: 1;
    position: relative;
    & > div {
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        background-color: #fafafa;
    }
}

.emptyData {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fafafa;
}

.emptyImg {
    // width: 130px;
    // height: 100px;
    object-fit: contain;
}

.emptySmallImg {
    // width: 42px;
    // height: 42px;
    object-fit: contain;
}

.emptyText {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.06;
    letter-spacing: -0.8px;
    color: #8c8c8c;
}

.emptySmallText {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: -0.6px;
    text-align: center;
    color: #5d5d5d;
}

.li_CardList {
    outline: none;
    position: relative;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    & > div {
        display: flex;
        position: absolute;
        flex-direction: row;
        padding-left: 17px;
        padding-right: 17px;
        box-sizing: border-box;
    }
}

.selectedCardList {
    position: absolute;
    overflow: hidden;
    border: 1px solid #1c90fb;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    pointer-events: none;
}

.memo {
    position:absolute;
    left:0;
    top:0;
    z-index: 9999;
}