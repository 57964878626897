.obtMultiLineTextField {
    & > div:nth-child(1) {
        display: contents;
        & > div:nth-child(1) {
            display: contents;
        }
    }
    textarea::-webkit-input-placeholder {
        padding: 0px;
    }

    textarea:-ms-input-placeholder {
        padding: 0px;
    }

    textarea::-moz-placeholder {
        padding: 0px;
    }
}

.fixed {
    textarea {
        resize: none;
    }
}
