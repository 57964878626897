@import "../Common/Common";

.required {
    @include required;
}

.readonly {
    @include readonly;
}

.disabled {
    @include disabled;
}

.root {
    border: 1px solid rgb(204, 204, 204);
    padding: 3px 6px;
    min-height: 19px;
    overflow: auto;
    background-color: #ffffff;
    color: #000000;

    &:focus-within {
        border-color: #1c90fb;
    }

    & > div[class~="DraftEditor-root"] {
        width: 100%;
        height: 100%;
    }

    .block {
        & > div[class~="public-DraftStyleDefault-block"] {
            margin: 0px;
        }

        &.alignLeft,
        &.alignLeft > * {
            text-align: left;
        }

        &.alignRight,
        &.alignRight > * {
            text-align: right;
        }

        &.alignCenter,
        &.alignCenter > * {
            text-align: center;
        }

        &.alignCenter,
        &.alignCenter > * {
            text-align: center;
        }

        &[class~="rdw-center-aligned-block"] {
            text-align: center;

            & > div {
                display: inline-block;
            }
        }

        &.alignJustify,
        &.alignJustify > * {
            text-align: justify;
        }
    }

    & ul[class~="public-DraftStyleDefault-ul"] {
        margin: 0px;
        & li[class~="public-DraftStyleDefault-depth0"] {
            list-style-type: none;
            margin-left: 10px;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: -10px;
                top: calc(50% - 1.5px);
                width: 3px;
                height: 3px;
                background-color: #585858;
            }
        }
        & li[class~="public-DraftStyleDefault-depth1"] {
            list-style-type: none;
            margin-left: 10px;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: -10px;
                top: calc(50% - 0.5px);
                width: 4px;
                height: 1px;
                background-color: #6a6a6a;
            }
        }
    }
    & ol[class~="public-DraftStyleDefault-ol"] {
        margin: 0px;
        & li[class~="public-DraftStyleDefault-depth0"] {
            margin-left: 10px;
            &::before {
                left: -29px;
            }
        }
        & li[class~="public-DraftStyleDefault-depth1"] {
            margin-left: 10px;
            &::before {
                left: -29px;
            }
        }
    }

    .hr {
        height: 1px;
        width: 100%;
        background-color: #e6e6e6;
    }

    .variable {
        position: relative;
        padding: 0px 5px;
        color: #ffffff;
        z-index: inherit;
        cursor: pointer;
        outline: none;

        &::before {
            content: "";
            position: absolute;
            left: 1px;
            right: 1px;
            top: 2px;
            bottom: 0px;
            border-radius: 999px;
            background-color: #1c90fb;
            pointer-events: none;
            z-index: -1;
        }

        .variableList {
            margin-top: 3px;
            width: 170px;
            max-height: 191px;
            box-shadow: 0px 1px 1px 0 rgba(6, 6, 6, 0.1);
            border: solid 1px #a6a6a6;
            background-color: #ffffff;
            cursor: default;
            z-index: 500;
            transition: transform 200ms ease;
            transform-origin: top;
            transform: scaleY(1);

            &.hide {
                transform: scaleY(0);
            }

            .variableListItem {
                box-sizing: border-box;
                padding: 0px 11px;
                height: 27px;
                font-size: 12px;
                text-align: left;
                color: #000000;
                display: flex;
                align-items: center;
                cursor: pointer;
                outline: none;
                z-index: 0;

                .description {
                    margin-left: 5px;
                    color: #4a4a4a;
                }

                &.selected {
                    color: #1c90fb;

                    & .description {
                        color: #1c90fb;
                    }
                }

                &:hover {
                    background-color: #eff7ff;
                }
            }
        }
    }

    .link {
        font-weight: bold;
        text-decoration: underline;
        color: #1c90fb;
    }

    .image {
        max-width: 100%;
    }
}
