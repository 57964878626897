// $labelWidth: 120px;
// $contentWidth: 170px;
$labelWidth: 102px;
$contentWidth: 178px;

.item {
  display: flex;
  flex-direction: row;
  //width: calc(#{$labelWidth} + #{$contentWidth});
  margin: 4px;
}

.label {
  line-height: 27px;
  color: #4a4a4a;
  font-size: 13px;
  min-width: $labelWidth;
  text-align: right;
  margin-right: 6px;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}

.tooltipWrapper {
  position: relative;
  width: inherit;
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.spaceBetweenChildren {
    & > *:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.requiredTooltip {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparent;
  & > div:first-child {
    width: 100%;
    height: 100%;
  }
}
