.main{
    display: inline-block;
    box-sizing: content-box;

    &.disabled{
        pointer-events: none;
        user-select: none;
    }

    .dialog{
        display: flex;
        justify-content: center;
    }
}