.root {
    display: inline-block;

    &.disabled {
        & > div > div > button {
            border-color: #ccc !important;
            background: #f5f5f5 !important;
            color: #8d8d8d !important;
            cursor: not-allowed !important;
        }
    }

    &.default {
        background-image: linear-gradient(to top, #f0f0f0, #ffffff);

        &:active {
            background-image: linear-gradient(to bottom, #cccccc, #f5f5f5);
        }

        & > div > div {
            & > button:first-child {
                border: 1px solid #cccccc;
                border-right: none;
                border-radius: 2px 0 0 2px;
            }
            & > button + button {
                border: 1px solid #cccccc;
                border-left: none;
                border-radius: 0 2px 2px 0;
            }
        }

        &:hover > div > div {
            & > button:first-child {
                border: 1px solid #9f9f9f;
                border-right: none;
                border-radius: 2px 0 0 2px;
            }
            & > button + button {
                border: 1px solid #9f9f9f;
                border-left: none;
                border-radius: 0 2px 2px 0;
            }
        }
    }

    &.blue {
        background-image: linear-gradient(to top, #1c90fb, #5eb5fd);
        &:hover {
            background-image: linear-gradient(to top, #3280e0, #3ba1ff);
        }
        &:active {
            background-image: linear-gradient(to bottom, #3280e0, #3ba1ff);
        }

        & > div > div {
            & > button:first-child {
                border: 1px solid #1c90fb;
                border-right: none;
                border-radius: 2px 0 0 2px;
            }
            & > button + button {
                border: 1px solid #1c90fb;
                border-left: none;
                border-radius: 0 2px 2px 0;
            }
        }

        &:hover > div > div {
            & > button:first-child {
                border: 1px solid #136ad5;
                border-right: none;
                border-radius: 2px 0 0 2px;
            }
            & > button + button {
                border: 1px solid #136ad5;
                border-left: none;
                border-radius: 0 2px 2px 0;
            }
        }
    }

    &.skyBlue {
        background-image: linear-gradient(to top, #d0ebff, #edf6ff);
        &:active {
            background-image: linear-gradient(to bottom, #a4d6fe, #e6f5ff);
        }

        & > div > div {
            & > button:first-child {
                border: 1px solid #aedafe;
                border-right: none;
                border-radius: 2px 0 0 2px;
            }
            & > button + button {
                border: 1px solid #aedafe;
                border-left: none;
                border-radius: 0 2px 2px 0;
            }
        }

        &:hover > div > div {
            & > button:first-child {
                border: 1px solid #5eb5fd;
                border-right: none;
                border-radius: 2px 0 0 2px;
            }
            & > button + button {
                border: 1px solid #5eb5fd;
                border-left: none;
                border-radius: 0 2px 2px 0;
            }
        }
    }

    &.drawer {
        background: #50596c;
        &:active {
            background-image: #50596c;
        }

        & > div > div {
            & > button:first-child {
                border: 1px solid #b5b5b5;
                border-right: none;
                border-radius: 2px 0 0 2px;
                color: #ffffff;
            }
            & > button + button {
                border: 1px solid #b5b5b5;
                border-left: none;
                border-radius: 0 2px 2px 0;
            }
        }

        &:hover > div > div {
            & > button:first-child {
                border-color: #e6e6e6;
            }
            & > button + button {
                border-color: #e6e6e6;
            }
        }
    }

    &.drawerImportant {
        background: #1c90fb;
        &:hover {
            background: #2ba7ff;
        }
        &:active {
            background: #1c90fb;
        }

        & > div > div {
            & > button:first-child {
                border: 1px solid #1c90fb;
                border-right: none;
                border-radius: 2px 0 0 2px;
                color: #ffffff;
            }
            & > button + button {
                border: 1px solid #1c90fb;
                border-left: none;
                border-radius: 0 2px 2px 0;
            }
        }

        &:hover > div > div {
            & > button:first-child {
                border-color: #2ba7ff;
            }
            & > button + button {
                border-color: #2ba7ff;
            }
        }
    }
}
