@charset "UTF-8";
.obtCalendar-erp {
  position: relative;
  /* 토요일 */
  /* 일요일 */
}
.obtCalendar-erp .erpToolbar {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 111px);
  height: 60px;
  left: 111px;
}
.obtCalendar-erp .erpToolbar .previous-month-button {
  border-right: 0px !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  position: relative;
}
.obtCalendar-erp .erpToolbar .previous-month-button:hover::after {
  content: "";
  position: absolute;
  right: -1px;
  top: -1px;
  bottom: -1px;
  width: 1px;
  background-color: #9f9f9f;
}
.obtCalendar-erp .erpToolbar .next-month-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.obtCalendar-erp .erpToolbar .btnThisMonth {
  margin-left: 4px;
}
.obtCalendar-erp .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0px;
}
.obtCalendar-erp .fc-toolbar.fc-header-toolbar .fc-left {
  display: flex;
  align-items: center;
  height: 60px;
}
.obtCalendar-erp .fc-toolbar.fc-header-toolbar .fc-left > h2 {
  font-size: 18px;
}
.obtCalendar-erp .fc-day-header {
  height: 40px;
  border-radius: 0px;
  background-color: #f5f8fa;
  font-size: 12px;
  border: 0px;
}
.obtCalendar-erp .fc-day-header > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.obtCalendar-erp .fc-row.fc-widget-header {
  margin-bottom: 8px;
}
.obtCalendar-erp .fc-day-top {
  font-size: 13px;
  padding: 3px 0px 0px 6px;
}
.obtCalendar-erp .fc-day-number {
  float: left !important;
}
.obtCalendar-erp .fc-view.fc-dayGridMonth-view.fc-dayGrid-view thead td {
  border: none;
}
.obtCalendar-erp .fc-event {
  border: none;
}
.obtCalendar-erp .fc-sat {
  color: #1c90fb;
}
.obtCalendar-erp .fc-sun {
  color: #ff0000;
}
.obtCalendar-erp .fc-event-container .fc-day-grid-event {
  border-radius: 0px;
  padding: 1px 6px;
}
.obtCalendar-erp .fc-event-container .fc-day-grid-event .fc-content .fc-title {
  font-size: 10px;
}