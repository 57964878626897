.obtTextField,
.obtTextField * {
    box-sizing: content-box;
    line-height: normal;
}

.obtTextField {
    display: inline-block;

    &.useCustomCodePickerIcon {
        position: relative;
        & > div:first-child > div > button > * {
            display: none !important;
        }
    }

    & > .customCodePickerIcon {
        pointer-events: none;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 16px;

        & > * {
            width: 100% !important;
        }
    }

    &.hideInformationText > div:first-child > p {
        display: none;
    }

    & input[type="password"] {
        &::-ms-clear,
        &::-ms-reveal {
            display: none;
        }
        &::-webkit-contacts-auto-fill-button,
        &::-webkit-credentials-auto-fill-button {
            visibility: hidden;
            display: none !important;
            pointer-events: none;
            height: 0;
            width: 0;
            margin: 0;
        }
    }
}
