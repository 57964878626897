.calendar {
    transition: opacity 0.3s ease-out;
    opacity: 1;
    
    &:focus {
        outline: none;
    }

    &:not(.visible) {
        opacity: 0;
    }
}

.actionBar {
    clear: both;
    padding: 10px;
    border: 1px solid rgb(163, 163, 163);
    border-top-color: rgb(230, 230, 230);
    white-space: nowrap;
    outline: none;
    background-color: #ffffff;
}
