.calendar {
    outline: none;
    transition: all 0.3s ease-out;
    opacity: 1;

    &:not(.visible) {
        opacity: 0;
    }

    & > .label {
        clear: both;
        padding: 10px;
        border-top: 1px solid #e6e6e6;
        white-space: nowrap;
        outline: none;
    }
}
