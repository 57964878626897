.root {
    .title{
        font-weight: bolder;
        font-size: 22px;
        border-bottom: 1px solid rgb(120, 160, 250);
        padding-bottom: 3px;
    }

    .contents {
        display: flex;
        padding: 30px 10px;

        .anchor {
            flex-shrink: 0;
            width: 200px;
            height: 100%;
            top: 0;
            position: sticky;
            transition: 0.2s;
            // background-color: rgb(240,242,249);

            .listBox {
                // border-radius: 5px;
                // box-shadow: 0px 0px 7px rgba(59, 59, 59, 0.15);
                height: 700px;

                & > .listText {
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 20px;
                    font-weight: bolder;
                    padding: 15px
                }

                & > .listItems {
                    padding: 10px;

                    & > p {
                        font-size: 13px;
                        color: rgb(63, 63, 63);
                        padding: 5px;
                        font-weight: bolder;
                        transition: 0.2s;

                        &:hover {
                            cursor: pointer;
                        }

                        &.selected {
                            color: royalblue;
                        }
                    }

                    & > .withIcon {
                        & > p {
                            font-size: 13px;
                            color: rgb(63, 63, 63);
                            padding: 5px;
                            font-weight: bolder;
                            transition: 0.14s;
                            display: inline-block;

                            &.selected {
                                color: royalblue;
                            }
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .arrowIcon {
                        background: url("./image/ic_arrow_down_01_m_disable.png");
                        display: inline-block;
                        vertical-align: middle; 
                        width: 18px; 
                        height: 18px; 
                        text-align: center; 
                        margin-left: 4px;

                        &:hover {
                            transition: 0.14s;
                            background: url("./image/ic_arrow_down_01_m_normal.png");
                            cursor: pointer;
                        }

                        &.opened {
                            transition: 0.14s;
                            background: url("./image/ic_arrow_up_01_m_normal.png");
                        }
                    }

                    .detailList {
                        & > span{
                            font-size: 11px;
                            color: rgb(127,127,127);
                            position: relative;

                            &::before {
                                content: "";
                                height: 1px;
                                width: 0;
                                background-color: rgb(180, 197, 248);
                                transition: 0.3s;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                            }

                            &:hover::before {
                                width: 100%;
                                background-color: rgb(180, 197, 248);
                            }

                            &.selected {
                                font-weight: bold;
                                // border-bottom: 1px solid rgb(180, 197, 248);
                            }
                        }
                        &.closed {
                            display: none;
                        }
                    }
                } 
            }
        }

        .main {
            flex-grow: 1;
            height: 100%;

            .attributeTitle {
                padding-left: 15px;
                padding-bottom: 5px;
                font-size: 15px;
                font-weight: bold;
            }

            .tableContents {
                margin: 0px 10px;
                width: calc(100% - 20px);
                margin-bottom: 10px;
                background-color: #ffffff;
                border: 1px solid #e6e6e6;
                word-break: break-all;
                table-layout: fixed;

                & pre {
                    white-space: pre-wrap;
                }

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                thead {
                    tr {
                        th {
                            font-size: 14px;
                            padding: 5px 0px;
                            // background: #fafafa;
                            background: rgb(248, 248, 250);
                            color: black;
                            position: relative;
                            border: 1px solid #e6e6e6;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            font-size: 13px;
                            padding: 5px;
                            border-bottom: 1px solid #e6e6e6;

                            &:nth-child(1) {
                                color: royalblue;
                                font-weight: bold;
                            }

                            &:nth-child(2) {
                                // color: #fb709f;
                            }

                            &:not(:last-child) {
                                border-right: 1px solid #e6e6e6;
                            }

                            &.detailAttributeTitle {
                                background-color: rgb(248, 248, 250);
                                font-size: '25px';
                                color: black;
                            }
                            
                            // & > .detailIcon {
                            //     background: url("./image/ic_help_m_over.png");
                            //     display: inline-block;
                            //     vertical-align: middle; 
                            //     width: 18px; 
                            //     height: 18px; 
                            //     text-align: center; 
                            // }
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}