.root {
  position: relative;

  & > .primaryRoot {
    padding: 4px 4px 4px 4px;
    border: 2px solid #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    & > .primary {
      flex-grow: 1;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // max-width: 900px;
    }

    & > .primaryFunctions {
      margin: 4px;
      // margin-left: auto;
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      & > * {
        flex-shrink: 0;
      }

      & .collapseButton {
        padding: 0px;
      }

      & .searchButton {
        padding: 0px;
      }

      & > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  &.disabled {
    cursor: not-allowed !important;

    & * {
      pointer-events: none;
    }
  }

  &.collapsed .optionalWrapper .optionalRoot {
    margin-top: var(--height, -100%);
  }
}

.optionalWrapper {
  overflow: hidden;
  margin-top: -1px;

  .optionalRoot {
    margin-top: 0px;
    box-sizing: border-box;
    border: 2px solid #e6e6e6;
    border-top: none;
    border-bottom: 1px solid #e6e6e6;
    background-color: #ffffff;
    transition: margin-top 0.25s ease;

    & > .optional {
      padding: 4px 39px 4px 4px;
      border: none;
      margin-top: 0px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // max-width: 920px;
    }

    & > .optionalFunctions {
      width: 100%;
      padding: 5px 0px;
      background-color: #f8f8f8;
      text-align: center;
      border-bottom: 1px solid #e6e6e6;
    }
  }

  &.disabled {
    cursor: not-allowed !important;

    & * {
      pointer-events: none;
    }
  }

  &.collapsed .optionalRoot {
    margin-top: var(--height, -100%);
  }
}
