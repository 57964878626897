.root {
    margin-bottom: 14px;

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        height: 29px;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #3f5675;
        background-color: #edf4fb;
        border-bottom: 1px solid #d5dce5;

        .stateLabel {
            margin-top: 1px;
            height: 18px;
            border-radius: 10px;
            padding: 0px 6px;
            font-size: 11px;
            line-height: 18px;
            color: white;
            text-align: center;
            min-width: 4em;
            pointer-events: none;
        }
    }

    .content {
        padding: 14px 10px 0px 10px;
    }
}
