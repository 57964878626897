.root{
    width: 186px;
    display: inline-flex;
    flex-direction: column;
    border:1px solid #cccccc;
    box-sizing: content-box;
    &>.title:first-child{
        border-top: none;
        border-bottom: 1px solid #cccccc;
    }
    &>.title{
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        background-color: #EEEEEE;
        color: #777777;
        font-size: 14px;
        padding: 3px 5px;
    }
    &>.headerColor{
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 6px 5px 8px 5px;
        &>.smallColor{
            height: 12px; 
            width: 12px; 
            cursor: pointer; 
            position: relative; 
            float: left; 
            margin-right: 5px;
            &:focus{
                box-shadow: rgb(25, 77, 51) 0px 0px 4px;
            }
        }
        &>.smallColor:last-child{
            margin-right: 0;
        }
    }
    &>.bottomColor{
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding-left:5px;
        padding-bottom: 6px;
        &>.smallColor{
            height: 12px; 
            width: 12px; 
            cursor: pointer; 
            position: relative; 
            float: left; 
            margin-right: 5px;
            margin-bottom: 2px;
            &:focus{
                box-shadow: rgb(25, 77, 51) 0px 0px 4px;
            }
        }
    }
    &>.standardColor{
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding-left:5px;
        padding-top: 6px;
        &>.smallColor{
            height: 12px; 
            width: 12px; 
            cursor: pointer; 
            position: relative; 
            float: left; 
            margin-right: 5px;
            margin-bottom: 6px;
            &:focus{
                box-shadow: rgb(25, 77, 51) 0px 0px 4px;
            }
        }
    }
    &>.recentColor{
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding-left:5px;
        padding-top: 6px;
        &>.smallColor:first-child{
            margin-right: 11px;
        }
        &>.smallColor{
            height: 12px; 
            width: 12px; 
            cursor: pointer; 
            position: relative; 
            float: left; 
            margin-right: 5px;
            margin-bottom: 5px;
            &:focus{
                box-shadow: rgb(25, 77, 51) 0px 0px 4px;
            }
        }

        &>.line{
           width: 6px;
           height: 12px;
           border-left: 1px solid;
           margin-right: 5px;
        }
    }

    &>.another{
        text-align: center;
        font-size: 13px;
        padding-bottom: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover{
            color:#0070C0;
            text-decoration: underline;            
        }
        &>.text{
            margin-left: 5px;
        }
    }
}