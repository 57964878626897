.default {
  display: inline-block;
  border: 1px solid #878787;
}

.head {
  height: 52px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  background-color: #f2f2f2;
  border-bottom: 1px solid #878787;
}

.headLeft {
  // width: calc(100% - 535px);
  padding-left: 20px;
  display: flex;
  justify-items: center;
  align-items: center;
  flex: 1 1 auto;
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    white-space: nowrap;
  }
}

.headRight {
  flex: 0 0 auto;
  // width: 500px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  align-items: center;
}

.add {
  width: 27px;
  height: 27px;
  margin-right: 30px;
  padding: 0px !important;
  background-image: none !important;
}

.delete {
  width: 27px;
  height: 27px;
  margin-right: 2px;
  padding: 0px !important;
  background-image: none !important;
}

.up {
  width: 27px;
  height: 27px;
  margin-right: 2px;
  padding: 0px !important;
  background-image: none !important;
}

.down {
  width: 27px;
  height: 27px;
  margin-right: 28px;
  padding: 0px !important;
  background-image: none !important;
}

.print {
  height: 24px;
  width: 40px;
  border: 0px;
  padding: 0px !important;
  background-image: none !important;
  div {
    img {
      width: 24px;
      height: 23px;
    }
  }
}

.pdfSearch {
  width: 24px;
  height: 24px;
  border: 0px;
  margin-left: 26px;
  padding: 0px !important;
  background-image: none !important;
  div {
    img {
      width: 24px;
      height: 23px;
    }
  }
}

.LS_splitbtn {
  top: 237.688px;
}

.splitHtml {
  background-image: none !important;
  div {
    div {
      div {
        button {
          border: 0px !important;
          height: 23px !important;
          div {
            span {
              img {
                width: 24px;
                height: 23px;
              }
            }
          }
        }
      }
    }
  }
}

.dropDownPercent {
  margin-right: 20px;
}

.bar {
  height: 20px;
  margin-right: 20px;
  border-right: 1px solid #c9c9c9;
}

.body {
  display: flex;
  background-color: #999999;
  overflow: hidden;
}

.pdfBody {
  display: flex;
  background-color: #f2f2f2;
}

.view {
  display: flex;
  flex-direction: column;
}

.previewButton {
  width: 5px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #999999;
  cursor: e-resize;
  & > button {
    border: 1px solid black;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #f7f7f7;
    height: 90px;
  }
}

.renderer {
  width: 100%;
  height: calc(100% - 50px);
}

.pdfDocument {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f7f7f7;
}

.contentsScroll {
  flex-grow: 1;
  overflow: hidden;
  background-color: #999999;
  width: 100%;
  height: 100%;
}

.previewScroll {
  flex-grow: 1;
  overflow: hidden;
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  padding: 10px;
  & > *:not(:first-child) {
    margin-top: 10px;
  }
}

.contents {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mark {
  opacity: 0.5;
  background: #ff0;
  box-shadow: 0px 2px 10px #ff0;
  color: transparent;
  white-space: pre;
}

.dockpanel {
  overflow: hidden;
}

.floatingPanel {
  display: flex;
  flex-direction: column;
  background-color: #575757;
  width: 220px;
  height: 103px;
  & > div:first-child {
    color: white;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
  }
}

.searchPreButton {
  width: 40px;
  height: 21px;
  & > div {
    & > span {
      font-size: 8px !important;
      color: #000000;
    }
  }
}

.searchNextButton {
  width: 40px;
  height: 21px;
  & > div {
    & > span {
      font-size: 8px !important;
      color: #000000;
    }
  }
}

.searchTextField {
  width: 200px;
  height: 25px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
}

.searchClose {
  background-color: #575757 !important;
  background-image: none !important;
  border: 0px;
  width: 11px;
  height: 11px;
  padding: 0px !important;
  & > div {
    img {
      width: 11px;
      height: 11px;
      background-color: #575757 !important;
    }
  }
}
