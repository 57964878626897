$background-color: #ffffff;
$border-color: #a6a6a6;
$color: #000000;

@mixin Theme($theme, $theme-background-color, $theme-border-color, $theme-color) {
  &.#{$theme} {
    & > .contents {
      background-color: $theme-background-color;
      border-color: $theme-border-color;
      color: $theme-color;
    }
    &.top {
      & .arrow {
        border-top-color: $theme-border-color;
      }
      & .arrowBackground {
        border-top-color: $theme-background-color;
      }
    }
    &.bottom {
      & .arrow {
        border-bottom-color: $theme-border-color;
      }
      & .arrowBackground {
        border-bottom-color: $theme-background-color;
      }
    }
    &.left {
      & .arrow {
        border-left-color: $theme-border-color;
      }
      & .arrowBackground {
        border-left-color: $theme-background-color;
      }
    }
    &.right {
      & .arrow {
        border-right-color: $theme-border-color;
      }
      & .arrowBackground {
        border-right-color: $theme-background-color;
      }
    }
  }
}

.root {
  display: inline-block;
  line-height: 0px;
}

.tooltipFloatingPanel {
  pointer-events: none;
}

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.12s ease-out;
  transform: scale(1);

  &:not(.visible) {
    transform: scale(0);

    // &:not(.transition) {
    //   display: none;
    // }
  }

  &:not(.visible):not(.transition) {
    display: none;
  }

  & > .contents {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px, rgba(0, 0, 0, 0.12) 0px 1px 2px;
    font-size: 12px;
    line-height: 12px;
    padding: 7px 12px;
    border-radius: 3px;
    background-color: $background-color;
    border: 1px solid $border-color;
    color: $color;
    &.maxContent {
      width: max-content;
      max-width: 20em;
      white-space: normal;
      word-break: break-all;
    }
  }
  & > .arrowWrapper {
    position: relative;
    box-sizing: border-box;
    width: 10px;
    height: 10px;

    & > .arrow {
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      border: 5px solid transparent
    }

    & > .arrowBackground {
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      border: 5px solid transparent;
    }
  }

  &.top {
    flex-direction: column;
    & .arrowWrapper {
      height: 6px;
      margin: 0px 5px;
    }
    & .arrow {
      border-top-color: $border-color;
    }
    & .arrowBackground {
      margin-top: -1px;
      border-top-color: $background-color;
    }
  }
  &.bottom {
    flex-direction: column-reverse;
    & .arrowWrapper {
      height: 6px;
      margin: 0px 5px;
    }
    & .arrow {
      margin-top: -4px;
      border-bottom-color: $border-color;
    }
    & .arrowBackground {
      margin-top: -3px;
      border-bottom-color: $background-color;
    }
  }
  &.left {
    flex-direction: row;
    & .arrowWrapper {
      width: 6px;
      margin: 5px 0px;
    }
    & .arrow {
      border-left-color: $border-color;
    }
    & .arrowBackground {
      margin-left: -1px;
      border-left-color: $background-color;
    }
  }
  &.right {
    flex-direction: row-reverse;
    & .arrowWrapper {
      width: 6px;
      margin: 5px 0px;
    }
    & .arrow {
      margin-left: -4px;
      border-right-color: $border-color;
    }
    & .arrowBackground {
      margin-left: -3px;
      border-right-color: $background-color;
    }
  }

  &.near {
    align-items: flex-start;
  }
  &.far {
    align-items: flex-end;
  }
  @include Theme("blue", #1c90fb, #156cbc, #ffffff);
  @include Theme("black", #000000, #000000, #ffffff);
  @include Theme("red", #fc5356, #bd3e40, #ffffff);
  @include Theme("orange", #febc2c, #be8d21, #ffffff);
  @include Theme("green", #1fc47c, #17935d, #ffffff);
  @include Theme("required", #ffdede, #ffdede, #010101);
}
