@charset "UTF-8";

.OBTSeleneSplitButton {
    .sp_selene.icon_datefield {
        width: 16px;
        height: 14px;
        background-position: -175px 0;
    }
    .sp_selene.icon_codehelp {
        width: 14px;
        height: 14px;
        background-position: -175px -275px;
    }

    body,
    button,
    dd,
    dl,
    dt,
    fieldset,
    form,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img,
    input,
    legend,
    li,
    ol,
    p,
    select,
    table,
    td,
    textarea,
    th,
    ul {
        margin: 0;
        padding: 0;
    }

    .sp_selene {
        display: inline-block;
        overflow: hidden;
        background: url("../Images/sp_lux.png") 0 0 no-repeat;
        line-height: 100em;
        vertical-align: top;
    }
    body.fixscroll {
        position: fixed;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
    .LS_btn {
        display: inline-block;
        width: auto;
        position: relative;
        border: 0 none;
        background: 0 0;
        font-weight: 200;
        vertical-align: top;
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 0;
    }
    .LS_splitbtn {
        position: relative;
        display: inline-block;
        vertical-align: top;
        letter-spacing: 0;
    }
    .LS_splitbtn .btngroup {
        clear: both;
        overflow: hidden;
        align-items: center;
    }
    .LS_splitbtn .LS_btn {
        color: #000;
        float: left;
        height: 32px;
        padding: 0 5px 0 12px;
        font-size: 14px;
        font-weight: normal;
        outline: none;
    }

    .LS_splitbtn .LS_btn .sp_selene {
        display: flex;
        overflow: hidden;
        background: url(./arrow.png) 0 0 no-repeat;
        position: absolute;
        width: 8px;
        height: 4px;
    }

    .LS_splitbtn .LS_btn + .LS_btn {
        display: inline-flex;
        align-items: center;
        color: #000;
        float: left;
        width: 24px;
        height: 32px;
        padding: 0 12px 0 0px;
        font-size: 14px;
        font-weight: normal;
        outline: none;
    }

    .LS_splitbtn.vblue .LS_btn {
        color: #fff;
    }
    .LS_splitbtn.vblue:not(.disabled) .LS_btn .sp_selene {
        display: flex;
        overflow: hidden;
        background: url(./blueArrow.png) 0 0 no-repeat;
        // position: absolute;
        width: 8px;
        height: 4px;
    }

    .LS_splitbtn.vsaoheader {
        background: 0 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0 2px 1px;
    }
    .LS_splitbtn.vsaoheader .LS_btn {
        background: 0 0;
        color: #fff;
        border-color: rgba(255, 255, 255, 0.3);
        height: 27px !important;
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
    }
    .LS_splitbtn.vsaoheader .LS_btn .sp_selene {
        background-position: -280px -307px;
    }
    .LS_splitbtn.vsaoheader .LS_btn + .LS_btn {
        border-left: 1px solid rgba(255, 255, 255, 0.3);
    }
    .LS_splitbtn.vsaoheader .LS_btn.click,
    .LS_splitbtn.vsaoheader .LS_btn.hover,
    .LS_splitbtn.vsaoheader .LS_btn:hover {
        border-color: rgba(0, 0, 0, 0.3);
    }
    .LS_splitbtn.vsaoheader .LS_btn.click + .LS_btn,
    .LS_splitbtn.vsaoheader .LS_btn.hover + .LS_btn,
    .LS_splitbtn.vsaoheader .LS_btn:hover + .LS_btn {
        border-left-color: rgba(0, 0, 0, 0.3);
    }
    .LS_splitbtn.vsaoheader .LS_btn.click {
        background: rgba(0, 0, 0, 0.1);
    }
    .LS_splitbtn.size_m .LS_btn {
        height: 27px;
        font-size: 12px;
    }

    .LS_splitbtn.size_m .LS_btn + .LS_btn {
        color: #000;
        float: left;
        width: auto;
        padding: 0 7px 0 0px;
        font-size: 14px;
        font-weight: 400;
    }

    .LS_splitbtn.size_s .LS_btn,
    .LS_splitbtn.vsaoheader .LS_btn {
        height: 20px;
        font-size: 11px;
    }
    .LS_splitbtn.size_s .LS_btn + .LS_btn,
    .LS_splitbtn.vsaoheader .LS_btn + .LS_btn {
        color: #000;
        float: left;
        width: 24px;
        padding: 0 12px 0 0px;
        font-size: 14px;
        font-weight: 400;
    }
    .LS_splitbtn.size_m .LS_btn,
    .LS_splitbtn.size_s .LS_btn,
    .LS_splitbtn.vsaoheader .LS_btn {
        padding: 0 5px 0 10px;
    }
    .LS_splitbtn.size_m .LS_btn .sp_selene,
    .LS_splitbtn.size_s .LS_btn .sp_selene,
    .LS_splitbtn.vsaoheader .LS_btn .sp_selene {
        position: inherit;
        vertical-align: middle;
        width: 8px;
        height: 4px;
    }
    .LS_splitbtn.size_m .LS_btn.disabled .sp_selene,
    .LS_splitbtn.size_s .LS_btn.disabled .sp_selene,
    .LS_splitbtn.vsaoheader .LS_btn.disabled .sp_selene {
        background-position: -280px -320px;
    }
    .LS_splitbtn.size_m.vblue:not(.disabled) .LS_btn .sp_selene,
    .LS_splitbtn.size_m.vsaoheader:not(.disabled) .LS_btn .sp_selene,
    .LS_splitbtn.size_m[class*="vcolor"]:not(.disabled) .LS_btn .sp_selene,
    .LS_splitbtn.size_s.vblue:not(.disabled) .LS_btn .sp_selene,
    .LS_splitbtn.size_s.vsaoheader:not(.disabled) .LS_btn .sp_selene,
    .LS_splitbtn.size_s[class*="vcolor"]:not(.disabled) .LS_btn .sp_selene,
    .LS_splitbtn.vsaoheader.vblue:not(.disabled) .LS_btn .sp_selene,
    .LS_splitbtn.vsaoheader.vsaoheader:not(.disabled) .LS_btn .sp_selene,
    .LS_splitbtn.vsaoheader[class*="vcolor"]:not(.disabled) .LS_btn .sp_selene {
        display: flex;
        overflow: hidden;
        background: url(./blueArrow.png) 0 0 no-repeat;
        // position: absolute;
        width: 8px;
        height: 4px;
    }
    .LS_splitbtn.disabled .LS_btn {
        // border-color: #ccc !important;
        // background: #f5f5f5 !important;
        // color: #8d8d8d !important;
        // cursor: not-allowed;
        text-decoration: none !important;
        text-underline-position: under;
    }

    .LS_splitbtn.disabled .LS_btn .sp_selene {
        opacity: 0.5 !important;
        color: #f5f5f5;
        background: url(./arrow.png) 0 0 no-repeat;
        // position: absolute;
        width: 8px;
        height: 4px;
    }
    .LS_splitbtn.disabled .LS_btn:hover + .LS_btn {
        border-left-color: #e0e0e0;
    }
    .LS_splitbtn.disabled.size_m .sp_selene,
    .LS_splitbtn.disabled.size_s .sp_selene,
    .LS_splitbtn.disabled.vsaoheader .sp_selene {
        background-position: -280px -320px !important;
    }

    .LS_splitbtn .LS_btn.click {
        border-color: none;
        background: none;
    }

    .LS_splitbtn .LS_btn .sp_selene {
        position: relative;
        top: auto;
        left: auto;
        margin-top: 0;
        margin-left: 0;
    }
    .LS_splitbtn.vblue .LS_btn {
        background: none;
    }

    .LS_splitbtn.vblue .LS_btn + .LS_btn {
        border-left: none;
    }
    .LS_splitbtn .LS_btn {
        line-height: normal;
        font-family: inherit;
    }
    .LS_splitbtn.disabled .LS_btn .sp_selene {
        background-position: 0 !important;
    }

    .LS_splitbtn.size_m .LS_btn,
    .LS_splitbtn.size_s .LS_btn,
    .LS_splitbtn.vsaoheader .LS_btn {
        font-family: inherit !important;
    }

    .LS_splitbtn .LS_btn + .LS_btn {
        margin-left: 0px !important;
    }
}
.LS_HSearch_resultItem .resultitem_list a .menuname,
.LS_multiselect .LSmultibutton > button,
.LS_multiselect .LSmultipopover .LSmultipopover_list .LSmultipopover_btn,
.LS_multiselect .LSmultipopover .LSmultipopover_title,
.LS_ogimage .message_content .header_container a.imagelnk,
.LS_ogurl .message_content .header_container .site_url,
.LS_ogurl .message_content .header_container a.imagelnk,
.LS_poll_v2 .end_rank .rank_list > li,
.LS_poll_v2 .vote_pic_list .picwrap .textbox > strong,
.LS_splitbtn .dropdown_menu > li > a,
.LS_viewer .rnd_wrap .rnbin .details_info dd,
.LS_viewer_new .rnd_wrap .rnbin .details_info dd,
.LSselectResult .LSnghSelectResult_listitem a > div {
    overflow: hidden;
    white-space: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
}
.LS_splitbtn .dropdown_menu.open {
    display: block;
}
.LS_splitbtn .dropdown_menu {
    z-index: 100;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #a3a3a3;
    box-sizing: border-box;
    width: 100%;
    max-height: 110px;
    overflow: hidden;
    overflow-y: auto;
}
.LS_splitbtn .dropdown_menu > li > a {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 27px;
    font-size: 12px;
    color: #4a4a4a;
    text-decoration: none;
    font-weight: 500;
    background: #fff;
    padding: 0px 7px;
    box-sizing: border-box;
}
.LS_splitbtn .dropdown_menu > li > a.hover,
.LS_splitbtn .dropdown_menu > li > a:hover {
    background: #e6f5ff;
}
.LS_splitbtn .dropdown_menu > li > a.disabled {
    color: #e0e0e0;
    cursor: not-allowed;
}
.LS_splitbtn .dropdown_menu > li > a.disabled.hover,
.LS_splitbtn .dropdown_menu > li > a.disabled:hover {
    background: #fff;
}
.LS_splitbtn .dropdown_menu > li > a {
    font-family: inherit !important;
}
