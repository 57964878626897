.root {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    height: inherit;

    &.disabled {
        cursor: not-allowed;
        user-select: none;
    }

    &>.prevButton {
        display: flex;
        box-sizing: content-box;
        justify-content: center;
        align-items: center;
        left: 10px;
        // margin: 10px 4px 10px 10px;
        cursor: pointer;
        border: 1px solid #cccccc;
        border-radius: 3px;
        user-select: none;
        flex-shrink: 0;

        &:hover {
            background-image: linear-gradient(to top, #d9d9d9, #fafafa);
        }

        &:active {
            background-image: linear-gradient(to top, #f5f5f5, #cccccc);
        }

        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
            background-image: none;
        }

        &>.arrow {
            opacity: 0.4;
        }
    }

    &>.nextButton {
        display: flex;
        box-sizing: content-box;
        justify-content: center;
        align-items: center;
        right: 10px;
        // margin: 10px 10px 10px 4px;
        cursor: pointer;
        border: 1px solid #cccccc;
        border-radius: 3px;
        user-select: none;
        flex-shrink: 0;

        &:hover {
            background-image: linear-gradient(to top, #d9d9d9, #fafafa);
        }

        &:active {
            background-image: linear-gradient(to top, #f5f5f5, #cccccc);
        }

        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
            background-image: none;
        }

        &>.arrow {
            opacity: 0.4;
        }
    }

    &>.child {
        display: inline-flex;
        overflow: hidden;
        flex-wrap: wrap;
        justify-content: space-around;

        &.horizontal {
            // margin: 10px 0;
            flex-direction: row;
        }

        &.vertical {
            // margin: 0 10px;
            flex-direction: column;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &>.hide {
        display: none;
    }
}

.defaultTemplate {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: content-box;
    flex-direction: row;
    font-size: 13px;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #cccccc;
    color: #4a4a4a;

    &.selected {
        border: 1px solid #1c90fb;
        background-color: #f5faff;
        color: #000000
    }

    &.mouseOver {
        border: 1px solid #1c90fb;
        color: #000000
    }

    &>.image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background-color: #f6f7f9;
        border-radius: 25px;
        margin-left: 15px;
        margin-right: 5px;
        flex-shrink: 0;
    }

    &>.imageMouseOver {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background-color: #e0f1ff;
        border-radius: 25px;
        margin-left: 15px;
        margin-right: 5px;
        flex-shrink: 0;
    }

    &>.textArea {
        overflow: hidden;
        padding: 5px;
        margin-right: 10px;
    }
}