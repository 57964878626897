.root {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;

    & > .dimmed {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }

    & > .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        & > .alertBoxStyle {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            z-index: 1200;
            min-width: 300px;
            border: solid 1px #a6a6a6;
            background: #ffffff;
            padding: 30px 30px 30px 30px;

            & > .buttonsDiv {
                margin-top: 16px;
            }
        }
    }

    &.closed {
        display: none;
    }
}

.alertIconStyle {
    margin-bottom: 20px;
    display: inline-block;
    width: 38px;
    height: 38px;
}

.alertTitleStyle {
    min-height: 27px;
    margin-bottom: 6px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: -0.45px;
    text-align: center;
    color: #000000;
}

.alertMessageStyle {
    min-height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 6px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}
