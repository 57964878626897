$background: #fafafa;
$topBorderColor: #666666;
$borderColor: #e6e6e6;

.table {
  border: none;
  border-spacing: 0px;
  border-collapse: separate;
  table-layout: fixed;
  border-top: 2px solid $topBorderColor;
  border-bottom: 1px solid $borderColor;
  padding: 0px 0px;
  box-sizing: content-box;

  & > tbody {
    box-sizing: content-box;
    & > tr {
      padding: 0px;
      margin: 0px;
      box-sizing: content-box;
      height: 40px;

      & > th,
      & > td {
        padding: 6px 0px;
        margin: 0px;
        box-sizing: content-box;

        & > .contentsWrapper {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
      }

      & > th {
        background-color: $background;
        // padding-left: 6px;
        // padding-right: 6px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 6px;
        padding-bottom: 7px;
        color: #4a4a4a;
        font-size: 13px;

        & > .contentsWrapper {
          justify-content: flex-end;
        }
      }

      & > td {
        // padding-left: 6px;
        // padding-right: 6px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 6px;
        padding-bottom: 7px;
        font-size: 12px;
      }

      &.breakLine {
        height: 2px !important;
        & > td {
          padding: 0px !important;
          background-color: $topBorderColor;
        }
      }
    }

    & > tr:not(:last-child):not(.breakLine):not(.breakLineAfter) {
      & > th,
      & > td {
        border-bottom: 1px solid $borderColor;
      }
    }
  }
}

.label {
  width: 174px;
}

.disabled {
  cursor: not-allowed;
}
