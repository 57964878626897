.root {
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  border: 1px solid #dae1e7;
  border-radius: 3px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  & > * {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    text-align: left;

    & > * {
      flex-shrink: 0;
      flex-grow: 0;
    }

    & > span {
      width: 100px;
    }

    .filter {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .clearButton {
    }
  }

  .filters {
    text-align: left;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    text-align: left;

    & > span {
      width: 100px;
      font-size: 16px;
      font-weight: bold;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .toggles {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-grow: 1;
      flex-shrink: 1;
      .filter {
        padding: 2px 5px;
        margin: 2px;
        cursor: pointer;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid transparent;

        &:not(.on):hover {
          border: 1px solid #8BA9FF;
        }

        &.on {
          border: 1px solid #8BA9FF;
          background-color: #8BA9FF;
          color: #ffffff;
        }
      }
    }
  }

  .console {
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & > * {
      flex-shrink: 0;
      flex-grow: 0;
    }

    .toolbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;

      & > * {
        background-position: 10px center;
        background-repeat: no-repeat;
        cursor: pointer;
        padding: 2px 10px 2px 30px;
        border: 1px solid #dae1e7;
        border-radius: 3px;

        &:not(:last-child) {
          margin-right: 10px;
        }

        &:hover {
          background-color: #d4ebd0;
        }
      }

      .clear {
        background-image: url("./console-clear.png");
      }
      .clearFilters {
        background-image: url("./console-filter-clear.png");
      }
      .allFilters {
        background-image: url("./console-filter-all.png");
      }
    }

    .logs {
      flex-shrink: 1;
      flex-grow: 1;
      font-size: 12px;
      background-color: #ffffff;
      border: 1px solid #dae1e7;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;
      height: 200px;

      .log {
        width: 100%;
        word-break: break-all;

        &:nth-child(2n) {
          background-color: #f7f7f7;
        }

        &:hover {
          background-color: #8BA9FF;
        }
      }

      .error {
        color: #ff0000;
      }
    }
  }
}
