@charset "UTF-8";
/**************************************************
   reset CSS
**************************************************/
html,
body {
  width: 100%;
  height: 100%;
  font-size: 12px;
  -webkit-text-size-adjust: none;
  overflow: hidden;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
fieldset,
legend,
input,
textarea,
img,
button,
select {
  margin: 0;
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
button {
  font-family: "NSKR", "돋움", Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif;
}

img,
fieldset {
  border: 0;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: #1a1a1a;
}

em,
address {
  font-style: normal;
}

ul,
ol,
li {
  list-style: none;
}

label,
button {
  cursor: pointer;
}

input::-ms-clear {
  display: none;
}

input:disabled {
  -webkit-text-fill-color: #8d8d8d;
  opacity: 1;
}

iframe {
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0;
}

* {
  -webkit-overflow-scrolling: touch;
}

/* 모바일에서 터치 부드러운 효과 적용 */
/* Noto Sans KR (korean) http://www.google.com/fonts/earlyaccess */
@font-face {
  font-family: "NSKT";
  font-style: normal;
  font-weight: 100;
  src: url("./playground/www/font/NotoSansKr/NotoSansKR-Thin.woff2") format("woff2"), url("./playground/www/font/NotoSansKr/NotoSansKR-Thin.woff") format("woff"), url("./playground/www/font/NotoSansKr/NotoSansKR-Thin.otf") format("opentype");
}
@font-face {
  font-family: "NSKL";
  font-style: normal;
  font-weight: 300;
  src: url("./playground/www/font/NotoSansKr/NotoSansKR-Light.woff2") format("woff2"), url("./playground/www/font/NotoSansKr/NotoSansKR-Light.woff") format("woff"), url("./playground/www/font/NotoSansKr/NotoSansKR-Light.otf") format("opentype");
}
@font-face {
  font-family: "NSKR";
  font-style: normal;
  font-weight: 400;
  src: url("./playground/www/font/NotoSansKr/NotoSansKR-Regular.woff2") format("woff2"), url("./playground/www/font/NotoSansKr/NotoSansKR-Regular.woff") format("woff"), url("./playground/www/font/NotoSansKr/NotoSansKR-Regular.otf") format("opentype");
}
@font-face {
  font-family: "NSKM";
  font-style: normal;
  font-weight: 500;
  src: url("./playground/www/font/NotoSansKr/NotoSansKR-Medium.woff2") format("woff2"), url("./playground/www/font/NotoSansKr/NotoSansKR-Medium.woff") format("woff"), url("./playground/www/font/NotoSansKr/NotoSansKR-Medium.otf") format("opentype");
}
@font-face {
  font-family: "NSKB";
  font-style: normal;
  font-weight: 700;
  src: url("./playground/www/font/NotoSansKr/NotoSansKR-Bold.woff2") format("woff2"), url("./playground/www/font/NotoSansKr/NotoSansKR-Bold.woff") format("woff"), url("./playground/www/font/NotoSansKr/NotoSansKR-Bold.otf") format("opentype");
}
@font-face {
  font-family: "NSKBK";
  font-style: normal;
  font-weight: 900;
  src: url("./playground/www/font/NotoSansKr/NotoSansKR-Black.woff2") format("woff2"), url("./playground/www/font/NotoSansKr/NotoSansKR-Black.woff") format("woff"), url("./playground/www/font/NotoSansKr/NotoSansKR-Black.otf") format("opentype");
}
.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.root .title {
  background-color: #8ba9ff;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding: 0px 10px;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
  position: relative;
}
.root .title .orbit1 {
  position: absolute;
  width: 800px;
  height: 800px;
  border-radius: 50%;
  border: 100px solid rgba(255, 255, 255, 0.5);
  left: -400px;
  top: -375px;
  box-sizing: border-box;
  pointer-events: none;
}
.root .title .titles {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.root .title .titles .mainTitle {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -3px;
  color: #ffffff;
  text-decoration: none;
}
.root .title .titles .subTitle {
  font-size: 15px;
  margin-left: 10px;
  color: #d4f8e8;
}
.root .title .navigation {
  justify-self: flex-end;
}
.root .title .navigation .navigationItem {
  text-decoration: none;
  font-size: 20px;
  color: #d4f8e8;
  cursor: pointer;
  position: relative;
}
.root .title .navigation .navigationItem::after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 3px;
  background-color: #d4f8e8;
  height: 2px;
  transform: scaleX(0);
  transition: transform 0.2s ease;
}
.root .title .navigation .navigationItem:hover::after {
  transform: scaleX(1);
}
.root .title .navigation .navigationItem.selected::after {
  transform: scaleX(1);
}
.root .title .navigation .separator {
  padding: 0px 10px;
  color: #d4f8e8;
}
.root .container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.root .container .nav {
  padding: 10px;
  overflow-y: auto;
  border-right: 2px solid lightgray;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 10px;
  height: 100%;
  min-width: 280px;
}
.root .container .contentsWrap {
  flex-grow: 1;
  position: relative;
  height: 100%;
}
.root .container .contentsWrap .contents {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  overflow: auto;
  padding: 10px;
}

.componentName {
  transition: 0.25s;
}
.componentName:hover {
  background-color: #ebedf4;
}

.loading {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}