.buttonGroup {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > * {
    & > .button.focus {
      border-color: #1c90fb !important;
      background-image: linear-gradient(to top, #d0ebff, #edf6ff) !important;
      z-index: 1;

      &:active {
        background-image: linear-gradient(to bottom, #a4d6fe, #e6f5ff) !important;
      }
    }
    &:not(:last-child) > .button {
      border-right: 0px !important;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      position: relative;

      &:hover::after,
      &.focus::after {
        content: "";
        position: absolute;
        top: -1px;
        bottom: -1px;
        right: -1px;
        width: 1px;
        z-index: 1;
        background: #9f9f9f;
      }

      &.focus::after {
        background: #1c90fb;
      }
    }
    &:not(:first-child) > .button {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

// .buttonGroup {
//   font-size: 12px;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   &::after {
//     content: "";
//     clear: both;
//     display: table;
//   }

//   & > div > .first {
//     position: relative;
//     z-index: 1;
//     border-radius: 2px 0px 0px 2px;
//     &.focus {
//       z-index: 2;
//       border: solid 1px #1c90fb !important;
//       background-image: linear-gradient(to top, #d0ebff, #edf6ff) !important;
//       &:hover {
//         border: solid 1px #1c90fb !important;
//       }
//       &:active {
//         background-image: linear-gradient(to bottom, #a4d6fe, #e6f5ff) !important;
//       }
//     }
//   }

//   & > div > .normal {
//     position: relative;
//     margin-left: -1px;
//     border-radius: 0px;
//     z-index: 1;
//     &.focus {
//       z-index: 2;
//       border: solid 1px #1c90fb !important;
//       background-image: linear-gradient(to top, #d0ebff, #edf6ff) !important;
//       &:hover {
//         border: solid 1px #1c90fb !important;
//       }
//       &:active {
//         background-image: linear-gradient(to bottom, #a4d6fe, #e6f5ff) !important;
//       }
//     }
//   }

//   & > div > .last {
//     position: relative;
//     margin-left: -1px;
//     border-radius: 0px 2px 2px 0px;
//     z-index: 1;
//     &.focus {
//       z-index: 2;
//       border: solid 1px #1c90fb !important;
//       background-image: linear-gradient(to top, #d0ebff, #edf6ff) !important;
//       &:hover {
//         border: solid 1px #1c90fb !important;
//       }
//       &:active {
//         background-image: linear-gradient(to bottom, #a4d6fe, #e6f5ff) !important;
//       }
//     }
//   }
// }
