.display {
  display: inline-block;
  position: relative;
  div > div > div > div > ul > div > li > button > span {
    top: 6px !important;
    bottom: 6px !important;
    font-family: inherit;
  }
  &.hideTabs {
    div > div > div:first-child {
    border-bottom: none !important;
    
      div:first-child {
        display: none !important;
      }
    }
  }
}

.btnLeft {
  margin-right: 2px;
}

.buttonGroup {
  position: absolute;
  top: 4px;
  right: 0px;
  & > div {
    & > button {
      width: 23px;
      height: 23px !important;
    }
  }

  &.hideTabs {
    display: none;
  }
}

.template {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: initial;
  overflow: auto;

  &:not(.templateSelected) {
    display: none;
    overflow: hidden;
  }
}
