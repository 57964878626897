.labelTextSpan {
    font-size: 14px;
    // font-family: NotoSansCJKkr;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
}

 .root {
    &.full {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
    }

    &.notFull {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
    }

    .wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .windowBackground {
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0,0,0,0.3);
    }

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-Index: 1200;

        &.boxBackground {
            border: 1px solid #d9d9d9;
            background: #ffffff;
        }

        &.small {
            min-width: 115px;
            min-height: 60px;

            & > div:nth-child(1) {
                position: relative !important;
                width: 100%;
                height: 100%;
                overflow: visible !important;

                & > div:nth-child(1) {
                    opacity: 0 !important;
                }
                & > div:nth-child(2) > div {
                    text-align: inherit !important;
                    vertical-align: baseline !important;

                    & > div { // 원 + 텍스트
                        display: block !important;
                        margin: 20px 47px 20px 47px;

                        & > div:nth-child(1) { // 원
                            float: left;
                            & > div:nth-child(2) > div > span { // 원안의 퍼센테이지
                                display: none !important;
                            }
                        }

                        & > div:nth-child(2) { // 텍스트
                            float: left;
                            position: relative !important;
                            margin: 1px 0px 0px 5px !important;
                            left: 0% !important;
                            right: 0% !important;
                        }
                    }
                }
            }
        }

        &.default {
            min-width: 290px;
            min-height: 160px;

            & > div:nth-child(1) {
                position: relative !important;
                // width: 100%;
                // height: 100%;

                & > div:nth-child(1) {
                    opacity: 0 !important;
                }

                & > div:nth-child(2) > div {
                    // vertical-align: baseline !important;

                    & > div { // 원 + 텍스트
                        // margin-top: 34px;

                        & > div:nth-child(1) > div:nth-child(2) > div > span > strong { // 원안의 퍼센테이지
                            // font-family: NotoSansCJKkr;
                            font-size: 16px !important;
                            font-weight: bold !important;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.13;
                            letter-spacing: -0.4px;
                        }

                        & > div:nth-child(2) { // 텍스트
                            margin: 13px 0px 0px 0px !important;
                            position: relative !important;
                            left: 0% !important;
                            right: 0% !important;
                        }
                    }
                }
            }

            & > div:nth-child(2) {
                z-index: 9999;
                display: inline-block;

                & > button { 
                    margin-left: 0.1px !important; //잘리는 현상 제거
                    margin-top: 10px;
                }
            }
        }

        &.large {
            & > div:nth-child(1) {
                position: relative !important;
                width: 100%;
                height: 100%;
                overflow: hidden !important;
                & > div:nth-child(1) {
                    opacity: 0 !important;
                }
                & > div:nth-child(2) > div > div > div > div:nth-child(2) > div > span > strong {
                    // font-family: NotoSansCJKkr;
                    font-size: 36px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.99;
                    letter-spacing: -0.9px;
                    text-align: center;
                }
            }
        }
    }

    &.closed {
        display: none;
    }
}

.section {
    position: relative;
    display: inline-block;
}
