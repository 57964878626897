.chip {
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
}
.default {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 27px;
    border-radius: 2px;
    box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.18);
    border: solid 1px #1c90fb;
    // background-color: #ffffff;
  }
  .imageStyle {
    margin-left: 7px;
    width: 16px;
    height: 16px;
    align-items: center;
  }
  .labelStyle {
    margin-left: 7px;
    margin-right: 14px;
    //font-family: NotoSansCJKkr;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
  .buttonStyle {
    object-fit: contain;
  }
  .chipsDefault {
    display: inline-block;
    overflow: auto;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
  }
  .chipsHead {
    display: flex;
    flex-direction: row;
    margin-left: 9px;
    margin-top: 9px;
    margin-bottom: 9px;
    flex-wrap: wrap;
    align-content: flex-start
  }