@import "../Common/Common";

.root {
    display: inline-block;

    .wrapper {
        display: inline-flex;
        flex-direction: row;
        box-sizing: border-box;
        align-items: center;
        position: relative;
        width: 100%;
        outline: none;
        line-height: normal;

        &:not(.customInput) {
            height: 27px;
            padding: 3.5px 7px;
            border: 1px solid #cccccc;
            background: #ffffff;

            &.readonly:not(.stateless) {
                background-color: #f2f2f2;
            }
            &.disabled:not(.stateless) {
                background-color: #f2f2f2;
                color: #a6a6a6;
            }
            &.required:not(.stateless) {
                @include required;
            }

            // &:focus-within,
            &.focus {
                border-color: #1c90fb;
            }
        }

        .dateInput {
            font-size: 12px;
            outline: none;
            border: none;
            font-family: inherit;
            padding: 6px 0px 5px 0px;
            background: transparent;

            &:disabled {
                -webkit-text-fill-color: #a6a6a6;
                color: #a6a6a6;
            }
        }

        .weekday {
            margin-left: 3px;

            &.sunday {
            }

            &.saturday {
            }
        }

        .fromToSeparator {
            margin: 0px 3px;
        }

        .icon {
            position: absolute;
            right: 7px;
            top: calc(50% - 7px);
            cursor: pointer;
        }

        &.disabled .icon {
            opacity: 0.2;
            cursor: none;
            pointer-events: none;
        }

        &.stateless .dateInput:disabled {
            -webkit-text-fill-color: #000000;
            color: #000000;
        }

        &.stateless.disabled .icon {
            opacity: 1;
        }
    }
}

.inputYMD {
    width: 62px;
}
.inputYM {
    width: 45px;
}
.inputY {
    width: 28px;
}
