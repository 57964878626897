@charset "UTF-8";
/**************************************************
   reset CSS
**************************************************/
html, body {
  width: 100%;
  height: 100%;
  font-size: 12px;
  -webkit-text-size-adjust: none;
  overflow: hidden;
}

body, p, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, dt, dd, table, th, td, form, fieldset, legend, input, textarea, img, button, select {
  margin: 0;
  padding: 0;
}

body, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, button {
  font-family: "NSKR", "돋움", Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif;
}

img, fieldset {
  border: 0;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: #1a1a1a;
}

em, address {
  font-style: normal;
}

ul, ol, li {
  list-style: none;
}

label, button {
  cursor: pointer;
}

input::-ms-clear {
  display: none;
}

input:disabled {
  -webkit-text-fill-color: #8d8d8d;
  opacity: 1;
}

iframe {
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0;
}

* {
  -webkit-overflow-scrolling: touch;
}

/* 모바일에서 터치 부드러운 효과 적용 */
/* Noto Sans KR (korean) http://www.google.com/fonts/earlyaccess */
@font-face {
  font-family: "NSKT";
  font-style: normal;
  font-weight: 100;
  src: url("../font/NotoSansKr/NotoSansKR-Thin.woff2") format("woff2"), url("../font/NotoSansKr/NotoSansKR-Thin.woff") format("woff"), url("../font/NotoSansKr/NotoSansKR-Thin.otf") format("opentype");
}
@font-face {
  font-family: "NSKL";
  font-style: normal;
  font-weight: 300;
  src: url("../font/NotoSansKr/NotoSansKR-Light.woff2") format("woff2"), url("../font/NotoSansKr/NotoSansKR-Light.woff") format("woff"), url("../font/NotoSansKr/NotoSansKR-Light.otf") format("opentype");
}
@font-face {
  font-family: "NSKR";
  font-style: normal;
  font-weight: 400;
  src: url("../font/NotoSansKr/NotoSansKR-Regular.woff2") format("woff2"), url("../font/NotoSansKr/NotoSansKR-Regular.woff") format("woff"), url("../font/NotoSansKr/NotoSansKR-Regular.otf") format("opentype");
}
@font-face {
  font-family: "NSKM";
  font-style: normal;
  font-weight: 500;
  src: url("../font/NotoSansKr/NotoSansKR-Medium.woff2") format("woff2"), url("../font/NotoSansKr/NotoSansKR-Medium.woff") format("woff"), url("../font/NotoSansKr/NotoSansKR-Medium.otf") format("opentype");
}
@font-face {
  font-family: "NSKB";
  font-style: normal;
  font-weight: 700;
  src: url("../font/NotoSansKr/NotoSansKR-Bold.woff2") format("woff2"), url("../font/NotoSansKr/NotoSansKR-Bold.woff") format("woff"), url("../font/NotoSansKr/NotoSansKR-Bold.otf") format("opentype");
}
@font-face {
  font-family: "NSKBK";
  font-style: normal;
  font-weight: 900;
  src: url("../font/NotoSansKr/NotoSansKR-Black.woff2") format("woff2"), url("../font/NotoSansKr/NotoSansKR-Black.woff") format("woff"), url("../font/NotoSansKr/NotoSansKR-Black.otf") format("opentype");
}
/* 크롬 input 자동완성 배경 초기화 */
input:-webkit-autofill {
  box-shadow: 0 0 0 30px #fff inset;
  -webkit-text-fill-color: #000;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

/* PlaceHolder 예외 처리 */
::-webkit-input-placeholder {
  text-align: left !important;
  color: #999;
}

:-moz-placeholder {
  text-align: left !important;
  color: #999;
}

::-moz-placeholder {
  text-align: left !important;
  color: #999;
}

:-ms-input-placeholder {
  text-align: left !important;
  color: #999 !important;
}

::-ms-input-placeholder {
  text-align: left !important;
  color: #999;
}

::placeholder {
  text-align: left !important;
  color: #999;
}

input::-webkit-input-placeholder {
  text-align: left !important;
  color: #999;
}

input:-moz-placeholder {
  text-align: left !important;
  color: #999;
}

input::-moz-placeholder {
  text-align: left !important;
  color: #999;
}

input:-ms-input-placeholder {
  text-align: left !important;
  color: #999 !important;
}

input::-ms-input-placeholder {
  text-align: left !important;
  color: #999;
}

input::placeholder {
  text-align: left !important;
  color: #999;
}

/***************************** 
    컬러
******************************/
/**************************************************
    Common
**************************************************/
.accessibility {
  overflow: hidden;
  position: absolute;
  left: -1000px;
  top: 0;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  text-indent: -9999px;
}

.hidden {
  overflow: hidden;
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 1px;
  height: 1px;
}

.blind {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}

.skip {
  display: none;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
}

.no_ellipsis {
  white-space: normal !important;
}

.ovh {
  overflow: hidden !important;
}

.ovv {
  overflow: visible !important;
}

.eventLink {
  cursor: pointer;
}

.link {
  position: relative;
  cursor: pointer;
}
.link:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 1px;
  background: #000000;
  transition: all 0.5s;
}
.link:hover:after {
  width: 100%;
  transition: all 0.5s;
}

/* flex */
.h-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  min-width: 0;
}

.v-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
}

.flex-1 {
  -webkit-flex: 1;
  flex: 1;
  min-width: 0;
}

.flex-2 {
  -webkit-flex: 2;
  flex: 2;
  min-width: 0;
}

.flex-3 {
  -webkit-flex: 3;
  flex: 3;
  min-width: 0;
}

/*scroll*/
.scroll_on {
  overflow: auto !important;
}

.scroll_off {
  overflow: hidden !important;
}

.scroll_y_on {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.scroll_x_on {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}

.scroll_fix {
  overflow: scroll !important;
}

.scroll_y_fix {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.scroll_x_fix {
  overflow-y: hidden !important;
  overflow-x: scroll !important;
}

/*position*/
.posi_re {
  position: relative !important;
}

.posi_ab {
  position: absolute !important;
}

.posi_fix {
  position: fixed !important;
}

.posi_inh {
  position: inherit !important;
}

/* Floating */
.fl {
  float: left !important;
}

.fr {
  float: right !important;
}

.cl {
  clear: both !important;
}

/*각 자식요소들 floating 지정으로 높이 인식못하는거 해결*/
.clear:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

/* display */
.dp_ib {
  display: inline-block !important;
}

.dp_i {
  display: inline !important;
}

.dp_b {
  display: block !important;
}

.dp_n {
  display: none !important;
}

/* visibility */
.vv {
  visibility: visible;
}

.vh {
  visibility: hidden;
}

/* Align */
.al {
  text-align: left !important;
}

.ac {
  text-align: center !important;
}

.ar {
  text-align: right !important;
}

.vt {
  vertical-align: top !important;
}

.vm {
  vertical-align: middle !important;
}

.vb {
  vertical-align: bottom !important;
}

/* Font size */
.f11 {
  font-size: 11px !important;
}

.f12 {
  font-size: 12px !important;
}

.f13 {
  font-size: 13px !important;
}

.f14 {
  font-size: 14px !important;
}

.f15 {
  font-size: 15px !important;
}

.f16 {
  font-size: 16px !important;
}

.f17 {
  font-size: 17px !important;
}

.f18 {
  font-size: 18px !important;
}

.f19 {
  font-size: 19px !important;
}

.f20 {
  font-size: 20px !important;
}

/* Font weight */
.fwb {
  font-weight: bold !important;
}

.fwn {
  font-weight: normal !important;
}

/* Padding, Margin style */
/* 특별한 case외에 사용 자제 */
.p0 {
  padding: 0 !important;
}

.p10 {
  padding: 10px !important;
}

.p15 {
  padding: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pl0 {
  padding-left: 0px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.m0 {
  margin: 0px !important;
}

.m5 {
  margin: 5px !important;
}

.m10 {
  margin: 10px !important;
}

.m15 {
  margin: 15px !important;
}

.m20 {
  margin: 20px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mr50 {
  margin-right: 50px !important;
}

/***************************** 
    공통
******************************/
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#OrbitWrap {
  width: 100%;
  height: 100%;
  background-color: #2E3A4B;
}

.copyright {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.2);
  text-align: center;
  opacity: 0.9;
}

/***************************** 
    서브 헤더
******************************/
.headerWrap {
  width: 100%;
  height: 60px;
  background-color: #2E3A4B;
  overflow: hidden;
}
.headerWrap:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.headerWrap .menu-trigger {
  position: fixed;
  top: 17px;
  right: 20px;
  width: 30px;
  height: 24px;
  z-index: 1000;
  cursor: pointer;
}
.headerWrap .textLogo {
  float: left;
  position: relative;
  margin: 13px 0 0 20px;
  padding: 0 15px;
  font: 400 20px/30px "NSKB";
  color: #8BA9FF;
  text-align: center;
  border: 1px solid #8BA9FF;
  border-radius: 300px;
  box-sizing: border-box;
  background: #2E3A4B;
  cursor: pointer;
  z-index: 3;
}
.headerWrap .textLogo b {
  margin: 0 0 0 10px;
  font: 400 14px/30px "NSKT";
  vertical-align: top;
  letter-spacing: 0;
  transition: 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.headerWrap .textLogo:hover b {
  letter-spacing: 2px;
  transition: 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.headerWrap .textLink {
  float: right;
  position: relative;
  margin: 17px 70px 0 0;
  color: #AD8AFD;
  background-color: #2E3A4B;
  z-index: 3;
}
.headerWrap .textLink .link {
  font: 400 14px/24px "NSKR";
}
.headerWrap .textLink .link:after {
  background: #AD8AFD;
}
.headerWrap .textLink .link b {
  margin: 0 0 0 3px;
  font: 400 12px/24px "NSKR";
}
.headerWrap .textLink .apart {
  margin: 0 10px;
}

/***************************** 
    메인배경
******************************/
.orbitGalaxyBg {
  transform-style: preserve-3d;
  perspective: 10000px;
}
.orbitGalaxyBg .orbitCloud {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0.7) translate(-50%, -50%);
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  transition: transform 4s;
}
.orbitGalaxyBg .orbitCloud .orbitCluster {
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  perspective: 10000px;
  animation: rotation 1500s linear infinite;
}
.orbitGalaxyBg .orbitCloud .orbitCluster .star {
  perspective: 1000px;
}
.orbitGalaxyBg .orbitCloud .orbitCluster .star, .orbitGalaxyBg .orbitCloud .orbitCluster .star::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
}
.orbitGalaxyBg .orbitCloud .orbitCluster .star::before {
  content: "";
}

@keyframes rotation {
  from {
    transform: rotateY(0) rotateX(0);
  }
  to {
    transform: rotateY(-360deg) rotateX(360deg);
  }
}
.star:nth-child(1) {
  transform: translate3d(474px, 1988px, -808px);
}

.star:nth-child(2) {
  transform: translate3d(739px, 1701px, -442px);
}

.star:nth-child(3) {
  transform: translate3d(-1332px, 266px, 80px);
}

.star:nth-child(4) {
  transform: translate3d(-1309px, -557px, 941px);
}

.star:nth-child(5) {
  transform: translate3d(-389px, -1714px, 452px);
}

.star:nth-child(6) {
  transform: translate3d(-997px, 1764px, 1827px);
}

.star:nth-child(7) {
  transform: translate3d(962px, 704px, -937px);
}

.star:nth-child(8) {
  transform: translate3d(1753px, 609px, 512px);
}

.star:nth-child(9) {
  transform: translate3d(1081px, -360px, 559px);
}

.star:nth-child(10) {
  transform: translate3d(-408px, -883px, 1389px);
}

.star:nth-child(11) {
  transform: translate3d(-1626px, -693px, 1731px);
}

.star:nth-child(12) {
  transform: translate3d(631px, 560px, 1507px);
}

.star:nth-child(13) {
  transform: translate3d(-1545px, 1183px, -1648px);
}

.star:nth-child(14) {
  transform: translate3d(1259px, -913px, -1052px);
}

.star:nth-child(15) {
  transform: translate3d(1872px, -1908px, -776px);
}

.star:nth-child(16) {
  transform: translate3d(359px, -1660px, -1302px);
}

.star:nth-child(17) {
  transform: translate3d(-897px, -1823px, -248px);
}

.star:nth-child(18) {
  transform: translate3d(-1428px, 493px, 1668px);
}

.star:nth-child(19) {
  transform: translate3d(-1351px, -1499px, -142px);
}

.star:nth-child(20) {
  transform: translate3d(-842px, 1353px, -1513px);
}

.star:nth-child(21) {
  transform: translate3d(1377px, -1465px, -418px);
}

.star:nth-child(22) {
  transform: translate3d(-760px, -1229px, -1701px);
}

.star:nth-child(23) {
  transform: translate3d(-1680px, -1814px, -1682px);
}

.star:nth-child(24) {
  transform: translate3d(-1292px, 32px, -615px);
}

.star:nth-child(25) {
  transform: translate3d(1202px, 546px, 1415px);
}

.star:nth-child(26) {
  transform: translate3d(6px, 373px, -1416px);
}

.star:nth-child(27) {
  transform: translate3d(159px, 1859px, 838px);
}

.star:nth-child(28) {
  transform: translate3d(913px, 1880px, 1306px);
}

.star:nth-child(29) {
  transform: translate3d(-1143px, -692px, 460px);
}

.star:nth-child(30) {
  transform: translate3d(1338px, -844px, 273px);
}

.star:nth-child(31) {
  transform: translate3d(-75px, 948px, 1693px);
}

.star:nth-child(32) {
  transform: translate3d(-1075px, 602px, -1713px);
}

.star:nth-child(33) {
  transform: translate3d(39px, 136px, -925px);
}

.star:nth-child(34) {
  transform: translate3d(-1444px, 157px, 21px);
}

.star:nth-child(35) {
  transform: translate3d(-88px, 997px, 1536px);
}

.star:nth-child(36) {
  transform: translate3d(1921px, -651px, -984px);
}

.star:nth-child(37) {
  transform: translate3d(-1756px, -1647px, 1909px);
}

.star:nth-child(38) {
  transform: translate3d(1386px, -839px, -278px);
}

.star:nth-child(39) {
  transform: translate3d(1444px, -190px, 1864px);
}

.star:nth-child(40) {
  transform: translate3d(898px, 1122px, 1730px);
}

.star:nth-child(41) {
  transform: translate3d(110px, 1572px, -220px);
}

.star:nth-child(42) {
  transform: translate3d(-1861px, -792px, 1519px);
}

.star:nth-child(43) {
  transform: translate3d(998px, 475px, 589px);
}

.star:nth-child(44) {
  transform: translate3d(155px, 1419px, -679px);
}

.star:nth-child(45) {
  transform: translate3d(1033px, -1863px, 735px);
}

.star:nth-child(46) {
  transform: translate3d(-568px, -1474px, 1961px);
}

.star:nth-child(47) {
  transform: translate3d(-374px, 794px, -747px);
}

.star:nth-child(48) {
  transform: translate3d(-258px, 1151px, -644px);
}

.star:nth-child(49) {
  transform: translate3d(1046px, -1947px, 998px);
}

.star:nth-child(50) {
  transform: translate3d(-863px, 1117px, 458px);
}

.star:nth-child(51) {
  transform: translate3d(1565px, -688px, 205px);
}

.star:nth-child(52) {
  transform: translate3d(1970px, -1189px, 814px);
}

.star:nth-child(53) {
  transform: translate3d(147px, 1074px, 1066px);
}

.star:nth-child(54) {
  transform: translate3d(-1506px, 1961px, -551px);
}

.star:nth-child(55) {
  transform: translate3d(299px, 1624px, -245px);
}

.star:nth-child(56) {
  transform: translate3d(-1512px, -89px, -769px);
}

.star:nth-child(57) {
  transform: translate3d(-18px, -395px, 1914px);
}

.star:nth-child(58) {
  transform: translate3d(1571px, 889px, 1810px);
}

.star:nth-child(59) {
  transform: translate3d(828px, 623px, -331px);
}

.star:nth-child(60) {
  transform: translate3d(80px, 410px, 1532px);
}

.star:nth-child(61) {
  transform: translate3d(-159px, -607px, 198px);
}

.star:nth-child(62) {
  transform: translate3d(906px, 1601px, -1831px);
}

.star:nth-child(63) {
  transform: translate3d(-356px, 1562px, 1763px);
}

.star:nth-child(64) {
  transform: translate3d(269px, 1845px, 868px);
}

.star:nth-child(65) {
  transform: translate3d(-696px, -655px, -1463px);
}

.star:nth-child(66) {
  transform: translate3d(-1275px, -1477px, -153px);
}

.star:nth-child(67) {
  transform: translate3d(-785px, 1357px, -1647px);
}

.star:nth-child(68) {
  transform: translate3d(1338px, 224px, 1637px);
}

.star:nth-child(69) {
  transform: translate3d(-1906px, 1206px, 1833px);
}

.star:nth-child(70) {
  transform: translate3d(603px, -1118px, 1066px);
}

.star:nth-child(71) {
  transform: translate3d(-169px, 99px, -935px);
}

.star:nth-child(72) {
  transform: translate3d(1001px, 705px, -696px);
}

.star:nth-child(73) {
  transform: translate3d(1677px, 233px, -524px);
}

.star:nth-child(74) {
  transform: translate3d(485px, -1208px, 1321px);
}

.star:nth-child(75) {
  transform: translate3d(1984px, 1492px, 1011px);
}

.star:nth-child(76) {
  transform: translate3d(-1543px, 402px, 289px);
}

.star:nth-child(77) {
  transform: translate3d(1026px, 1638px, -711px);
}

.star:nth-child(78) {
  transform: translate3d(-1381px, -99px, 1453px);
}

.star:nth-child(79) {
  transform: translate3d(-370px, -524px, -1112px);
}

.star:nth-child(80) {
  transform: translate3d(-928px, -233px, -823px);
}

.star:nth-child(81) {
  transform: translate3d(1286px, -1243px, 175px);
}

.star:nth-child(82) {
  transform: translate3d(-1491px, -1757px, -1732px);
}

.star:nth-child(83) {
  transform: translate3d(-951px, -404px, 1446px);
}

.star:nth-child(84) {
  transform: translate3d(-419px, 931px, -1510px);
}

.star:nth-child(85) {
  transform: translate3d(-1666px, 1329px, 1739px);
}

.star:nth-child(86) {
  transform: translate3d(402px, 1731px, 1075px);
}

.star:nth-child(87) {
  transform: translate3d(905px, -302px, -402px);
}

.star:nth-child(88) {
  transform: translate3d(-1564px, 546px, -811px);
}

.star:nth-child(89) {
  transform: translate3d(-1849px, -182px, 921px);
}

.star:nth-child(90) {
  transform: translate3d(-1589px, 261px, 1369px);
}

.star:nth-child(91) {
  transform: translate3d(-1559px, 1920px, 1089px);
}

.star:nth-child(92) {
  transform: translate3d(1035px, 554px, -1070px);
}

.star:nth-child(93) {
  transform: translate3d(-1506px, -394px, 149px);
}

.star:nth-child(94) {
  transform: translate3d(1176px, 975px, 1833px);
}

.star:nth-child(95) {
  transform: translate3d(397px, -413px, 835px);
}

.star:nth-child(96) {
  transform: translate3d(1382px, -575px, 1682px);
}

.star:nth-child(97) {
  transform: translate3d(688px, 1026px, -1596px);
}

.star:nth-child(98) {
  transform: translate3d(-36px, 931px, -1329px);
}

.star:nth-child(99) {
  transform: translate3d(-703px, -422px, 1566px);
}

.star:nth-child(100) {
  transform: translate3d(1398px, 1009px, -1004px);
}

.star:nth-child(101) {
  transform: translate3d(-99px, 218px, 1628px);
}

.star:nth-child(102) {
  transform: translate3d(-571px, -1328px, 1278px);
}

.star:nth-child(103) {
  transform: translate3d(208px, -771px, -1619px);
}

.star:nth-child(104) {
  transform: translate3d(-1833px, 78px, -602px);
}

.star:nth-child(105) {
  transform: translate3d(-1287px, -1593px, -172px);
}

.star:nth-child(106) {
  transform: translate3d(-135px, -1273px, 450px);
}

.star:nth-child(107) {
  transform: translate3d(887px, -1250px, 1774px);
}

.star:nth-child(108) {
  transform: translate3d(1077px, 1220px, 1922px);
}

.star:nth-child(109) {
  transform: translate3d(560px, 819px, 1961px);
}

.star:nth-child(110) {
  transform: translate3d(-1198px, 1116px, 207px);
}

.star:nth-child(111) {
  transform: translate3d(353px, 981px, -645px);
}

.star:nth-child(112) {
  transform: translate3d(-913px, 1960px, 1065px);
}

.star:nth-child(113) {
  transform: translate3d(464px, -1706px, 1571px);
}

.star:nth-child(114) {
  transform: translate3d(1889px, 1523px, 1256px);
}

.star:nth-child(115) {
  transform: translate3d(1369px, 1260px, 1389px);
}

.star:nth-child(116) {
  transform: translate3d(-547px, 712px, -326px);
}

.star:nth-child(117) {
  transform: translate3d(-1247px, -1948px, 1350px);
}

.star:nth-child(118) {
  transform: translate3d(50px, 1573px, 1825px);
}

.star:nth-child(119) {
  transform: translate3d(576px, -723px, 717px);
}

.star:nth-child(120) {
  transform: translate3d(1557px, -1373px, 21px);
}

.star:nth-child(121) {
  transform: translate3d(-1457px, -224px, -416px);
}

.star:nth-child(122) {
  transform: translate3d(-797px, 370px, 1567px);
}

.star:nth-child(123) {
  transform: translate3d(848px, 1131px, 1531px);
}

.star:nth-child(124) {
  transform: translate3d(-789px, 409px, 324px);
}

.star:nth-child(125) {
  transform: translate3d(-1263px, 399px, 1599px);
}

.star:nth-child(126) {
  transform: translate3d(387px, -255px, -65px);
}

.star:nth-child(127) {
  transform: translate3d(1705px, 1121px, -1224px);
}

.star:nth-child(128) {
  transform: translate3d(-661px, 833px, -1879px);
}

.star:nth-child(129) {
  transform: translate3d(-1597px, -603px, -422px);
}

.star:nth-child(130) {
  transform: translate3d(658px, 1874px, 1557px);
}

.star:nth-child(131) {
  transform: translate3d(1723px, 851px, 1004px);
}

.star:nth-child(132) {
  transform: translate3d(589px, 747px, -1633px);
}

.star:nth-child(133) {
  transform: translate3d(1392px, 395px, 1394px);
}

.star:nth-child(134) {
  transform: translate3d(1951px, -618px, 1242px);
}

.star:nth-child(135) {
  transform: translate3d(-1098px, -497px, 1024px);
}

.star:nth-child(136) {
  transform: translate3d(595px, -460px, 620px);
}

.star:nth-child(137) {
  transform: translate3d(-1632px, -1185px, -518px);
}

.star:nth-child(138) {
  transform: translate3d(-16px, -1713px, -155px);
}

.star:nth-child(139) {
  transform: translate3d(879px, 702px, 1571px);
}

.star:nth-child(140) {
  transform: translate3d(1051px, -705px, -1312px);
}

.star:nth-child(141) {
  transform: translate3d(-1345px, 1040px, 76px);
}

.star:nth-child(142) {
  transform: translate3d(1454px, -927px, 1432px);
}

.star:nth-child(143) {
  transform: translate3d(-499px, 447px, -1760px);
}

.star:nth-child(144) {
  transform: translate3d(1275px, -1079px, -581px);
}

.star:nth-child(145) {
  transform: translate3d(1219px, -887px, 1203px);
}

.star:nth-child(146) {
  transform: translate3d(-1689px, -1045px, -227px);
}

.star:nth-child(147) {
  transform: translate3d(1967px, -568px, -732px);
}

.star:nth-child(148) {
  transform: translate3d(-168px, -1800px, 1836px);
}

.star:nth-child(149) {
  transform: translate3d(1645px, -685px, 669px);
}

.star:nth-child(150) {
  transform: translate3d(400px, -737px, 1988px);
}

.star:nth-child(151) {
  transform: translate3d(-1439px, -1913px, -71px);
}

.star:nth-child(152) {
  transform: translate3d(-394px, 302px, -1840px);
}

.star:nth-child(153) {
  transform: translate3d(738px, -655px, 1322px);
}

.star:nth-child(154) {
  transform: translate3d(1034px, 157px, -1544px);
}

.star:nth-child(155) {
  transform: translate3d(970px, 639px, 349px);
}

.star:nth-child(156) {
  transform: translate3d(-1668px, 1911px, 557px);
}

.star:nth-child(157) {
  transform: translate3d(224px, -1927px, -1029px);
}

.star:nth-child(158) {
  transform: translate3d(903px, 1668px, 1080px);
}

.star:nth-child(159) {
  transform: translate3d(-19px, -689px, 173px);
}

.star:nth-child(160) {
  transform: translate3d(1203px, 1857px, -107px);
}

.star:nth-child(161) {
  transform: translate3d(-971px, -129px, 1050px);
}

.star:nth-child(162) {
  transform: translate3d(228px, 1879px, -1223px);
}

.star:nth-child(163) {
  transform: translate3d(1664px, -1370px, 1423px);
}

.star:nth-child(164) {
  transform: translate3d(1714px, -51px, -869px);
}

.star:nth-child(165) {
  transform: translate3d(-662px, -1391px, -222px);
}

.star:nth-child(166) {
  transform: translate3d(1736px, 704px, -1801px);
}

.star:nth-child(167) {
  transform: translate3d(566px, -1425px, -712px);
}

.star:nth-child(168) {
  transform: translate3d(1905px, 509px, -1645px);
}

.star:nth-child(169) {
  transform: translate3d(-992px, -305px, -150px);
}

.star:nth-child(170) {
  transform: translate3d(-26px, 1195px, -1806px);
}

.star:nth-child(171) {
  transform: translate3d(123px, 1144px, -820px);
}

.star:nth-child(172) {
  transform: translate3d(1471px, 91px, 1227px);
}

.star:nth-child(173) {
  transform: translate3d(-1019px, 1574px, 1074px);
}

.star:nth-child(174) {
  transform: translate3d(-947px, 1930px, 1055px);
}

.star:nth-child(175) {
  transform: translate3d(-601px, -691px, 270px);
}

.star:nth-child(176) {
  transform: translate3d(-1339px, -710px, -213px);
}

.star:nth-child(177) {
  transform: translate3d(-1113px, -640px, 1872px);
}

.star:nth-child(178) {
  transform: translate3d(1899px, -404px, 1085px);
}

.star:nth-child(179) {
  transform: translate3d(-1693px, 757px, 897px);
}

.star:nth-child(180) {
  transform: translate3d(1917px, 767px, -546px);
}

.star:nth-child(181) {
  transform: translate3d(-875px, -677px, -1160px);
}

.star:nth-child(182) {
  transform: translate3d(155px, -687px, 47px);
}

.star:nth-child(183) {
  transform: translate3d(-1930px, 779px, -1294px);
}

.star:nth-child(184) {
  transform: translate3d(1803px, 1305px, -147px);
}

.star:nth-child(185) {
  transform: translate3d(112px, -1256px, 596px);
}

.star:nth-child(186) {
  transform: translate3d(-691px, 428px, -371px);
}

.star:nth-child(187) {
  transform: translate3d(1622px, 1189px, -1745px);
}

.star:nth-child(188) {
  transform: translate3d(1690px, -721px, 1823px);
}

.star:nth-child(189) {
  transform: translate3d(-108px, 1465px, -724px);
}

.star:nth-child(190) {
  transform: translate3d(-209px, 1603px, -588px);
}

.star:nth-child(191) {
  transform: translate3d(-1371px, 1085px, 1235px);
}

.star:nth-child(192) {
  transform: translate3d(-1701px, 208px, -1347px);
}

.star:nth-child(193) {
  transform: translate3d(1070px, -854px, -573px);
}

.star:nth-child(194) {
  transform: translate3d(-1113px, 1078px, -459px);
}

.star:nth-child(195) {
  transform: translate3d(-1853px, 1731px, 351px);
}

.star:nth-child(196) {
  transform: translate3d(-1243px, 117px, 736px);
}

.star:nth-child(197) {
  transform: translate3d(-396px, 558px, 252px);
}

.star:nth-child(198) {
  transform: translate3d(-1429px, -996px, -1776px);
}

.star:nth-child(199) {
  transform: translate3d(-1681px, 490px, 1070px);
}

.star:nth-child(200) {
  transform: translate3d(992px, -404px, -1487px);
}

.star:nth-child(201) {
  transform: translate3d(-335px, -1174px, 436px);
}

.star:nth-child(202) {
  transform: translate3d(-1107px, 1671px, 1144px);
}

.star:nth-child(203) {
  transform: translate3d(-1026px, 532px, 54px);
}

.star:nth-child(204) {
  transform: translate3d(-941px, -1025px, 1329px);
}

.star:nth-child(205) {
  transform: translate3d(1384px, -1359px, 1612px);
}

.star:nth-child(206) {
  transform: translate3d(-394px, 255px, -1958px);
}

.star:nth-child(207) {
  transform: translate3d(-570px, 883px, 473px);
}

.star:nth-child(208) {
  transform: translate3d(322px, -1446px, 768px);
}

.star:nth-child(209) {
  transform: translate3d(-89px, -1578px, -1041px);
}

.star:nth-child(210) {
  transform: translate3d(-740px, 1680px, -1546px);
}

.star:nth-child(211) {
  transform: translate3d(-1920px, 344px, 684px);
}

.star:nth-child(212) {
  transform: translate3d(932px, 1073px, -476px);
}

.star:nth-child(213) {
  transform: translate3d(1510px, 1455px, 618px);
}

.star:nth-child(214) {
  transform: translate3d(-933px, 1716px, -1094px);
}

.star:nth-child(215) {
  transform: translate3d(-128px, -751px, 445px);
}

.star:nth-child(216) {
  transform: translate3d(-362px, 1723px, -1325px);
}

.star:nth-child(217) {
  transform: translate3d(-1606px, 240px, 532px);
}

.star:nth-child(218) {
  transform: translate3d(-1203px, -653px, -1441px);
}

.star:nth-child(219) {
  transform: translate3d(-175px, 1988px, -1242px);
}

.star:nth-child(220) {
  transform: translate3d(-205px, -1337px, 1667px);
}

.star:nth-child(221) {
  transform: translate3d(894px, -1374px, 1108px);
}

.star:nth-child(222) {
  transform: translate3d(-600px, -1776px, -436px);
}

.star:nth-child(223) {
  transform: translate3d(642px, -1517px, 1244px);
}

.star:nth-child(224) {
  transform: translate3d(1078px, -592px, 811px);
}

.star:nth-child(225) {
  transform: translate3d(-1788px, 1052px, 1087px);
}

.star:nth-child(226) {
  transform: translate3d(-701px, -905px, -116px);
}

.star:nth-child(227) {
  transform: translate3d(495px, 666px, -345px);
}

.star:nth-child(228) {
  transform: translate3d(621px, 1505px, 337px);
}

.star:nth-child(229) {
  transform: translate3d(713px, -1368px, 1497px);
}

.star:nth-child(230) {
  transform: translate3d(1245px, -920px, -1414px);
}

.star:nth-child(231) {
  transform: translate3d(348px, 1180px, -822px);
}

.star:nth-child(232) {
  transform: translate3d(-951px, -100px, -863px);
}

.star:nth-child(233) {
  transform: translate3d(-88px, 1306px, 1459px);
}

.star:nth-child(234) {
  transform: translate3d(-784px, -1022px, 1200px);
}

.star:nth-child(235) {
  transform: translate3d(1514px, 1467px, 1234px);
}

.star:nth-child(236) {
  transform: translate3d(-1309px, 1948px, 779px);
}

.star:nth-child(237) {
  transform: translate3d(-1341px, 1335px, 404px);
}

.star:nth-child(238) {
  transform: translate3d(-873px, -1075px, -1643px);
}

.star:nth-child(239) {
  transform: translate3d(-1542px, 349px, 304px);
}

.star:nth-child(240) {
  transform: translate3d(-1347px, 1310px, -563px);
}

.star:nth-child(241) {
  transform: translate3d(-938px, -186px, 1345px);
}

.star:nth-child(242) {
  transform: translate3d(293px, 1132px, 1104px);
}

.star:nth-child(243) {
  transform: translate3d(1916px, 501px, -621px);
}

.star:nth-child(244) {
  transform: translate3d(-425px, 392px, 958px);
}

.star:nth-child(245) {
  transform: translate3d(-1518px, 1285px, 1322px);
}

.star:nth-child(246) {
  transform: translate3d(1137px, 896px, -585px);
}

.star:nth-child(247) {
  transform: translate3d(-1278px, 1288px, -1270px);
}

.star:nth-child(248) {
  transform: translate3d(-1410px, 1337px, -1144px);
}

.star:nth-child(249) {
  transform: translate3d(656px, -487px, 1400px);
}

.star:nth-child(250) {
  transform: translate3d(-363px, 1629px, -1235px);
}

.star:nth-child(251) {
  transform: translate3d(1367px, -239px, -73px);
}

.star:nth-child(252) {
  transform: translate3d(-77px, -988px, -234px);
}

.star:nth-child(253) {
  transform: translate3d(1131px, -1742px, 809px);
}

.star:nth-child(254) {
  transform: translate3d(209px, 866px, 870px);
}

.star:nth-child(255) {
  transform: translate3d(1763px, 559px, -691px);
}

.star:nth-child(256) {
  transform: translate3d(-461px, 1611px, 98px);
}

.star:nth-child(257) {
  transform: translate3d(-1189px, -1131px, -128px);
}

.star:nth-child(258) {
  transform: translate3d(-731px, 1627px, -1348px);
}

.star:nth-child(259) {
  transform: translate3d(1646px, -1351px, -118px);
}

.star:nth-child(260) {
  transform: translate3d(-712px, 1102px, -1426px);
}

.star:nth-child(261) {
  transform: translate3d(-814px, 894px, 1846px);
}

.star:nth-child(262) {
  transform: translate3d(-1779px, -495px, 733px);
}

.star:nth-child(263) {
  transform: translate3d(1484px, 1646px, -39px);
}

.star:nth-child(264) {
  transform: translate3d(-1782px, -349px, -488px);
}

.star:nth-child(265) {
  transform: translate3d(-592px, 1789px, 1301px);
}

.star:nth-child(266) {
  transform: translate3d(1456px, -823px, -1315px);
}

.star:nth-child(267) {
  transform: translate3d(897px, 1139px, 127px);
}

.star:nth-child(268) {
  transform: translate3d(120px, -879px, -305px);
}

.star:nth-child(269) {
  transform: translate3d(216px, -1207px, -1823px);
}

.star:nth-child(270) {
  transform: translate3d(217px, -770px, -1055px);
}

.star:nth-child(271) {
  transform: translate3d(1004px, -1780px, 980px);
}

.star:nth-child(272) {
  transform: translate3d(1330px, -824px, 224px);
}

.star:nth-child(273) {
  transform: translate3d(-316px, 1040px, 1667px);
}

.star:nth-child(274) {
  transform: translate3d(1400px, 810px, -1479px);
}

.star:nth-child(275) {
  transform: translate3d(1964px, 1358px, 325px);
}

.star:nth-child(276) {
  transform: translate3d(-1279px, 1010px, -1215px);
}

.star:nth-child(277) {
  transform: translate3d(-448px, -10px, 254px);
}

.star:nth-child(278) {
  transform: translate3d(1853px, -1087px, 625px);
}

.star:nth-child(279) {
  transform: translate3d(592px, -1510px, -90px);
}

.star:nth-child(280) {
  transform: translate3d(1964px, -38px, -359px);
}

.star:nth-child(281) {
  transform: translate3d(1132px, 1067px, -243px);
}

.star:nth-child(282) {
  transform: translate3d(1916px, 716px, -298px);
}

.star:nth-child(283) {
  transform: translate3d(-1796px, -1644px, -1314px);
}

.star:nth-child(284) {
  transform: translate3d(1825px, -1872px, -1183px);
}

.star:nth-child(285) {
  transform: translate3d(-1622px, -720px, 279px);
}

.star:nth-child(286) {
  transform: translate3d(884px, -1164px, 1674px);
}

.star:nth-child(287) {
  transform: translate3d(-1170px, -1315px, 1547px);
}

.star:nth-child(288) {
  transform: translate3d(1814px, -1003px, -1975px);
}

.star:nth-child(289) {
  transform: translate3d(499px, 552px, 1205px);
}

.star:nth-child(290) {
  transform: translate3d(1968px, 1790px, 1112px);
}

.star:nth-child(291) {
  transform: translate3d(655px, 362px, -1804px);
}

.star:nth-child(292) {
  transform: translate3d(-1035px, 24px, 131px);
}

.star:nth-child(293) {
  transform: translate3d(-362px, 561px, -1703px);
}

.star:nth-child(294) {
  transform: translate3d(-1038px, -360px, 1270px);
}

.star:nth-child(295) {
  transform: translate3d(318px, 951px, 1392px);
}

.star:nth-child(296) {
  transform: translate3d(-346px, -541px, 1653px);
}

.star:nth-child(297) {
  transform: translate3d(1390px, -1640px, -62px);
}

.star:nth-child(298) {
  transform: translate3d(-1256px, -168px, -1347px);
}

.star:nth-child(299) {
  transform: translate3d(1415px, 14px, 1815px);
}

.star:nth-child(300) {
  transform: translate3d(1684px, 460px, -1405px);
}

.star:nth-child(301) {
  transform: translate3d(503px, -811px, -789px);
}

.star:nth-child(302) {
  transform: translate3d(1185px, -790px, 591px);
}

.star:nth-child(303) {
  transform: translate3d(1236px, 1334px, 181px);
}

.star:nth-child(304) {
  transform: translate3d(-171px, -268px, -288px);
}

.star:nth-child(305) {
  transform: translate3d(-719px, 1361px, 826px);
}

.star:nth-child(306) {
  transform: translate3d(561px, -39px, 1945px);
}

.star:nth-child(307) {
  transform: translate3d(385px, 171px, 1639px);
}

.star:nth-child(308) {
  transform: translate3d(-1072px, -1197px, 1580px);
}

.star:nth-child(309) {
  transform: translate3d(869px, 603px, 1442px);
}

.star:nth-child(310) {
  transform: translate3d(-1092px, -365px, -77px);
}

.star:nth-child(311) {
  transform: translate3d(-509px, 493px, -1166px);
}

.star:nth-child(312) {
  transform: translate3d(-1550px, -366px, -1014px);
}

.star:nth-child(313) {
  transform: translate3d(1777px, -317px, 1438px);
}

.star:nth-child(314) {
  transform: translate3d(1305px, -157px, -330px);
}

.star:nth-child(315) {
  transform: translate3d(-1431px, 300px, 340px);
}

.star:nth-child(316) {
  transform: translate3d(1309px, -418px, 1492px);
}

.star:nth-child(317) {
  transform: translate3d(-162px, 1224px, -1650px);
}

.star:nth-child(318) {
  transform: translate3d(1299px, -1072px, 1127px);
}

.star:nth-child(319) {
  transform: translate3d(-1237px, 818px, 834px);
}

.star:nth-child(320) {
  transform: translate3d(627px, -131px, -248px);
}

.star:nth-child(321) {
  transform: translate3d(-1512px, -13px, 679px);
}

.star:nth-child(322) {
  transform: translate3d(-950px, -1980px, 717px);
}

.star:nth-child(323) {
  transform: translate3d(-483px, -35px, 1855px);
}

.star:nth-child(324) {
  transform: translate3d(-1028px, 682px, -1768px);
}

.star:nth-child(325) {
  transform: translate3d(1464px, -918px, -796px);
}

.star:nth-child(326) {
  transform: translate3d(-1493px, 950px, 1615px);
}

.star:nth-child(327) {
  transform: translate3d(-90px, 1067px, -1227px);
}

.star:nth-child(328) {
  transform: translate3d(-1424px, -469px, 1304px);
}

.star:nth-child(329) {
  transform: translate3d(1667px, -1387px, 1920px);
}

.star:nth-child(330) {
  transform: translate3d(1889px, 1318px, 100px);
}

.star:nth-child(331) {
  transform: translate3d(-350px, 944px, -333px);
}

.star:nth-child(332) {
  transform: translate3d(1994px, 656px, -1240px);
}

.star:nth-child(333) {
  transform: translate3d(1645px, 545px, -1719px);
}

.star:nth-child(334) {
  transform: translate3d(-1091px, -1917px, -1089px);
}

.star:nth-child(335) {
  transform: translate3d(1078px, -165px, 498px);
}

.star:nth-child(336) {
  transform: translate3d(-960px, -711px, 204px);
}

.star:nth-child(337) {
  transform: translate3d(-1303px, -455px, 1646px);
}

.star:nth-child(338) {
  transform: translate3d(54px, 1743px, 1443px);
}

.star:nth-child(339) {
  transform: translate3d(1125px, 1830px, -926px);
}

.star:nth-child(340) {
  transform: translate3d(535px, -1075px, -1281px);
}

.star:nth-child(341) {
  transform: translate3d(892px, 1895px, 1771px);
}

.star:nth-child(342) {
  transform: translate3d(-273px, 1001px, -649px);
}

.star:nth-child(343) {
  transform: translate3d(-1549px, 1596px, -452px);
}

.star:nth-child(344) {
  transform: translate3d(1742px, -585px, 1844px);
}

.star:nth-child(345) {
  transform: translate3d(1928px, 951px, -809px);
}

.star:nth-child(346) {
  transform: translate3d(401px, -1583px, -844px);
}

.star:nth-child(347) {
  transform: translate3d(286px, -248px, 44px);
}

.star:nth-child(348) {
  transform: translate3d(-535px, -1388px, 942px);
}

.star:nth-child(349) {
  transform: translate3d(-1287px, -943px, -440px);
}

.star:nth-child(350) {
  transform: translate3d(-1034px, 125px, 167px);
}

.star:nth-child(351) {
  transform: translate3d(739px, -1699px, 1445px);
}

.star:nth-child(352) {
  transform: translate3d(1876px, 772px, 236px);
}

.star:nth-child(353) {
  transform: translate3d(1328px, 1142px, -1553px);
}

.star:nth-child(354) {
  transform: translate3d(1158px, -566px, -1869px);
}

.star:nth-child(355) {
  transform: translate3d(1315px, 81px, 853px);
}

.star:nth-child(356) {
  transform: translate3d(1113px, -1946px, -95px);
}

.star:nth-child(357) {
  transform: translate3d(-431px, 376px, 1837px);
}

.star:nth-child(358) {
  transform: translate3d(-417px, 1470px, 1361px);
}

.star:nth-child(359) {
  transform: translate3d(1117px, 840px, -683px);
}

.star:nth-child(360) {
  transform: translate3d(-1487px, -1983px, 42px);
}

.star:nth-child(361) {
  transform: translate3d(-1555px, -1101px, -1585px);
}

.star:nth-child(362) {
  transform: translate3d(-750px, -1985px, -21px);
}

.star:nth-child(363) {
  transform: translate3d(-649px, 1025px, 1558px);
}

.star:nth-child(364) {
  transform: translate3d(-254px, -521px, 1017px);
}

.star:nth-child(365) {
  transform: translate3d(120px, -802px, 1449px);
}

.star:nth-child(366) {
  transform: translate3d(969px, 1398px, -1235px);
}

.star:nth-child(367) {
  transform: translate3d(-5px, -716px, 1482px);
}

.star:nth-child(368) {
  transform: translate3d(-682px, 1229px, 1681px);
}

.star:nth-child(369) {
  transform: translate3d(-1548px, -330px, -1318px);
}

.star:nth-child(370) {
  transform: translate3d(-1985px, -749px, 954px);
}

.star:nth-child(371) {
  transform: translate3d(-1198px, -1347px, 455px);
}

.star:nth-child(372) {
  transform: translate3d(-1566px, -790px, -1619px);
}

.star:nth-child(373) {
  transform: translate3d(-1167px, 1514px, -90px);
}

.star:nth-child(374) {
  transform: translate3d(-1830px, 877px, 349px);
}

.star:nth-child(375) {
  transform: translate3d(415px, -890px, 1052px);
}

.star:nth-child(376) {
  transform: translate3d(1836px, 552px, 1637px);
}

.star:nth-child(377) {
  transform: translate3d(-1593px, -344px, 309px);
}

.star:nth-child(378) {
  transform: translate3d(-1891px, -141px, 248px);
}

.star:nth-child(379) {
  transform: translate3d(-271px, 1776px, -1515px);
}

.star:nth-child(380) {
  transform: translate3d(1105px, -1810px, -209px);
}

.star:nth-child(381) {
  transform: translate3d(-60px, -1754px, -817px);
}

.star:nth-child(382) {
  transform: translate3d(-739px, 947px, -1745px);
}

.star:nth-child(383) {
  transform: translate3d(-1232px, -550px, 1716px);
}

.star:nth-child(384) {
  transform: translate3d(1128px, -1327px, -1259px);
}

.star:nth-child(385) {
  transform: translate3d(726px, -1418px, 1607px);
}

.star:nth-child(386) {
  transform: translate3d(1235px, -1061px, -791px);
}

.star:nth-child(387) {
  transform: translate3d(-1208px, -141px, -190px);
}

.star:nth-child(388) {
  transform: translate3d(-102px, 940px, -1870px);
}

.star:nth-child(389) {
  transform: translate3d(398px, -1091px, 204px);
}

.star:nth-child(390) {
  transform: translate3d(-1092px, -1944px, -1480px);
}

.star:nth-child(391) {
  transform: translate3d(270px, -631px, 1140px);
}

.star:nth-child(392) {
  transform: translate3d(-1728px, 917px, -507px);
}

.star:nth-child(393) {
  transform: translate3d(-1205px, 457px, 1270px);
}

.star:nth-child(394) {
  transform: translate3d(1454px, -398px, 1818px);
}

.star:nth-child(395) {
  transform: translate3d(-1806px, -640px, -209px);
}

.star:nth-child(396) {
  transform: translate3d(-325px, 655px, -723px);
}

.star:nth-child(397) {
  transform: translate3d(-431px, 109px, -1119px);
}

.star:nth-child(398) {
  transform: translate3d(-675px, 1235px, 685px);
}

.star:nth-child(399) {
  transform: translate3d(702px, -974px, 1463px);
}

.star:nth-child(400) {
  transform: translate3d(-104px, 788px, 1732px);
}

.star:nth-child(401) {
  transform: translate3d(1436px, 1298px, 215px);
}

.star:nth-child(402) {
  transform: translate3d(1675px, -371px, -846px);
}

.star:nth-child(403) {
  transform: translate3d(-1464px, -1233px, -1667px);
}

.star:nth-child(404) {
  transform: translate3d(-439px, -629px, -1557px);
}

.star:nth-child(405) {
  transform: translate3d(-974px, -1558px, 1959px);
}

.star:nth-child(406) {
  transform: translate3d(1121px, 849px, -1380px);
}

.star:nth-child(407) {
  transform: translate3d(1650px, -1978px, 779px);
}

.star:nth-child(408) {
  transform: translate3d(-1896px, -1601px, 1583px);
}

.star:nth-child(409) {
  transform: translate3d(671px, 375px, -1720px);
}

.star:nth-child(410) {
  transform: translate3d(1209px, 1273px, -1725px);
}

.star:nth-child(411) {
  transform: translate3d(74px, 1115px, 1826px);
}

.star:nth-child(412) {
  transform: translate3d(-1009px, 961px, -636px);
}

.star:nth-child(413) {
  transform: translate3d(839px, -692px, -959px);
}

.star:nth-child(414) {
  transform: translate3d(-242px, -652px, -1687px);
}

.star:nth-child(415) {
  transform: translate3d(595px, -36px, -10px);
}

.star:nth-child(416) {
  transform: translate3d(-505px, -754px, 983px);
}

.star:nth-child(417) {
  transform: translate3d(-1696px, 383px, 156px);
}

.star:nth-child(418) {
  transform: translate3d(1321px, -1043px, -27px);
}

.star:nth-child(419) {
  transform: translate3d(-619px, 920px, -516px);
}

.star:nth-child(420) {
  transform: translate3d(-1345px, -774px, 1175px);
}

.star:nth-child(421) {
  transform: translate3d(904px, -1805px, -952px);
}

.star:nth-child(422) {
  transform: translate3d(99px, 1744px, -1837px);
}

.star:nth-child(423) {
  transform: translate3d(1557px, 1575px, 313px);
}

.star:nth-child(424) {
  transform: translate3d(-1793px, -60px, 1822px);
}

.star:nth-child(425) {
  transform: translate3d(-1157px, 1882px, -1806px);
}

.star:nth-child(426) {
  transform: translate3d(1475px, -458px, 1068px);
}

.star:nth-child(427) {
  transform: translate3d(1585px, 1189px, 875px);
}

.star:nth-child(428) {
  transform: translate3d(-1949px, -1013px, -1770px);
}

.star:nth-child(429) {
  transform: translate3d(-141px, 272px, -1535px);
}

.star:nth-child(430) {
  transform: translate3d(699px, -1135px, 1962px);
}

.star:nth-child(431) {
  transform: translate3d(-1593px, 1742px, 1561px);
}

.star:nth-child(432) {
  transform: translate3d(-713px, 1042px, -572px);
}

.star:nth-child(433) {
  transform: translate3d(-1878px, 478px, -83px);
}

.star:nth-child(434) {
  transform: translate3d(-31px, 1784px, -405px);
}

.star:nth-child(435) {
  transform: translate3d(863px, -168px, -1189px);
}

.star:nth-child(436) {
  transform: translate3d(1855px, -957px, 1991px);
}

.star:nth-child(437) {
  transform: translate3d(152px, -250px, -461px);
}

.star:nth-child(438) {
  transform: translate3d(-1781px, -1175px, -573px);
}

.star:nth-child(439) {
  transform: translate3d(-1548px, 993px, -1000px);
}

.star:nth-child(440) {
  transform: translate3d(537px, 741px, 1249px);
}

.star:nth-child(441) {
  transform: translate3d(-1761px, -583px, 615px);
}

.star:nth-child(442) {
  transform: translate3d(-1746px, -203px, 1844px);
}

.star:nth-child(443) {
  transform: translate3d(-150px, 331px, -906px);
}

.star:nth-child(444) {
  transform: translate3d(680px, -1200px, -332px);
}

.star:nth-child(445) {
  transform: translate3d(-1525px, -194px, -374px);
}

.star:nth-child(446) {
  transform: translate3d(-976px, 1532px, -1056px);
}

.star:nth-child(447) {
  transform: translate3d(1463px, 1558px, 897px);
}

.star:nth-child(448) {
  transform: translate3d(-1295px, 957px, 1792px);
}

.star:nth-child(449) {
  transform: translate3d(-565px, -1576px, 1867px);
}

.star:nth-child(450) {
  transform: translate3d(-1205px, -516px, 1887px);
}

.star:nth-child(451) {
  transform: translate3d(-86px, -815px, -1503px);
}

.star:nth-child(452) {
  transform: translate3d(905px, -1326px, 1963px);
}

.star:nth-child(453) {
  transform: translate3d(1963px, 751px, -728px);
}

.star:nth-child(454) {
  transform: translate3d(946px, -1880px, 692px);
}

.star:nth-child(455) {
  transform: translate3d(-40px, 992px, -40px);
}

.star:nth-child(456) {
  transform: translate3d(-1064px, 1338px, -272px);
}

.star:nth-child(457) {
  transform: translate3d(450px, -1330px, 1149px);
}

.star:nth-child(458) {
  transform: translate3d(-1324px, 1462px, 1048px);
}

.star:nth-child(459) {
  transform: translate3d(1456px, 335px, 1558px);
}

.star:nth-child(460) {
  transform: translate3d(-455px, -1579px, -1339px);
}

.star:nth-child(461) {
  transform: translate3d(-1281px, 824px, -992px);
}

.star:nth-child(462) {
  transform: translate3d(-1685px, 1104px, 1988px);
}

.star:nth-child(463) {
  transform: translate3d(-973px, 739px, -845px);
}

.star:nth-child(464) {
  transform: translate3d(-460px, -415px, 992px);
}

.star:nth-child(465) {
  transform: translate3d(736px, -885px, 297px);
}

.star:nth-child(466) {
  transform: translate3d(-719px, -975px, -1105px);
}

.star:nth-child(467) {
  transform: translate3d(731px, 283px, -1663px);
}

.star:nth-child(468) {
  transform: translate3d(1959px, -577px, 869px);
}

.star:nth-child(469) {
  transform: translate3d(1689px, -308px, 941px);
}

.star:nth-child(470) {
  transform: translate3d(95px, 597px, -762px);
}

.star:nth-child(471) {
  transform: translate3d(-1488px, 1960px, -1421px);
}

.star:nth-child(472) {
  transform: translate3d(-1924px, 1322px, -45px);
}

.star:nth-child(473) {
  transform: translate3d(-864px, -466px, 1324px);
}

.star:nth-child(474) {
  transform: translate3d(-1509px, 1514px, -1356px);
}

.star:nth-child(475) {
  transform: translate3d(-489px, 113px, 1764px);
}

.star:nth-child(476) {
  transform: translate3d(-1757px, -667px, 1834px);
}

.star:nth-child(477) {
  transform: translate3d(732px, 851px, 228px);
}

.star:nth-child(478) {
  transform: translate3d(372px, 923px, 1819px);
}

.star:nth-child(479) {
  transform: translate3d(-801px, 1082px, -1928px);
}

.star:nth-child(480) {
  transform: translate3d(-767px, -1577px, 616px);
}

.star:nth-child(481) {
  transform: translate3d(-1055px, 1047px, 543px);
}

.star:nth-child(482) {
  transform: translate3d(-1451px, -1341px, 460px);
}

.star:nth-child(483) {
  transform: translate3d(827px, -1117px, -1514px);
}

.star:nth-child(484) {
  transform: translate3d(388px, -916px, -953px);
}

.star:nth-child(485) {
  transform: translate3d(955px, 1881px, 662px);
}

.star:nth-child(486) {
  transform: translate3d(-785px, -1672px, 212px);
}

.star:nth-child(487) {
  transform: translate3d(1488px, 938px, -525px);
}

.star:nth-child(488) {
  transform: translate3d(-1709px, 1540px, -1192px);
}

.star:nth-child(489) {
  transform: translate3d(-767px, -1503px, 1137px);
}

.star:nth-child(490) {
  transform: translate3d(-1334px, -55px, 1628px);
}

.star:nth-child(491) {
  transform: translate3d(-1672px, 1258px, -1034px);
}

.star:nth-child(492) {
  transform: translate3d(1126px, 622px, -1701px);
}

.star:nth-child(493) {
  transform: translate3d(16px, 1677px, -1916px);
}

.star:nth-child(494) {
  transform: translate3d(1669px, 408px, 200px);
}

.star:nth-child(495) {
  transform: translate3d(1445px, -282px, -624px);
}

.star:nth-child(496) {
  transform: translate3d(1859px, 1418px, 236px);
}

.star:nth-child(497) {
  transform: translate3d(-1966px, -334px, 666px);
}

.star:nth-child(498) {
  transform: translate3d(1777px, 1995px, -1891px);
}

.star:nth-child(499) {
  transform: translate3d(-1782px, -1256px, 621px);
}

.star:nth-child(500) {
  transform: translate3d(-1171px, -907px, -107px);
}

.star:nth-child(501) {
  transform: translate3d(1397px, 377px, -1634px);
}

.star:nth-child(502) {
  transform: translate3d(1848px, 1564px, 11px);
}

.star:nth-child(503) {
  transform: translate3d(1069px, 1483px, -702px);
}

.star:nth-child(504) {
  transform: translate3d(-1440px, 1645px, -415px);
}

.star:nth-child(505) {
  transform: translate3d(926px, -265px, 909px);
}

.star:nth-child(506) {
  transform: translate3d(-75px, 1038px, 1537px);
}

.star:nth-child(507) {
  transform: translate3d(-1923px, 1220px, -656px);
}

.star:nth-child(508) {
  transform: translate3d(-164px, -1921px, 1454px);
}

.star:nth-child(509) {
  transform: translate3d(209px, -1304px, 1675px);
}

.star:nth-child(510) {
  transform: translate3d(1792px, -62px, 1729px);
}

.star:nth-child(511) {
  transform: translate3d(-1704px, 1162px, 907px);
}

.star:nth-child(512) {
  transform: translate3d(-1450px, -1349px, -772px);
}

.star:nth-child(513) {
  transform: translate3d(-532px, -65px, 1279px);
}

.star:nth-child(514) {
  transform: translate3d(952px, 186px, -1108px);
}

.star:nth-child(515) {
  transform: translate3d(1351px, -320px, -927px);
}

.star:nth-child(516) {
  transform: translate3d(973px, -1553px, 1706px);
}

.star:nth-child(517) {
  transform: translate3d(-1645px, -102px, 1728px);
}

.star:nth-child(518) {
  transform: translate3d(-156px, -497px, -982px);
}

.star:nth-child(519) {
  transform: translate3d(1331px, -739px, -1856px);
}

.star:nth-child(520) {
  transform: translate3d(-252px, -1993px, 1629px);
}

.star:nth-child(521) {
  transform: translate3d(1742px, 1px, 306px);
}

.star:nth-child(522) {
  transform: translate3d(709px, 481px, 1135px);
}

.star:nth-child(523) {
  transform: translate3d(1181px, -201px, 408px);
}

.star:nth-child(524) {
  transform: translate3d(721px, -1177px, 1867px);
}

.star:nth-child(525) {
  transform: translate3d(1557px, -1378px, -683px);
}

.star:nth-child(526) {
  transform: translate3d(-76px, 1132px, -1945px);
}

.star:nth-child(527) {
  transform: translate3d(-1818px, -315px, -1306px);
}

.star:nth-child(528) {
  transform: translate3d(-1789px, -1428px, -981px);
}

.star:nth-child(529) {
  transform: translate3d(-434px, -193px, -1100px);
}

.star:nth-child(530) {
  transform: translate3d(-900px, -409px, -1713px);
}

.star:nth-child(531) {
  transform: translate3d(1354px, 1588px, 1398px);
}

.star:nth-child(532) {
  transform: translate3d(1561px, 1956px, 123px);
}

.star:nth-child(533) {
  transform: translate3d(-707px, 1248px, 1541px);
}

.star:nth-child(534) {
  transform: translate3d(433px, 1405px, -250px);
}

.star:nth-child(535) {
  transform: translate3d(-845px, 970px, 205px);
}

.star:nth-child(536) {
  transform: translate3d(-1831px, -455px, 212px);
}

.star:nth-child(537) {
  transform: translate3d(51px, -658px, 1057px);
}

.star:nth-child(538) {
  transform: translate3d(-394px, -615px, -1198px);
}

.star:nth-child(539) {
  transform: translate3d(-934px, 198px, 1343px);
}

.star:nth-child(540) {
  transform: translate3d(449px, 391px, -1323px);
}

.star:nth-child(541) {
  transform: translate3d(213px, -162px, 1912px);
}

.star:nth-child(542) {
  transform: translate3d(-1307px, 1514px, -1071px);
}

.star:nth-child(543) {
  transform: translate3d(-1959px, -930px, 847px);
}

.star:nth-child(544) {
  transform: translate3d(-561px, -1877px, 1238px);
}

.star:nth-child(545) {
  transform: translate3d(1046px, 1289px, 889px);
}

.star:nth-child(546) {
  transform: translate3d(584px, 776px, -983px);
}

.star:nth-child(547) {
  transform: translate3d(-1521px, 1744px, -1837px);
}

.star:nth-child(548) {
  transform: translate3d(1148px, -1257px, 415px);
}

.star:nth-child(549) {
  transform: translate3d(-1577px, 1394px, -1984px);
}

.star:nth-child(550) {
  transform: translate3d(-1596px, -665px, -1806px);
}

.star:nth-child(551) {
  transform: translate3d(-708px, 1682px, 1225px);
}

.star:nth-child(552) {
  transform: translate3d(-664px, -308px, 1455px);
}

.star:nth-child(553) {
  transform: translate3d(-256px, 1893px, -132px);
}

.star:nth-child(554) {
  transform: translate3d(-234px, -968px, 1194px);
}

.star:nth-child(555) {
  transform: translate3d(-774px, 94px, -870px);
}

.star:nth-child(556) {
  transform: translate3d(-781px, 1151px, 1224px);
}

.star:nth-child(557) {
  transform: translate3d(1502px, -384px, -450px);
}

.star:nth-child(558) {
  transform: translate3d(566px, -85px, -889px);
}

.star:nth-child(559) {
  transform: translate3d(-1889px, 1051px, 462px);
}

.star:nth-child(560) {
  transform: translate3d(-1855px, -1204px, -1962px);
}

.star:nth-child(561) {
  transform: translate3d(-756px, -35px, -1164px);
}

.star:nth-child(562) {
  transform: translate3d(895px, 1919px, -424px);
}

.star:nth-child(563) {
  transform: translate3d(753px, -1659px, -1506px);
}

.star:nth-child(564) {
  transform: translate3d(-1455px, 1585px, 754px);
}

.star:nth-child(565) {
  transform: translate3d(-1100px, -132px, -1404px);
}

.star:nth-child(566) {
  transform: translate3d(1194px, 1604px, -1070px);
}

.star:nth-child(567) {
  transform: translate3d(1935px, 1646px, -999px);
}

.star:nth-child(568) {
  transform: translate3d(-1981px, 1373px, -1365px);
}

.star:nth-child(569) {
  transform: translate3d(-1327px, 1438px, 625px);
}

.star:nth-child(570) {
  transform: translate3d(-95px, 999px, -1639px);
}

.star:nth-child(571) {
  transform: translate3d(-196px, -1634px, -306px);
}

.star:nth-child(572) {
  transform: translate3d(-368px, -1732px, 308px);
}

.star:nth-child(573) {
  transform: translate3d(-1649px, -1298px, 1211px);
}

.star:nth-child(574) {
  transform: translate3d(1661px, 883px, -1756px);
}

.star:nth-child(575) {
  transform: translate3d(1402px, 842px, -30px);
}

.star:nth-child(576) {
  transform: translate3d(712px, 111px, -508px);
}

.star:nth-child(577) {
  transform: translate3d(1359px, 1057px, 1861px);
}

.star:nth-child(578) {
  transform: translate3d(-1753px, 1712px, 197px);
}

.star:nth-child(579) {
  transform: translate3d(-1002px, 1871px, 508px);
}

.star:nth-child(580) {
  transform: translate3d(5px, -410px, -20px);
}

.star:nth-child(581) {
  transform: translate3d(1392px, 1894px, 70px);
}

.star:nth-child(582) {
  transform: translate3d(-191px, -1205px, 637px);
}

.star:nth-child(583) {
  transform: translate3d(1884px, 490px, -1037px);
}

.star:nth-child(584) {
  transform: translate3d(-840px, 1688px, -1517px);
}

.star:nth-child(585) {
  transform: translate3d(-1592px, -730px, 986px);
}

.star:nth-child(586) {
  transform: translate3d(-1184px, -79px, 79px);
}

.star:nth-child(587) {
  transform: translate3d(-1338px, 1456px, -1892px);
}

.star:nth-child(588) {
  transform: translate3d(-1107px, -1617px, 234px);
}

.star:nth-child(589) {
  transform: translate3d(-1198px, 748px, -1152px);
}

.star:nth-child(590) {
  transform: translate3d(1218px, 1545px, -182px);
}

.star:nth-child(591) {
  transform: translate3d(1729px, -576px, 786px);
}

.star:nth-child(592) {
  transform: translate3d(-716px, -1746px, 1030px);
}

.star:nth-child(593) {
  transform: translate3d(-953px, -1847px, -1322px);
}

.star:nth-child(594) {
  transform: translate3d(1825px, -1418px, 153px);
}

.star:nth-child(595) {
  transform: translate3d(-792px, 535px, 915px);
}

.star:nth-child(596) {
  transform: translate3d(719px, -1672px, 575px);
}

.star:nth-child(597) {
  transform: translate3d(-809px, 386px, -1113px);
}

.star:nth-child(598) {
  transform: translate3d(808px, 1535px, 1732px);
}

.star:nth-child(599) {
  transform: translate3d(-1159px, 64px, -1833px);
}

.star:nth-child(600) {
  transform: translate3d(1354px, 1160px, 739px);
}

/***************************** 
    로고 1안 
******************************/
.orbitTextLogo {
  position: relative;
  display: inline-block;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 0 0 20px;
}
.orbitTextLogo::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -180px 0 0 -180px;
  width: 360px;
  height: 360px;
  background: rgba(34, 34, 34, 0.8);
  box-shadow: 0 0 50px #8BA9FF;
  border-radius: 365px;
  z-index: 1;
}
.orbitTextLogo .orbitCircle {
  display: inline-block;
  position: relative;
  top: 0;
  left: -10px;
  height: 50px;
  width: 50px;
  transform-origin: center;
  animation: orbit 15s infinite linear;
  animation-delay: 0.25s;
  border: 10px solid white;
  border-radius: 50%;
  box-shadow: 0 0 10px #222222;
  z-index: 1;
}
.orbitTextLogo .orbitCircle .orbitObject {
  position: absolute;
  top: -20px;
  left: 0;
  width: 10px;
  height: 10px;
  border: 5px solid #8BA9FF;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 1;
}
.orbitTextLogo .orbitCircle .orbitObject span.ripple {
  position: relative;
  top: -9px;
  left: -5px;
  width: 10px;
  height: 10px;
  padding: 10px;
  font-size: 0px;
  border-radius: 50%;
  background-color: transparent;
  animation: ripple 1s infinite linear;
}
.orbitTextLogo .orbitObjectText {
  position: relative;
  display: inline-block;
  top: 10px;
  border-radius: 50%;
  color: #ffffff;
  font: 400 40px "NSKR";
  text-shadow: 0 0 10px #222222;
  letter-spacing: 10px;
  vertical-align: top;
  z-index: 1;
}
.orbitTextLogo .luna {
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 360px;
  height: 360px;
  margin: -180px 0 0 -180px;
  border-radius: 100%;
  box-sizing: border-box;
  background: #CCCCCC;
  box-shadow: inset -2vw -1.5vw 0 rgba(22, 23, 67, 0.05);
}
.orbitTextLogo .luna .crater1,
.orbitTextLogo .luna .crater2,
.orbitTextLogo .luna .crater3 {
  position: absolute;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: inset 3px 3px 0 rgba(0, 0, 0, 0.2);
}
.orbitTextLogo .luna .crater1 {
  height: 60px;
  width: 60px;
  top: 32%;
  left: 17%;
}
.orbitTextLogo .luna .crater2 {
  height: 40px;
  width: 40px;
  top: 26%;
  left: 55%;
  box-shadow: inset 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.orbitTextLogo .luna .crater3 {
  height: 40px;
  width: 40px;
  top: 60%;
  left: 40%;
  box-shadow: inset 2px 2px 0 rgba(0, 0, 0, 0.2);
}

@-webkit-keyframes orbit {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes orbit {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes orbit {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(139, 169, 255, 0.1), 0 0 0 5px rgba(139, 169, 255, 0.1), 0 0 0 10px rgba(139, 169, 255, 0.1), 0 0 0 15px rgba(139, 169, 255, 0.1);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(139, 169, 255, 0.1), 0 0 0 10px rgba(139, 169, 255, 0.1), 0 0 0 15px rgba(139, 169, 255, 0.1), 0 0 0 20px rgba(139, 169, 255, 0);
  }
}
@-moz-keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(139, 169, 255, 0.1), 0 0 0 5px rgba(139, 169, 255, 0.1), 0 0 0 10px rgba(139, 169, 255, 0.1), 0 0 0 15px rgba(139, 169, 255, 0.1);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(139, 169, 255, 0.1), 0 0 0 10px rgba(139, 169, 255, 0.1), 0 0 0 15px rgba(139, 169, 255, 0.1), 0 0 0 20px rgba(139, 169, 255, 0);
  }
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(139, 169, 255, 0.1), 0 0 0 5px rgba(139, 169, 255, 0.1), 0 0 0 10px rgba(139, 169, 255, 0.1), 0 0 0 15px rgba(139, 169, 255, 0.1);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(139, 169, 255, 0.1), 0 0 0 10px rgba(139, 169, 255, 0.1), 0 0 0 15px rgba(139, 169, 255, 0.1), 0 0 0 20px rgba(139, 169, 255, 0);
  }
}
/***************************** 
    로고 2안
******************************/
.orbitPluto {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
}
.orbitPluto .orbitPlanet {
  box-sizing: border-box;
  position: absolute;
  border-radius: 100%;
  height: 200px;
  width: 200px;
  overflow: hidden;
  margin-left: -100px;
  margin-top: -100px;
  box-shadow: inset -2vw -1.5vw 0 rgba(22, 23, 67, 0.05);
  background: #8BA9FF;
  z-index: 2;
  animation: filter-animation 12s linear infinite;
}
.orbitPluto .orbitPlanet .crater1,
.orbitPluto .orbitPlanet .crater2,
.orbitPluto .orbitPlanet .crater3 {
  position: absolute;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: inset 3px 3px 0 rgba(0, 0, 0, 0.2);
}
.orbitPluto .orbitPlanet .crater1 {
  height: 40px;
  width: 40px;
  top: 32%;
  left: 17%;
}
.orbitPluto .orbitPlanet .crater2 {
  height: 20px;
  width: 20px;
  top: 26%;
  left: 55%;
  box-shadow: inset 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.orbitPluto .orbitPlanet .crater3 {
  height: 20px;
  width: 20px;
  top: 60%;
  left: 40%;
  box-shadow: inset 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.orbitPluto .orbitObject {
  position: absolute;
  width: 300px;
  height: 300px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 100%;
  margin: -150px 0 0 -150px;
  animation: orbit 12s linear infinite;
}
.orbitPluto .orbitObject .luna {
  width: 20px;
  height: 20px;
  margin: 34px 34px 34px 245px;
  border-radius: 100%;
  background: #8BA9FF;
  box-shadow: inset -4px -4px 0 rgba(22, 23, 67, 0.2);
}
.orbitPluto .orbitObject .luna:after {
  content: "";
  position: relative;
  top: -4px;
  left: 0px;
  width: 10px;
  height: 10px;
  padding: 10px;
  font-size: 0px;
  border-radius: 50%;
  background-color: transparent;
  animation: ripple 1s infinite linear;
}
.orbitPluto .textBox {
  position: relative;
  width: 300px;
  margin: 180px 0 0 -150px;
  font: 400 24px/50px "NSKB";
  color: #FFFFFF;
  text-align: center;
  border-radius: 300px;
  background: rgba(0, 0, 0, 0.2);
  z-index: 3;
}
.orbitPluto .textBox b {
  margin: 0 0 0 10px;
  font: 400 16px/50px "NSKT";
  vertical-align: top;
}

.rocket {
  position: absolute;
  right: 50%;
  top: 50%;
  margin-top: -82px;
  margin-right: -82px;
  height: 22px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-transform-origin: 0% 100% 0;
  -ms-transform-origin: 0% 100% 0;
  transform-origin: 0% 100% 0;
  z-index: 1;
  -webkit-animation: rocket 12s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  animation: rocket 12s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
}
.rocket .rocket-rotate {
  position: absolute;
  height: 22px;
  -webkit-transform: rotate(-110deg);
  -ms-transform: rotate(-110deg);
  transform: rotate(-110deg);
  -webkit-animation: rocket-rotate 12s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  animation: rocket-rotate 12s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
}
.rocket .rocket-rotate .pod {
  position: absolute;
  top: 0;
  left: -8px;
  height: 16px;
  width: 16px;
  background: #eee;
  border-radius: 100% 0 100% 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.rocket .rocket-rotate .fuselage {
  position: absolute;
  top: 14px;
  left: -6px;
  height: 8px;
  width: 12px;
  background: #eee;
  border-radius: 100% 100% 0 0;
}
.rocket .rocket-rotate .fuselage:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid red;
}

@-webkit-keyframes rocket {
  0% {
    right: -10%;
    top: -10%;
    margin-top: -82px;
    margin-right: -82px;
  }
  40% {
    right: 50%;
    top: 50%;
  }
  82% {
    margin-top: -82px;
    margin-right: -82px;
  }
  90% {
    margin-top: -20px;
    margin-right: 0px;
  }
  100% {
    right: 50%;
    top: 50%;
    margin-top: 0px;
    margin-right: 0px;
  }
}
@keyframes rocket {
  0% {
    right: -10%;
    top: -10%;
    margin-top: -82px;
    margin-right: -82px;
  }
  40% {
    right: 50%;
    top: 50%;
  }
  90% {
    margin-top: -82px;
    margin-right: -82px;
  }
  95% {
    margin-top: -20px;
    margin-right: 0px;
  }
  100% {
    right: 50%;
    top: 50%;
    margin-top: 0px;
    margin-right: 0px;
  }
}
@-webkit-keyframes rocket-rotate {
  0% {
    -webkit-transform: rotate(-110deg);
    transform: rotate(-110deg);
  }
  20% {
    -webkit-transform: rotate(-110deg);
    transform: rotate(-110deg);
  }
  34% {
    -webkit-transform: rotate(47deg);
    transform: rotate(47deg);
  }
  79% {
    -webkit-transform: rotate(47deg);
    transform: rotate(47deg);
  }
  100% {
    -webkit-transform: rotate(47deg);
    transform: rotate(47deg);
  }
}
@keyframes rocket-rotate {
  0% {
    -webkit-transform: rotate(-110deg);
    transform: rotate(-110deg);
  }
  20% {
    -webkit-transform: rotate(-110deg);
    transform: rotate(-110deg);
  }
  34% {
    -webkit-transform: rotate(47deg);
    transform: rotate(47deg);
  }
  79% {
    -webkit-transform: rotate(47deg);
    transform: rotate(47deg);
  }
  100% {
    -webkit-transform: rotate(47deg);
    transform: rotate(47deg);
  }
}
@-webkit-keyframes orbit {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes orbit {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes filter-animation {
  0% {
    -webkit-filter: hue-rotate(0deg);
  }
  50% {
    -webkit-filter: hue-rotate(360deg);
  }
  100% {
    -webkit-filter: hue-rotate(0deg);
  }
}
@keyframes filter-animation {
  0% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(360deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}