.root {
    box-sizing: border-box;
    border-top: 2px solid #666666;
    border-bottom: 1px solid rgb(225, 225, 225);
    border-left: 1px solid rgb(225, 225, 225);

    & > .grid {
        position: relative;
        width: 100%;
        height: 100%;

        &::after {
            content: "";
            position: absolute;
            display: inline-block;
            top: 0px;
            right: 0px;
            bottom: 0px;
            width: 1px;
            box-sizing: border-box;
            border-right: 1px solid rgb(225, 225, 225);
        }
    }
}

.emptyData {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.emptyDataTiny {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.emptyTextNormal {
    margin-top: 10px;
    font-size: 12px;
    color: #8c8c8c;
}

.emptyTextSmall {
    margin-top: 10px;
    font-size: 12px;
    color: #8c8c8c;
}

.emptyTextTiny {
    margin-left: 5px;
    font-size: 12px;
    color: #8c8c8c;
}

// .dateFieldDialog {
//     z-index: 2000;
// }

.dateFieldDialog > div {
    z-index: 2000;
}

.hoverWrapper {
    position: absolute;
    pointer-events: none;
    left: -1px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;

    .hoverRoot {
        box-sizing: border-box;
        position: absolute;
        // border: 1px solid #1c90fb;
        transition: top 100ms ease-out, opacity 100ms ease-out;
        opacity: 0;
        max-width: 100%;
        overflow: hidden;

        &.visible {
            opacity: 1;
        }

        .hoverActionButtonRoot {
            box-sizing: border-box;
            position: absolute;
            top: 0px;
            right: 0px;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding-right: 10px;
            padding-left: 20px;
            background: linear-gradient(to right, #ffffff00 0px, #ffffff 20px, #ffffff 100%);

            & > * {
                pointer-events: all;
            }

            & > *:not(:last-child) {
                margin-right: 5px;
            }
        }

        .reorderRoot {
            position: absolute;
            top: 0px;
            bottom: 0px;
            right: 0px;
            overflow: hidden;

            .moveThumb {
                flex: 0 0 auto;
                position: absolute;
                left: 0px;
                top: 0px;
                bottom: 0px;
                width: 20px;
                opacity: 0;
                pointer-events: all;
                cursor: pointer;
                transition: opacity 100ms ease-out;

                &:hover,
                &.on {
                    background-color: #2c98fb;
                    background-image: url("../OBTTreeView//img/icon-con-change.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    opacity: 1;
                }
            }

            .reorderImageRoot {
                flex: 1 1 auto;
                position: absolute;
                left: 0px;
                top: 0px;
                bottom: 0px;
                transition: left 100ms ease-out;

                &.on {
                    left: 20px;
                }

                .reorderImage {
                    position: absolute;
                }
            }
        }
    }
}

.privacyLoadingRoot {
    position: absolute;
    right: 20px;
    bottom: 20px;
    pointer-events: none;

    .privacyLoading {
        background-color: #ffffff;
    }
}
